import { Component, OnInit, IterableDiffers } from '@angular/core';
import { UserService } from "../../../services/user.service";
import { NotificationsService } from "../../../services/notifications.service";
import { ApiUrl } from "../../../utils/config";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-api-operations',
  templateUrl: './api-operations.component.html',
  styleUrls: ['./api-operations.component.css']
})
export class ApiOperationsComponent implements OnInit {
  list: any;
  currentPages = 1;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;
  rawImageUrl = "/assets/img/user.png";
  search: any = '';

  downloadFile : any = []


  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { 
     
  }

 
  ngOnInit(): void {
    this.getAll(1);
  }
  onSearch(e:any){
    this.getAll(1);
  }

  download(){

  let jsonData =this.downloadFile
    this.userService.downloadFile(jsonData, 'jsontocsv');
  }

  getAll = (page: any) => {
   
    this.currentPages = page;
    this.page=page;
    this.userService.getApiLogSearch(page,this.search).toPromise()
      .then(response => {
        if (response.success) {
          this.list = response.data.result;
          this.count = response.data.count;
          let exportOperationTable :any=[]
          this.list.map((item:any,index:any)=>{
            exportOperationTable.push({
              scan_img : item.scan_img,
              crystalName:item.crystalName,
              finishType : item.crystalName,
              selected : item.selected,
              confidence :item.confidence,
              autoId : item.autoId,

            })
          })
          this.downloadFile = exportOperationTable
         ;
        //  this.download()
        }
      });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getAll(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll(this.page);
  }

  view = (data: any) => {
    
    this.reportedPostDetails = data;
    
  }
  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteOperation(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getAll(1);
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }
}
