<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->


<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">       
                        Subscriber View
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>
                                  <ng-container *ngIf="list;else other_content">
                                      {{ list.length>0?(currentPages - 1) * tableSize+1:0}}

                                  </ng-container>
                                  <ng-template #other_content>0</ng-template>
                                </b>
                              -
                              <b>
                                <ng-container *ngIf="list;else other_content">
                                    {{ list.length>0?((currentPages - 1) * tableSize+1) + (list?list.length-1:0):'0'}}
                                </ng-container>   
                                <ng-template #other_content>0</ng-template>
                            </b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">User Name</th>
                                    <th class="text-center whitespace-no-wrap">Plan Name</th>
                                    <th class="text-center whitespace-no-wrap">Scan Quantity</th>
                                    <th class="text-center whitespace-no-wrap">Collection Quantity</th>
                                    <th class="text-center whitespace-no-wrap">Backup</th>
                                    <th class="text-center whitespace-no-wrap">Amount</th>
                                    <th class="text-center whitespace-no-wrap">Start Date</th>
                                    <th class="text-center whitespace-no-wrap">End Date</th>
                                    <th class="text-center whitespace-no-wrap">Plan ID</th>
                                    <th class="text-center whitespace-no-wrap">DeviceType</th>
                                    <th class="text-center whitespace-no-wrap">GUID</th>

                                    <!-- <th class="text-center whitespace-no-wrap">Sr No.</th>
                                     <th class="text-center whitespace-no-wrap">GUID</th>
                                    <th class="text-center whitespace-no-wrap">Plan Name</th>
                                    <th class="text-center whitespace-no-wrap">Plan Start Date</th>
                                    <th class="text-center whitespace-no-wrap">Plan End Date</th>
                                    <th class="text-center whitespace-no-wrap">CreatedAt</th> -->

                                    <!-- <th class="text-center whitespace-no-wrap">Actions</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let item of list | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * tableSize + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">
                                        {{  item.userId ? item.userId.firstName : ''	}}                                       
                                    </td>
                                    <td class="text-center" title="{{item.subscriptionPlan.durationType}}">{{  item.subscriptionPlan ? item.subscriptionPlan.planName : ''	}} </td>
                                        <td class="text-center">{{ item.scanQuantity }}</td>
                                        <td class="text-center">{{ item.collectionQuantity }}</td>
                                        <td class="text-center">
                                            {{  item.subscriptionPlan ? item.subscriptionPlan.backup : ''	}} 
                                        </td>
                                        <td class="text-center">{{item.amount}}</td>
                                        <td class="text-center">{{item.start_date | date: 'medium'}}</td>                                        
                                        <td class="text-center">{{ checkEndDate(item.start_date,item.end_date,item.subscriptionPlan.durationType) | date: 'medium' }}</td>
                                        <td class="text-center">
                                            {{item.subscriptionPlan?item.subscriptionPlan._id:'' }}
                                        </td>
                                        <td class="text-center">{{ item.userId?item.userId.deviceType:'' }}</td>
                                        <td class="text-center">{{ item.userId?item.userId.guId:'' }}</td>
                               
                               
                            <!-- <td class="text-center">{{ item.userId?item.userId.guId:'' }}</td>                                    
                                    <td class="text-center">{{item.start_date | date: 'medium'}}</td>
                                    <td class="text-center">{{item.end_date | date: 'medium'}}</td>
                                    <td class="text-center">{{item.createdAt | date: 'medium'}}</td> -->
                                    <!-- <td class="text-center">
                                         <a data-toggle="modal" data-target="#viewSubscription" (click)="view(item)"
                                            class="text-succes mr-1">
                                            <i class="fa fa-eye"></i>
                                        </a> 
                                       <a 
                                        data-target="#viewSubscription" (click)="viewSubscription(item)" class="text-succes">
                                        <i
                                            class="fa fa-eye"></i></a>                                        
                                       
                                    </td>
                                        -->
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>
<div class="modal" id="viewSubscription">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">payment subscription info</h4>
                  <div class="row">
                    
                     
                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>subscriptionId</th>
                                <td>{{ reportedPostDetails?reportedPostDetails.subscriptionId:'' }}
                                </td>
                                <th>userId</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.userId:""}}
                                </td>
                            </tr>
                            
                            <tr>
                                <th>start_date</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.start_date:""}}</td>
                                <th>end_date</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.end_date:""}}</td>
                            </tr>
                            <tr>
                                <th>transactionId</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.transactionId:""}}</td>
                                <th>status</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.status:""}}</td>
                            </tr>
                            <tr>
                                <th>amount</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.amount:""}}</td>
                                <th>planName</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.planName:""}}</td>
                            </tr>
                            <tr>
                                <th>quantity</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.quantity:""}}</td>
                                <th>durationType</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.durationType:""}}</td>
                            </tr>
                            <tr>
                                <th>paymentStatus</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.paymentStatus:""}}</td>
                                <th></th>
                                <td></td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
    

    </div>
