<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Posts (community wall)
                        <span class="float-right">
                            <div class="search-box">
                                
                            </div>
                        </span>
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * tableSize+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * tableSize+1) + (postList?postList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">profileid</th>
                                    <th class="text-center whitespace-no-wrap">Id</th>
                                    <th class="text-center whitespace-no-wrap">description</th>
                                    <th class="text-center whitespace-no-wrap">likes</th>
                                    <th class="text-center whitespace-no-wrap">postStatus</th>
                                    <th class="text-center whitespace-no-wrap">hiddenByUsers</th>
                                    <th class="text-center whitespace-no-wrap">postImg</th>

                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>
                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let post of postList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{ post.created_by?post.created_by._id:'' }}

                                    </td>
                                    <td class="text-center">{{ post.created_by?post.created_by.guId:'' }}

                                    </td>
                                    <td class="text-center">{{post.text}}</td>
                                    <td class="text-center">{{post.likes.length}}</td>
                                    <td class="text-center">
                                        {{post.postStatus}}
                                        
                                    </td>
                                    
                                    <td class="text-center">{{ post.hiddenByUsers }}</td>
                                    <td class="text-center">{{ post.postImg }}</td>
                                    <td class="text-center">{{ post.createdAt }}</td>
                                    <td class="text-center">{{ post.updatedAt }}</td>
                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewUser" (click)="viewPost(post)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteUser" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 class="modal-title">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this data?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewUser">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">community wall info</h4>
                  <div class="row">
                    
                      <div class="">
                          <div class="columns">
                              <div class="circle">
                                  
                                  <img src="{{
									postDetails 
									  ? base + '/'+ postDetails.postImg
									  : 'assets/img/user.png'
								  }}" class="img-rounded w-100 h-100">
                              </div>
                          </div>
                      </div>
                      
                      
                      
                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>description</th>
                                <td>{{postDetails ? postDetails.text:""}}</td>
                                <th>hiddenByUsers</th>
                                <td>{{postDetails ? postDetails.hiddenByUsers:""}}</td>
                            </tr>
                            
                            <tr>
                                <th>ID</th>
                                <td>{{postDetails && postDetails.created_by ? postDetails.created_by.guId:""}} </td>
                                <th>likes</th>
                                <td>{{postDetails ? postDetails.likes.length:""}}</td>
                            </tr>
                            
                            <tr>
                                <th>postStatus</th>
                                <td>{{postDetails ? postDetails.postStatus:""}}</td>
                                <th>profileid</th>
                                <td>{{postDetails && postDetails.created_by ? postDetails.created_by._id:""}}</td>
                            </tr>
                            <tr>
                                <th>createdAt</th>
                                <td>{{postDetails ? postDetails.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="postDetails!=undefined">
                                    {{ postDetails?postDetails.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
   
</div>