<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        My crystal table
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * tableSize+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * tableSize+1) + (list?list.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">crystalName</th>
                                    <th class="text-center whitespace-no-wrap">identification</th>
                                    <th class="text-center whitespace-no-wrap">name</th>
                                    <th class="text-center whitespace-no-wrap">added_by</th>
                                    <th class="text-center whitespace-no-wrap">collections</th>
                                    <th class="text-center whitespace-no-wrap">noIdReason</th>
                                    <th class="text-center whitespace-no-wrap">finish</th>
                                    <th class="text-center whitespace-no-wrap">crystalId</th>
                                    <th class="text-center whitespace-no-wrap">description</th>
                                    <th class="text-center whitespace-no-wrap">location</th>
                                    <th class="text-center whitespace-no-wrap">image1</th>
                                    <th class="text-center whitespace-no-wrap">image2</th>
                                    <th class="text-center whitespace-no-wrap">image3</th>
                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>

                                    
                                    <th class="text-center whitespace-no-wrap">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let item of list | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * tableSize + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">
                                        {{item.crystalName}}
                                    </td>
                                    <td class="text-center">{{ item.identification	}}
                                    </td>
                                    <td class="text-center">{{ item.name }}</td>
                                    <td class="text-center">{{ item.added_by?item.added_by.guId:'' }}</td>
                                    <td class="text-center">{{ item.collections }}</td>
                                    <td class="text-center">{{item.noIdReason}}</td>
                                    <td class="text-center">{{item.finish}}</td>
                                    <td class="text-center">{{item.crystalId}}</td>
                                    <td class="text-center">{{item.description}}</td>
                                    <td class="text-center">{{item.location}}</td>
                                    <td class="text-center">{{item.image1}}</td>
                                    <td class="text-center">{{item.image2}}</td>
                                    <td class="text-center">{{item.image3}}</td>
                                    <td class="text-center">{{item.createdAt}}</td>
                                    <td class="text-center">{{item.updatedAt}}</td>


                                    <td class="text-center">
                                        <a data-toggle="modal" data-target="#viewSubscription" (click)="view(item)"
                                            class="text-succes mr-1">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                        
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>
<div class="modal" id="viewSubscription">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">my crystal table info</h4>
                  <div class="row">
                    
                      <div class="">
                            <h4 class="text-center"> image1</h4>
                          <div class="columns">
                              <div class="circle">
                                  
                                <img src="{{
                                    reportedPostDetails 
                                      ? base + '/'+ reportedPostDetails.image1
                                      : 'assets/img/user.png'
                                  }}" class="w-100 h-100" />
                              </div>
                          </div>
                      </div>
                      <div class="">
                            <h4 class="text-center"> image2</h4>
                          <div class="columns">
                              <div class="circle">
                                  
                                <img src="{{
                                    reportedPostDetails 
                                      ? base + '/'+ reportedPostDetails.image2
                                      : 'assets/img/user.png'
                                  }}" class="w-100 h-100" />
                              </div>
                          </div>
                      </div>
                      <div class="">
                        <h4 class="text-center"> image3</h4>
                      <div class="columns">
                          <div class="circle">
                              
                            <img src="{{
                                reportedPostDetails 
                                  ? base + '/'+ reportedPostDetails.image3
                                  : 'assets/img/user.png'
                              }}" class="w-100 h-100" />
                          </div>
                      </div>
                  </div>
                      

                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>crystalName</th>
                                <td>{{ reportedPostDetails?reportedPostDetails.crystalName:'' }}
                                </td>
                                <th>identification</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.identification:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>name</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.name:""}}</td>
                                <th>noIdReason</th>
                                <td>
                                    {{reportedPostDetails ? reportedPostDetails.noIdReason:""}}
                                </td>
                            </tr>
                           
                            <tr>
                                <th>finish</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.finish:""}}</td>
                                <th>crystalId</th>
                                <td>
                                    {{ reportedPostDetails?reportedPostDetails.crystalId:'' }}
                                </td>
                            </tr>
                            <tr>
                                <th>added_by</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.added_by.guId:""}}</td>
                                <th>collections</th>
                                <td>
                                    {{ reportedPostDetails?reportedPostDetails.collections:'' }}
                                </td>
                            </tr>
                            <tr>
                                <th>description</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.description:""}}</td>
                                <th>location</th>
                                <td>
                                    {{ reportedPostDetails?reportedPostDetails.location:'' }}
                                </td>
                            </tr>
                            <tr>
                                <th>createdAt</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails?reportedPostDetails.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
    
</div>