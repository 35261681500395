import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { ApiUrl } from "../../../../utils/config";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-content-criterias',
  templateUrl: './content-criterias.component.html',
  styleUrls: ['./content-criterias.component.css']
})
export class ContentCriteriasComponent implements OnInit {

  subscriptionList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  submitted = false;
  loading = false;
  idToShow: any;
  subscriptionForm !: FormGroup;
  groupDetails: any;
  base: any = ApiUrl;

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getSubscriptionList(1);
    this.updateformControl()
  }

  getSubscriptionList = (page: Number) => {
    page = page ? page : 1
    this.userService.getAllCriteria(page).toPromise().then(response => {
      if (response.success) {
        this.subscriptionList = response.data;
        this.count = response.data.length;
      }
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getSubscriptionList(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getSubscriptionList(1);
  }

  viewSubscription = (data: any) => {
    this.groupDetails = data;
    this.idToShow = data._id;
    this.getGroupDetail();
  }

  getGroupDetail = () => {
    this.subscriptionForm = this.formbuilder.group({
      name: [this.groupDetails.name, Validators.required],
      query: [this.groupDetails.query, Validators.required]
    })
  }

  updateformControl = () => {
    this.subscriptionForm = this.formbuilder.group({
      name: ['', Validators.required],
      query: ['', Validators.required]
    })
  }

  updateGroupInfo = () => {
    this.submitted = true;
    this.loading = true;
    this.userService
      .updateCriteria(this.subscriptionForm.value, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.getSubscriptionList(this.page);
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteCriteria(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getSubscriptionList(1)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

}
