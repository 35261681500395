<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <div class="row">
                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Admin Profile
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="profile-sidebar">
                                <div class="card card-topline-aqua">
                                    <div class="card-body no-padding text-center">
                                        <div class="row">

                                            <div class="profile-userpic text-center w-100">
                                                <img src="{{
                                                    adminDetail
                                                      ? base + '/'+ adminDetail.profileImg
                                                      : 'assets/img/user.png'
                                                  }}" class="img-responsive" alt=""> </div>
                                        </div>
                                        <div class="profile-usertitle ">
                                            <div class="profile-usertitle-name"> 
                                                {{adminDetail ? adminDetail.firstName:""}} 
                                                {{adminDetail ? adminDetail.lastName:""}}
                                            </div>
                                        </div>
                                        <div class="profile-desc">
                                            Hello I am {{adminDetail ? adminDetail.firstName:""}} 
                                            {{adminDetail ? adminDetail.lastName:""}} from  
                                            {{adminDetail ? adminDetail.country:""}}. 
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header pb-3">
                                        <h4 class="mb-0">Address</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row text-center m-t-10">
                                            <div class="col-md-12">
                                                <p class="mb-0">{{adminDetail ? adminDetail.city:""}}
                                                    <br> {{adminDetail ? adminDetail.state:""}}, {{adminDetail ? adminDetail.country:""}}.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="profile-content">
                                <div class="row">
                                    <div class="tab-content col-md-12 mt-2">
                                        <div class="tab-pane fontawesome-demo active" id="tab1">
                                            <div id="biography">
                                                <div class="card mt-0">
                                                    <div class="card-body">
                                                        <div class="row m-0">
                                                            <div class="col-md-3"> <strong>Full
                                                                    Name</strong>
                                                                <br>
                                                                <p class="text-muted mb-0">
                                                                    {{adminDetail ? adminDetail.firstName:""}} 
                                                                    {{adminDetail ? adminDetail.lastName:""}}
                                                                </p>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <strong>Role</strong>
                                                                <br>
                                                                <p class="text-muted mb-0">
                                                                    {{adminDetail ? adminDetail.role:""}}
                                                                </p>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <strong>Email</strong>
                                                                <br>
                                                                <p class="text-muted mb-0">
                                                                    {{adminDetail ? adminDetail.email:""}}
                                                                </p>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <strong>Country</strong>
                                                                <br>
                                                                <p class="text-muted mb-0">{{adminDetail ? adminDetail.country:""}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                <h4>About Me</h4>
                                            </div>
                                            <div class="card-body">

                                                <ul class="list-group list-group-unbordered">
                                                    <li class="list-group-item">

                                                        <b>Time of Birth </b>: {{adminDetail ? adminDetail.tob:""}}
                                                      
                                                    </li>
                                                    <li class="list-group-item">
                                                        <b>D.O.B </b>:  {{adminDetail ? adminDetail.dob:""}}
                                                       
                                                    </li>
                                                  
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right mb-4">
                                        <a href="javascript:void(0);" data-toggle="modal"
                                        data-target="#editUser" class="btn btn-success">Edit
                                            Profile</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="editUser">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
                &times;
            </button>
        </div>
            <div class="modal-body">
                <div class="card-content">
                    <div class="row">
                       
                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Edit Profile 
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="updateUser" (ngSubmit)="updateProfile()" class="custom-mt-form">
                                        <div class="columns">
                                           
                                            <div class="circle">
                                                <div class="pic-upload">
                                                    <img [src]="imgUrl" class="w-100 h-100" />
                                                    <img class="profile-pic" 
                                                    src="{{
                                                        adminDetail
                                                          ? base + '/'+ adminDetail.profileImg
                                                          : 'assets/img/user.png'
                                                      }}">s
                                                  </div>
                                               
                                               
                                            </div>
                                            <div class="p-image">
                                                <label for="fileupload"> <i
                                                        class="fa fa-camera upload-button"></i></label>
                                                <input  id="fileupload" class="file-upload" type="file" (change)="onFileChange($event)" accept="image/*">
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">First Name</label> 
                                                    <input 
                                                    formControlName="firstName"
                                                    type="text"
                                                    class="form-control" 
                                                    placeholder="Enter first name"
                                                    >
                                                  

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Last Name</label>
                                                    <input 
                                                    formControlName="lastName"
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Enter last name"
                                                    >
                                                   
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Email</label> 
                                                    <input 
                                                    formControlName="email"
                                                    type="text"
                                                    class="form-control" 
                                                    placeholder="Enter Email"
                                                     readonly>
                                                  
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">City</label>
                                                    <input 
                                                    formControlName="city"
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Enter your city"
                                                    >
                                                  
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">State</label>
                                                    <input 
                                                    formControlName="state"
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Enter your state"
                                                    >
                                                 
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Country</label>
                                                    <input 
                                                    formControlName="country"
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Enter your country"
                                                    >
                                                   
                                                </div>
                                            </div>

                                           
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">D.O.B</label>
                                                    <input 
                                                    formControlName="dob"
                                                    type="date" class="form-control"
                                                    placeholder="Enter Date of Birth" max="{{currentDate}}">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">T.O.B</label>
                                                    <input 
                                                    formControlName="tob"
                                                    type="text" class="form-control"
                                                    placeholder="Enter Time of Birth">
                                                </div>
                                            </div>
                                           
                                           
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       

            </div>
        </div>
    </div>
</div>
