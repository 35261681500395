import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiUrl } from "../../../../utils/config";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'

@Component({
  selector: 'app-constant-config',
  templateUrl: './constant-config.component.html',
  styleUrls: ['./constant-config.component.css']
})
export class ConstantConfigComponent implements OnInit {

  ethicalSourcingDetail: any;
  currentPages = 1;
  postsPerPage = 10;
  updateUser !: FormGroup;
  tableSize = 10;
  tutorialList: any;
  submitted = false;
  loading = false;
  count = 0;
  page = 1;
  idToShow: any
  base: any = ApiUrl;
  locationList:any
  imgUrl !: String
    imageToUpload: any;
    search:any = '';


  public Editor = CKEditorModule;
  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,


  ) { }

  ngOnInit(): void {
    this.getTutorialList(1);
    this.updatefromControl();
    this.getLanguageList()
  }
  onSearch(e:any){
    
    this.getTutorialList(1);
    
}

  updatefromControl = () => {
    this.updateUser = this.formbuilder.group({
      description: [''],
      label: [''],
      englishValue: [''],
      title: [''],
      type: [''],
      location:[0],
      webAdmin: [false, Validators.required],
      viewOrder: ['', [Validators.pattern(/^[0-9]+$/)]],
      system: [false, Validators.required],
      default: [false, Validators.required],
      UX: [false, Validators.required],
      local: [false, Validators.required],
      createdAt: [''],
      updatedAt:['']

    })
  }

  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }

  getTutorialList = (page: any) => {

    this.currentPages = page?page:1;
    this.page = page?page:1;

    var obj = {
      page:page?page:1,
      search:this.search
    }
    this.userService.getConstantConfigs(obj).toPromise().then(response => {
      if (response.success) {
        
        this.tutorialList = response.data.result;
        this.count = response.data.count;
      }
    });
  }

  getEthicalSourcingDetail = () => {

    if(this.ethicalSourcingDetail.image!==undefined && this.ethicalSourcingDetail.image!==''){
        this.imgUrl  = this.base + '/'+ this.ethicalSourcingDetail.image
    }

    this.updateUser = this.formbuilder.group({
      description: [this.ethicalSourcingDetail.description ? this.ethicalSourcingDetail.description : ''],
      label: [this.ethicalSourcingDetail.label],
      englishValue: [this.ethicalSourcingDetail.englishValue?this.ethicalSourcingDetail.englishValue:''],
      title: [this.ethicalSourcingDetail.title],
      type: [this.ethicalSourcingDetail.type],
      location:[this.ethicalSourcingDetail?.location],
      webAdmin: [this.ethicalSourcingDetail.webAdmin, Validators.required],
      viewOrder: [this.ethicalSourcingDetail.viewOrder, [Validators.pattern(/^[0-9]+$/)]],
      system: [this.ethicalSourcingDetail.system, Validators.required],
      default: [this.ethicalSourcingDetail.default, Validators.required],
      UX: [this.ethicalSourcingDetail.UX, Validators.required],
      local: [this.ethicalSourcingDetail.local, Validators.required],
      createdAt: [this.ethicalSourcingDetail.createdAt],
      updatedAt: [this.ethicalSourcingDetail.updatedAt]

    })
  }

  get f() {
    return this.updateUser.controls;
  }
  get titleShow() {
    return this.f.type.value!=='Text';
  }
  get descriptionShow() {
    return this.f.label.value==='About Us' || this.f.label.value==='Ethical Sourcing';
  }

  viewStore = (data: any) => {
    this.imgUrl = 'assets/img/user.png';
    this.ethicalSourcingDetail = data;
    this.idToShow = data._id;
    this.getEthicalSourcingDetail();
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getTutorialList(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTutorialList(1);
  }

    onFileChange = (fileInput: any) => {
        this.imageToUpload = <Array<File>>fileInput.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(this.imageToUpload[0]);
        reader.onload = (e: any) => {
            this.imgUrl = e.target.result;
        };
    };
  updateProfile = () => {
    this.submitted = true;
    const formData = new FormData();

    if (this.imageToUpload && this.imageToUpload[0]) {
        formData.append('image', this.imageToUpload[0]);
       
    }
    for (let key in this.updateUser.value) {

        if(this.updateUser.value[key]!==null){

            formData.append(key, this.updateUser.value[key]);
        }
    }
   
    this.userService
      .updateConstantConfig(formData, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: ' Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
          this.getTutorialList(this.page)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

  deleteStore = (id: any,heading:any) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteStatic(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getTutorialList(this.page)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

}
