<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
	<div class="mt-8 row">
		<div class="intro-y h-10 col-md-12 mb-4">
			<div class="card">
				<div class="card-content">
					<div class="row">
						<div class="col-lg-12 ">
							<h3 class="text-lg mb-0 mt-0">
								Group Management (content grouping)
                                
							</h3>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3 "></div>
						<div class="col-md-9 ">
						  <div class="form-group text-right showing_line" >
							<span>
							  Showing 
							  <b>{{ (currentPages - 1) * tableSize+1}}</b>
							  -
							  <b>{{ ((currentPages - 1) * tableSize+1) + (subscriptionList?subscriptionList.length-1:0)}}</b>
							  of 
							  <b>{{count}}</b>
							   items
							</span>
						  </div>
						</div>
					  </div>
					<div class="table-responsive">
						<table width="100%" class="table table-bordered table-striped table-crystal"> 
							<thead>
								<th width="5%">Sr No.</th>
								<th width="15%">groupName</th>
								<th width="5%">groupOrder</th>
								<th width="10%">groupType</th>
                                <th width="5%">type</th>
								<th width="10%">verticalViewCount</th>
								<th width="10%">groupMaximumCount</th>
								<th width="20%">description</th>
								<th class="">createdAt</th>
                                    <th class="">updatedAt</th>
								
							</thead> 
							
									<tbody>
								
							  <tr *ngFor="let subscription of subscriptionList | paginate : {
								itemsPerPage: tableSize,
								currentPage: page,
								totalItems: count
							  }; index as i"> 

									<td width="5%">{{ (currentPages - 1) * postsPerPage + i + 1 }}</td>
									<td width="15%">{{subscription.groupName}}</td>
									<td width="5%" class="text-center">{{subscription.groupOrder}}</td>
									<td width="10%">{{subscription.groupType}}</td>
									<td width="5%">{{subscription.type}}</td>
									<td width="10%" class="text-center">{{subscription.verticalViewCount}}</td>
                                    <td width="10%" class="text-center">{{subscription.groupMaximumCount}}</td>
									<td width="20%">{{subscription.description}}</td>
									<td class="">{{ subscription.createdAt }}</td>
                                    <td class="">{{ subscription.updatedAt }}</td>
									
								</tr>
								</tbody>
								</table>
								
						
					</div>
				</div>
				<div class="d-flex justify-content-center"> 
				
				</div> 
			</div>
		</div>
	</div>
	<div class="modal fade" id="deleteSubscription" role="dialog">
		<div class="modal-dialog">
		
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal">
						&times;
					</button>
					<h4 class="modal-title">Delete</h4>
				</div>
				<div class="modal-body">
					<p>Are you sure you want to delete this data?</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-default" data-dismiss="modal">
						Close
					</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">
						Ok
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="viewSubscription">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal">&times;</button>
				</div>
				<div class="card">
					<div class="card-content">
						<div class="row">

							<div class="col-lg-12 ">
								<h3 class="text-lg mb-0">
									Group Info
								</h3>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-lg-12 col-md-12 col-sm-12 col-12">
								<div class="card">
									<div class="card-body">
										<form [formGroup]="subscriptionForm" (ngSubmit)="updateGroupInfo()"
											class="custom-mt-form">

											<div class="row m-0">
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Group Name</label>
														<input formControlName="groupName" type="text"
															class="form-control" placeholder="Enter Group Name">
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Group Type</label>
														<div class="selectbox">
															<select class="form-control custom-select"
                                                                    formControlName="groupType">
																<option value="">Select</option>
																<option value="Vertical">Vertical</option>
																<option value="Horizontal">Horizontal</option>
															</select>
														</div>
													</div>
												</div>

												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Group Order</label>
														<div class="selectbox">
															<select class="form-control custom-select"
                                                                    formControlName="groupOrder">
																<option value="">Select</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
																<option value="6">6</option>
																<option value="7">7</option>
															</select>
														</div>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Type</label>
														<input formControlName="type" type="number"
															class="form-control" placeholder="Enter Type">
													</div>
												</div>
                                                <div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Vertical View Count</label>
														<input formControlName="verticalViewCount" type="number"
															class="form-control" placeholder="Enter Vertical View Count">
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Group Maximum Count</label>
														<input formControlName="groupMaximumCount" type="number"
															class="form-control" placeholder="Group Maximum Count">
													</div>
												</div>

                                                <div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Description</label>
														<input formControlName="description" type="text"
															class="form-control" placeholder="Description">
													</div>
												</div>
											</div>
											<div class="col-lg-12 col-md-12 col-sm-12 col-12">
												<div class="form-group custom-mt-form-group">
													<button class="btn btn-success mr-2" type="submit">Submit</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>