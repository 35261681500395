/* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.*/
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { Router } from "@angular/router";
import {NotificationsService} from "../../../services/notifications.service";
import { SessionService } from "../../../services/session.service";



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  submitted = false;
  
  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private router: Router,private sessionService: SessionService,private notify:NotificationsService) {
   }

  ngOnInit(): void {
    this.loginControls()


  }

  loginControls = () => {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.minLength(6), Validators.required]],
      remember: [""]
    });    
  };
  
  get f() {
    return this.loginForm.controls;
  }

  submitForm = ()=>{
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.userService.login(this.loginForm.value).toPromise().then((resp)=>{
      if(resp.success && resp.data.role && resp.data.role==="ADMIN"){

        if (this.loginForm.value.remember) {
            localStorage.setItem("token",JSON.stringify(resp.data.token))
        }else{
            sessionStorage.setItem("token",JSON.stringify(resp.data.token))
        }
        this.router.navigate(["/admin/dashboard"]);
      }else{
        this.notify.showError("you are not authorized")
      }
    }); 
  }
  

}
