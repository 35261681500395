/*@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUrl } from '../../../../../../utils/config'

@Component({
  selector: 'app-add-app-tutorial',
  templateUrl: './add-app-tutorial.component.html',
  styleUrls: ['./add-app-tutorial.component.css']
})
export class AddAppTutorialComponent implements OnInit {
  addSubscriptionForm !: FormGroup;
  submitted = false;
  loading = false;
  base: any = ApiUrl;

  constructor(private userService: UserService, private formbuilder: FormBuilder, private router: Router, private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.addSubscriptionControler()
  }

  addSubscriptionControler = () => {
    this.addSubscriptionForm = this.formbuilder.group({
      description: ['', Validators.required],
      heading: ['App Tutorial', Validators.required],
      title: ['', Validators.required]
    })
  }

  addSubscription = () => {
    this.submitted = true;
    if (this.addSubscriptionForm.invalid) {
      return;
    }
    this.addSubscriptionForm.value;
    this.loading = true;
    this.userService.addContent(this.addSubscriptionForm.value).toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'App Tutorial Added Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.router.navigate(["/admin/appTutorial"]);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }
}
