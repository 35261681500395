import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { ApiUrl } from "../../../../utils/config";

@Component({
  selector: 'app-community-wall',
  templateUrl: './community-wall.component.html',
  styleUrls: ['./community-wall.component.css']
})
export class CommunityWallComponent implements OnInit {

  postList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  postDetails: any;
  base: any = ApiUrl;

  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getallPost(1);
  }

  getallPost = (page: Number) => {
    page = page ? page : 1
    this.userService.getallPost(page).toPromise().then(response => {
      if (response.success) {
        this.postList = response.data.result;
        this.count = response.data.count;
      }
    });
  }

  onChange = (e: any, id: any) => {
    let message = !e ? 'post is blocked now' : "post is active now"
    let data = {
      id: id,
      postStatus: !e ? "BLOCK" : "ACTIVE"
    }
    this.userService.blockUnblockPost(data).toPromise().then(response => {
      if (response.success) {
        this.notify.showSuccess(message)
      }
    })
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getallPost(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getallPost(1);
  }

  viewPost = (data: any) => {
    this.postDetails = data;
  }

}
