<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-3 col-md-6">
      <div class="card card-stats">
        <div class="card-header">
          <div class="icon icon-warning">
            <i class="zmdi zmdi-equalizer"></i>
          </div>
        </div>
        <div class="card-content">
          <p class="category"><strong>Total Users Registered</strong></p>
          <h3 class="card-title">{{totalRecord ? totalRecord.userCount:""}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons text-info">info</i>
          
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="card card-stats">
        <div class="card-header">
          <div class="icon icon-rose">
            <i class="zmdi zmdi-shopping-cart"></i>
          </div>
        </div>
        <div class="card-content">
          <p class="category"><strong>Total Store</strong></p>
          <h3 class="card-title">{{totalRecord ? totalRecord.storeCount:""}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons text-info">info</i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="card card-stats">
        <div class="card-header">
          <div class="icon icon-success">
            <i class="zmdi zmdi-money"></i>
          </div>
        </div>
        <div class="card-content">
          <p class="category"><strong>Total Subscription</strong></p>
          <h3 class="card-title">{{totalRecord ? totalRecord.subscriptionCount:""}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">date_range</i> Weekly sales
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="card card-stats">
        <div class="card-header">
          <div class="icon icon-info">
            <i class="zmdi zmdi-nature-people"></i>
          </div>
        </div>
        <div class="card-content">
          <p class="category"><strong>Total Crystal Count</strong></p>
          <h3 class="card-title">{{totalRecord ? totalRecord.crystalCount:""}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">update</i> Just Updated
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="card" style="min-height: 350px">
        <div class="card-header card-header-icon">
          <i class="material-icons">timeline</i>
        </div>
        <div class="card-content">
          <h4 class="card-title">Sales Trend
          </h4>
          <div class="chart-edge">
            <div id="line-chart" class="demo-placeholder"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card" style="min-height: 350px">
        <div class="card-content">
          <h4 class="card-title">Region-wise Sales
          </h4>
          <div id="dash1-sales-chart" class="chart">
            <canvas id="doughnutChart" height="400" width="498"
              style="display: block; width: 498px; height: 400px;"></canvas>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>