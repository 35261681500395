/* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.*/

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiUrl } from "../../../../utils/config";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'


@Component({
  selector: 'app-app-tutorial',
  templateUrl: './app-tutorial.component.html',
  styleUrls: ['./app-tutorial.component.css']
})
export class AppTutorialComponent implements OnInit {
  ethicalSourcingDetail: any;
  currentPages = 1;
  postsPerPage = 10;
  updateUser !: FormGroup;
  tableSize = 10;
  tutorialList: any;
  submitted = false;
  loading = false;
  count = 0;
  page = 1;
  idToShow: any
  base: any = ApiUrl;
  locationList:any

  public Editor = CKEditorModule;
  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
  

  ) { }

  ngOnInit(): void {
    this.getTutorialList(1);
    this.updatefromControl();
    this.getLanguageList()
  }

  updatefromControl = () => {
    this.updateUser = this.formbuilder.group({
      description: ['', Validators.required],
      heading: ['', Validators.required],
      title: ['', Validators.required],
      location:[0,Validators.required]
    })
  }

  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }

  getTutorialList = (page: Number) => {
    this.userService.getByHeading("App Tutorial").toPromise().then(response => {
      if (response.success) {
        this.tutorialList = response.data;
        this.count = response.data.length;
      }
    });
  }

  getEthicalSourcingDetail = () => {
    this.updateUser = this.formbuilder.group({
      description: [this.ethicalSourcingDetail.description ? this.ethicalSourcingDetail.description : '',Validators.required],
      heading: [this.ethicalSourcingDetail.heading, Validators.required],
      title: [this.ethicalSourcingDetail.title, Validators.required],
      location:[this.ethicalSourcingDetail?.location,Validators.required]
    })
  }

  get f() {
    return this.updateUser.controls;
  }

  viewStore = (data: any) => {
    this.ethicalSourcingDetail = data;
    this.idToShow = data._id;
    this.getEthicalSourcingDetail();
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getTutorialList(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTutorialList(1);
  }

  updateProfile = () => {
    this.submitted = true;
    const formData = new FormData();
    for (let key in this.updateUser.value) {
      formData.append(key, this.updateUser.value[key]);
    }
    
    this.userService
      .updateTutorial(this.updateUser.value, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: ' Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
          this.getTutorialList(this.page)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

  deleteStore = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteStatic(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getTutorialList(this.page)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }
}
