<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Reported Posts (post report)
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>
                                
                                <ng-container *ngIf="reportedPostList;else other_content">
                                    {{ reportedPostList.length>0?(currentPages - 1) * tableSize+1:0}}

                                </ng-container>
                                <ng-template #other_content>0</ng-template>
                                   
                                </b>
                              -
                              <b>
                                <ng-container *ngIf="reportedPostList;else other_content">
                                    {{ reportedPostList.length>0?((currentPages - 1) * tableSize+1) + (reportedPostList?reportedPostList.length-1:0):'0'}}
                                </ng-container>   
                                <ng-template #other_content>0</ng-template>
                                 
                                </b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">posted_by</th>
                                    <th class="text-center whitespace-no-wrap">reported_by</th>
                                    <th class="text-center whitespace-no-wrap">postId</th>
                                    <th class="text-center whitespace-no-wrap">reportReason</th>
                                

                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let post of reportedPostList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{post.postId?post.postId.created_by.guId:''}}
                                        
                                    </td>
                                    <td class="text-center">{{post.reported_by?post.reported_by.guId:''}} 
                                    </td>
                                    <td class="text-center">{{post.postId?post.postId._id:''}}</td>
                                    <td class="text-center">{{post.reportReason}}</td>
                                
                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewUser" (click)="viewPost(post)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="intro-x" *ngIf="reportedPostList!=undefined && reportedPostList.length==0">
                                    <td class="text-center" colspan="6">
                                        No record found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewUser">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">Post Info</h4>
                  <div class="row">
                    
                      <div class="">
                          <div class="columns">
                              <div class="circle">
                                  
                                  <img src="{{
									reportedPostDetails && reportedPostDetails.postId
									  ? base + '/'+ reportedPostDetails.postId.postImg
									  : 'assets/img/user.png'
								  }}" class="img-rounded w-100 h-100">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>posted_by</th>
                                <td><ng-container *ngIf="reportedPostDetails!=undefined && reportedPostDetails.postId!=undefined">
                                    {{reportedPostDetails && reportedPostDetails.postId ?
                                    reportedPostDetails.postId.created_by.guId :""}}</ng-container>
                                </td>
                                <th>reported_by</th>
                                <td><ng-container *ngIf="reportedPostDetails!=undefined && reportedPostDetails.reported_by!=undefined">
                                    {{reportedPostDetails && reportedPostDetails.reported_by ?
                                    reportedPostDetails.reported_by.guId:""}}
                                    </ng-container>
                                </td>
                            </tr>
                            
                            <tr>
                                <th>postId</th>
                                <td>{{reportedPostDetails &&reportedPostDetails.postId ? reportedPostDetails.postId._id:""}}</td>
                                <th>reportReason</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.reportReason:""}}</td>
                            </tr>
                            <tr>
                                <th>createdAt</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails?reportedPostDetails.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
       
</div>