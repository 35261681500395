<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
	<div class="mt-8 row">
		<div class="intro-y h-10 col-md-12 mb-4">
			<div class="card">
				<div class="card-content">

					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12">
							<div class="card">
								<div class="card-body">
									<form [formGroup]="addCrystalForm" (ngSubmit)="addCrystal()" class="custom-mt-form">

										<div class="d-flex flex-wrap">
											
											<div class="">
												<h4 class="text-center">Raw Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="rawImageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploadss">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploadss" class="file-upload"
															(change)="onImageChangeRaw($event)" type="file"
															accept="image/*">
													</div>
												</div>
												
											</div>
											<div class="ml-4">
												<h4 class="text-center">Raw Thumbnail Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="raw_thumbnail_imageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploads2">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploads2" class="file-upload"
															(change)="onImageChangeraw_thumbnail($event)" type="file"
															accept="image/*">
													</div>
												</div>
												
											</div>
											<div class="ml-4">
												<h4 class="text-center">Polished Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="polishedImageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploads3">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploads3" class="file-upload"
															(change)="onImageChangepolishedImage($event)" type="file"
															accept="image/*">
													</div>
												</div>
												
											</div>
											<div class="ml-4">
												<h4 class="text-center">Polished Thumbnail Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="polished_thumbnail_imageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploads4">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploads4" class="file-upload"
															(change)="onImageChangepolished_thumbnail_image($event)" type="file"
															accept="image/*">
													</div>
												</div>
												
											</div>
										</div>

										<div class="row m-0">
											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Crystal Name</label> <input
														formControlName="crystalName" type="text" class="form-control"
														placeholder="Enter Crystal Name" [ngClass]="{
															'is-invalid': submitted && f.crystalName.errors
														  }">
													<div *ngIf="submitted && f.crystalName.errors"
														class="invalid-feedback">
														<div *ngIf="f.crystalName.errors.required">
															Crystal Name is required
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Known As</label>
													<input formControlName="knownAs" type="text" class="form-control">
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Known For</label>
													<input formControlName="knownFor" type="text" class="form-control">
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Physical</label>
													<div class="input-group">
														<input #newPhysical
															(keyup.enter)="addPhysical(newPhysical.value)"
															(blur)="addPhysical(newPhysical.value);
															newPhysical.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addPhysical(newPhysical.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let physical of physicalArray;index as i">
															{{physical}}
															<button type="button"
																(click)="removeButton('physicalArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Description</label> <input
														formControlName="description" type="text" class="form-control">
												</div>
											</div>

											<!-- <div class=" col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Description</label> <editor
													formControlName="description"
													[init]="{
														height: 500,
														width: 500,
														menubar: false,
														plugins: [
														'advlist autolink lists link image charmap print preview anchor',
														'searchreplace visualblocks code fullscreen',
														'insertdatetime media table paste code help wordcount'
														],
														toolbar:
														'undo redo | formatselect | bold italic backcolor | \
														alignleft aligncenter alignright alignjustify | \
														bullist numlist outdent indent | removeformat | help'
													}"
													></editor>
												</div>
											</div> -->

											
											
											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Primary Chakra</label>
													<div class="input-group">
														<input #newPrimaryChakra
															(keyup.enter)="addPrimaryChakra(newPrimaryChakra.value)"
															(blur)="addPrimaryChakra(newPrimaryChakra.value);
															newPrimaryChakra.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addPrimaryChakra(newPrimaryChakra.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let primaryChakra of primaryChakraArray;index as i">
															{{primaryChakra}}
															<button type="button"
																(click)="removeButton('primaryChakraArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Secondary Chakra</label>
													<div class="input-group">
														<input #newSecondaryChakra
															(keyup.enter)="addSecondaryChakra(newSecondaryChakra.value)"
															(blur)="addSecondaryChakra(newSecondaryChakra.value);
															newSecondaryChakra.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addSecondaryChakra(newSecondaryChakra.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let secondaryChakra of secondaryChakraArray;index as i">
															{{secondaryChakra}}
															<button type="button"
																(click)="removeButton('secondaryChakraArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Spiritual</label>
													<div class="input-group">
														<input #newSpiritual
															(keyup.enter)="addSpiritual(newSpiritual.value)"
															(blur)="addSpiritual(newSpiritual.value);
															newSpiritual.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addSpiritual(newSpiritual.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of spiritualArray;index as i">
															{{dataToDisplay}}
															<button type="button"
																(click)="removeButton('spiritualArray',i)">
																<i class="fa fa-minus"></i>
															</button>

														</li>
													</ul>
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Color</label>
													<div class="input-group">
														<input #newColor
															(keyup.enter)="addColor(newColor.value)"
															(blur)="addColor(newColor.value);
															newColor.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addColor(newColor.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of colorArray;index as i">
															{{dataToDisplay}}
															<button type="button"
																(click)="removeButton('colorArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>

											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Rarity</label>
													<input formControlName="rarity" type="text" class="form-control">
												</div>
											</div>


											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Chemical Composition</label> <input
														formControlName="chemicalComposition" type="text"
														class="form-control">
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Mineral Class</label>
													<div class="input-group">
														<input #newMineralClass
															(keyup.enter)="addMineralClass(newMineralClass.value)"
															(blur)="addMineralClass(newMineralClass.value);
															newMineralClass.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addMineralClass(newMineralClass.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of mineralClassArray;index as i">
															<span class="left">
																{{dataToDisplay}}

															</span>
															<span class="right">
															<button type="button"
																(click)="removeButton('mineralClassArray',i)">
																<i class="fa fa-minus"></i>
															</button>
															</span>
														</li>
													</ul>
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Crystal System</label> <input
														formControlName="crystalSystem" type="text"
														class="form-control">
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Astrological Sign</label>
													<div class="input-group">
														<input #newAstrologicalSign
															(keyup.enter)="addAstrologicalSign(newAstrologicalSign.value)"
															(blur)="addAstrologicalSign(newAstrologicalSign.value);
															newAstrologicalSign.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addAstrologicalSign(newAstrologicalSign.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of astrologicalSignArray;index as i">
															{{dataToDisplay}}
															<button type="button"
																(click)="removeButton('astrologicalSignArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>


											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Ethical Sourced</label> <input
														formControlName="ethicalSourced" type="text"
														class="form-control">
												</div>
											</div>


											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Hardness</label> <input
														formControlName="hardness" type="text" class="form-control">
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Location</label>
													<div class="input-group">
														<input #newLocation
															(keyup.enter)="addLocation(newLocation.value)"
															(blur)="addLocation(newLocation.value);
															newLocation.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addLocation(newLocation.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of locationArray;index as i">
															{{dataToDisplay}}
															<button type="button"
																(click)="removeButton('locationArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Numerical Vibration</label>
													<div class="input-group">
														<input #newNumericalVibration
															(keyup.enter)="addNumericalVibration(newNumericalVibration.value)"
															(blur)="addNumericalVibration(newNumericalVibration.value);
															newNumericalVibration.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addNumericalVibration(newNumericalVibration.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of numericalVibrationArray;index as i">
															{{dataToDisplay}}
															<button type="button"
																(click)="removeButton('numericalVibrationArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>

											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Pronunciation</label> <input
														formControlName="pronunciation" type="text"
														class="form-control">
												</div>
											</div>


											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Affirmation</label> <input
														formControlName="affirmation" type="text" class="form-control">
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Emotional</label>
													<div class="input-group">
														<input #newEmotional
															(keyup.enter)="addEmotional(newEmotional.value)"
															(blur)="addEmotional(newEmotional.value);
															newEmotional.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addEmotional(newEmotional.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of emotionalArray;index as i">
															{{dataToDisplay}}
															<button type="button"
																(click)="removeButton('emotionalArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>
												
											<div class="col-lg-3 col-md-3 col-sm-6 col-12">
												<div class="form-group custom-mt-form-group custom-group">
													<label class="control-label">Alternate StoneName</label>
													<div class="input-group">
														<input #newAlternateStoneName
															(keyup.enter)="addAlternateStoneName(newAlternateStoneName.value)"
															(blur)="addAlternateStoneName(newAlternateStoneName.value);
															newAlternateStoneName.value='' " class="form-control custom-input-group">
														<button type="button"
															(click)="addAlternateStoneName(newAlternateStoneName.value)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
													<ul>
														<li *ngFor="let dataToDisplay of alternateStoneNameArray;index as i">
															{{dataToDisplay}}
															<button type="button"
																(click)="removeButton('alternateStoneNameArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</li>
													</ul>
												</div>
											</div>	
											<div class="col-lg-3 col-md-3 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Brain</label> 
													
													<select class="form-control" formControlName="brain">
														<option value="0">0</option>
														<option value="1">1</option>

													</select>
												</div>
											</div>

										</div>

										<div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
											<div class="form-group custom-mt-form-group">
												<button class="btn btn-success mr-2" type="submit">Submit</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>