import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../services/user.service";
import { NotificationsService } from "../../../services/notifications.service";
import { ApiUrl } from "../../../utils/config";
import { Location } from '@angular/common';
import { Router, ActivatedRoute,Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';


@Component({
  selector: 'app-log-table',
  templateUrl: './log-table.component.html',
  styleUrls: ['./log-table.component.css']
})
export class LogTableComponent implements OnInit {

  reportedPostList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;
  currentUrl: any = '';

  
  constructor(
    private userService: UserService,
    private notify: NotificationsService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
       
        
      }

      if (event instanceof NavigationEnd) {
        
          
          this.getSubscriptionList();
      }

      if (event instanceof NavigationError) {
          
      }
    });
  }

  getSubscriptionList = () => {
    if(this.location.path() != ''){
      this.currentUrl = this.location.path();
    } else {
      this.currentUrl = 'Home'
    }
    
  }

  get show(){ return this.currentUrl==='/admin/logTable'}

  ngOnInit(): void {
    this.getallReportedPost(1);
  }

  getallReportedPost = (page: Number) => {
    page = page ? page : 1
    this.userService.getLogTable(page).toPromise()
      .then(response => {
        if (response.success) {
          this.reportedPostList = response.data.result;
          this.count = response.data.count;
        }
      });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getallReportedPost(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getallReportedPost(this.page);
  }

  viewPost = (data: any) => {
    this.reportedPostDetails = data;
  }

}
