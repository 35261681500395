import { Injectable } from "@angular/core"; // injectable for Decorator that marks a class as available to be provided and injected as a dependency.
import { Router, NavigationStart } from "@angular/router";// import router for navigation change detect url without page refresh
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http"; // common intercept for angular htto

import { Observable, throwError } from "rxjs"; // set observable for request, response handle
import { map, catchError,filter } from "rxjs/operators"; // set map and catch error for handle response value
import { NgxSpinnerService } from "ngx-spinner"; // set spinner with ngx spinner after response handle
import {NotificationsService} from "../../services/notifications.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  curruntUrl = this.router.url;
  constructor( // decalre access handler in constructor
    private router: Router,
    // private spinner: NgxSpinnerService,
    private notifierService: NotificationsService
  ) {
    router.events.subscribe((event) => { // get router on change router without page load
      if (event instanceof NavigationStart) {
        this.curruntUrl = event.url; // set url in variable
      }
    });
  }

  /**
   * Intercepts token interceptor
   * This is used for authenticate all APIs
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    

    // handle http status
       return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 403) {
              // this.router.navigate(["/login"]);
              this.router.navigate(["/admin/dashboard"]);
            } else {
              return event;
            }
          }
          return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let status = error.status;
        if (status === 400) {
          this.notifierService.showError(error.error.message);
        } else if (status === 403) {
          // this.router.navigate(["/login"]);
          this.router.navigate(["/admin/dashboard"]);
        }
        return throwError(error);
    }));
      
   
  }
}
