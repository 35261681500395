<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <div class="row">
                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">Change Password</h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-7 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="resetPasswordForm"
                                    (ngSubmit)="changePassword()" class="custom-mt-form">
                                        <div class="row m-0">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Old Password</label>
                                                    <input formControlName="oldPassword" type="password" class="form-control"
                                                        placeholder="Enter old password"  [ngClass]="{
                                                            'is-invalid': submitted && f.oldPassword.errors
                                                          }"/>
                                                    <div  *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback" >
                                                        <div *ngIf="f.oldPassword.errors.required">
                                                            Old-Password is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">New Password</label>
                                                    <input formControlName="password" type="password" class="form-control"
                                                        placeholder="Enter new password" [ngClass]="{
                                                            'is-invalid': submitted && f.password.errors }"/>
                                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required">
                                                            Password is required
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">New Password</label>
                                                    <input formControlName="confirmPassword" type="password" class="form-control"
                                                        placeholder="Confirm password" [ngClass]="{
                                                            'is-invalid': submitted && f.confirmPassword.errors
                                                          }"/>
                                                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                        <div *ngIf="f.confirmPassword.errors.required">
                                                            Confirm-Password is required
                                                        </div>
                                                        <div *ngIf="f.confirmPassword.errors.ConfirmPassword">
                                                            Passsword and ConfirmPassword didn't match.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>