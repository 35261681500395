<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Stores & More (company table)
                       
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-9">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * tableSize+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * tableSize+1) + (storeList?storeList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="whitespace-no-wrap">Sr No.</th>
                                    <th class="whitespace-no-wrap">shopName</th>
                                    <th class="text-center whitespace-no-wrap">description</th>
                                    <th class="text-center whitespace-no-wrap">address</th>
                                    <th class="text-center whitespace-no-wrap">website</th>
                                    <th class="text-center whitespace-no-wrap">firstName</th>
                                    <th class="text-center whitespace-no-wrap">lastName</th>
                                    <th class="text-center whitespace-no-wrap">title</th>
                                    <th class="text-center whitespace-no-wrap">mobileNumber</th>
                                    <th class="text-center whitespace-no-wrap">email</th>
                                    <th class="text-center whitespace-no-wrap">address</th>
                                    <th class="text-center whitespace-no-wrap">city</th>
                                    <th class="text-center whitespace-no-wrap">state</th>
                                    <th class="text-center whitespace-no-wrap">zip</th>
                                    <th class="text-center whitespace-no-wrap">country</th>
                                    <th class="text-center whitespace-no-wrap">website</th>
                                    <th class="text-center whitespace-no-wrap">category</th>
                                    <th class="text-center whitespace-no-wrap">onlineStore</th>
                                    <th class="text-center whitespace-no-wrap">EW_Partner</th>
                                    <th class="text-center whitespace-no-wrap">investigated</th>
                                    <th class="text-center whitespace-no-wrap">ecommerceLink</th>
                                    <th class="text-center whitespace-no-wrap">facebook</th>
                                    <th class="text-center whitespace-no-wrap">instagram</th>
                                    <th class="text-center whitespace-no-wrap">twitter</th>
                                    <th class="text-center whitespace-no-wrap">active</th>
                                    
                                   
                                    <th class="text-center whitespace-no-wrap">ethicalRanking</th>
                                    <th class="text-center whitespace-no-wrap">qualityRanking</th>
                                    <th class="text-center whitespace-no-wrap">experienceRanking</th>
                                    <th class="text-center whitespace-no-wrap">speciality</th>
                                    <th class="text-center whitespace-no-wrap">briefDescription</th>
                                    <th class="text-center whitespace-no-wrap">storeImage</th>
                                    <th class="text-center whitespace-no-wrap">thumbnail_image</th>
                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>
                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let store of storeList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{store.shopName}}</td>
                                    <td class="text-center">{{store.description}}</td>
                                    <td class="text-center">{{store.address}}</td>
                                    <td class="text-center">{{store.website}}</td>
                                    <td class="text-center">{{store.firstName}}</td>
                                    <td class="text-center">{{store.lastName}}</td>
                                    <td class="text-center">{{store.title}}</td>
                                    <td class="text-center">{{store.mobileNumber}}</td>
                                    <td class="text-center">{{store.email}}</td>
                                    <td class="text-center">{{store.address}}</td>
                                    <td class="text-center">{{store.city}}</td>
                                    <td class="text-center">{{store.state}}</td>
                                    <td class="text-center">{{store.zip}}</td>
                                    <td class="text-center">{{store.country}}</td>
                                    <td class="text-center">{{store.website}}</td>
                                    <td class="text-center">{{store.category}}</td>
                                    <td class="text-center">{{store.onlineStore}}</td>
                                    <td class="text-center">{{store.EW_Partner}}</td>
                                    <td class="text-center">{{store.investigated}}</td>
                                    <td class="text-center">{{store.ecommerceLink}}</td>
                                    <td class="text-center">{{store.facebook}}</td>
                                    <td class="text-center">{{store.instagram}}</td>
                                    <td class="text-center">{{store.twitter}}</td>
                                    <td class="text-center">{{store.active}}</td>
                                    

                                    <td class="text-center">{{store.ethicalRanking}}</td>
                                    <td class="text-center">{{store.qualityRanking}}</td>
                                    <td class="text-center">{{store.experienceRanking}}</td>
                                    <td class="text-center">{{store.speciality}}</td>
                                    <td class="text-center">
                                        {{store.briefDescription}}
                                    </td>
                                    <td class="text-center">{{ store.storeImage }}</td>
                                    <td class="text-center">{{ store.thumbnail_image }}</td>
                                    <td class="text-center">{{ store.createdAt }}</td>
                                    <td class="text-center">{{ store.updatedAt }}</td>
                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewStore" (click)="viewStore(store)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                            
                                            
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteStore" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 class="modal-title">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this data?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewStore" >        
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">Store Info</h4>
                  <div class="row">
                    
                      <div class="">
                          <div class="columns">
                              <div class="circle">
                                  <div class="pic-upload">
                                      <img [src]="imageUrl" class="w-100 h-100" />
                                  </div>
                                  <img class="profile-pic" src="{{
                                  selected_image
                                      ? base + '/'+ selected_image
                                      : 'assets/img/user.png'
                                  }}">
                              </div>
                          </div>
                      </div>
                      <div class="">
                          <div class="columns">
                              <div class="circle">
                                  <div class="pic-upload">
                                      <img [src]="thumbnail_imageUrl" class="w-100 h-100" />
                                  </div>
                                  <img class="profile-pic" src="{{
                                  selected_image
                                      ? base + '/'+ selected_image
                                      : 'assets/img/user.png'
                                  }}">
                              </div>
                          </div>
                      </div>
                      
                      
                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>shopName</th>
                                <td>{{storeDetails ? storeDetails.shopName:""}}</td>
                                <th>firstName</th>
                                <td>{{storeDetails ? storeDetails.firstName:""}}</td>
                            </tr>
                            <tr>
                                <th>lastName</th>
                                <td>{{storeDetails ? storeDetails.lastName:""}}</td>
                                <th>title</th>
                                <td>{{storeDetails ? storeDetails.title:""}}</td>
                            </tr>
                            <tr>
                                <th>description</th>
                                <td>{{storeDetails ? storeDetails.description:""}}</td>
                                <th>briefDescription</th>
                                <td>{{storeDetails ? storeDetails.briefDescription:""}}</td>
                            </tr>
                            <tr>
                                <th>address</th>
                                <td>{{storeDetails ? storeDetails.address:""}}</td>
                                <th>speciality</th>
                                <td>{{storeDetails ? storeDetails.speciality:""}}</td>
                            </tr>
                            <tr>
                                <th>city</th>
                                <td>{{storeDetails ? storeDetails.city:""}}</td>
                                <th>state</th>
                                <td>{{storeDetails ? storeDetails.state:""}}</td>
                            </tr>
                            <tr>
                                <th>zip</th>
                                <td>{{storeDetails ? storeDetails.zip:""}}</td>
                                <th>country</th>
                                <td>{{storeDetails ? storeDetails.country:""}}</td>
                            </tr>
                            <tr>
                                <th>website</th>
                                <td>{{storeDetails ? storeDetails.website:""}}</td>
                                <th>mobileNumber</th>
                                <td>{{storeDetails ? storeDetails.mobileNumber:""}}</td>
                            </tr>
                            <tr>
                                <th>email</th>
                                <td>{{storeDetails ? storeDetails.email:""}}</td>
                                <th>category</th>
                                <td>{{storeDetails ? storeDetails.category:""}}</td>
                            </tr>
                            <tr>
                                <th>ethicalRanking</th>
                                <td>{{storeDetails ? storeDetails.ethicalRanking:""}}</td>
                                <th>qualityRanking</th>
                                <td>{{storeDetails ? storeDetails.qualityRanking:""}}</td>
                            </tr>
                            <tr>
                                <th>experienceRanking</th>
                                <td>{{storeDetails ? storeDetails.experienceRanking:""}}</td>
                                <th>onlineStore</th>
                                <td>{{storeDetails ? storeDetails.onlineStore:""}}</td>
                            </tr>
                            <tr>
                                <th>EW_Partner</th>
                                <td>{{storeDetails ? storeDetails.EW_Partner:""}}</td>
                                <th>investigated</th>
                                <td>{{storeDetails ? storeDetails.investigated:""}}</td>
                            </tr>
                            <tr>
                                <th>ecommerceLink</th>
                                <td>{{storeDetails ? storeDetails.ecommerceLink:""}}</td>
                                <th>facebook</th>
                                <td>{{storeDetails ? storeDetails.facebook:""}}</td>
                            </tr>
                            <tr>
                                <th>instagram</th>
                                <td>{{storeDetails ? storeDetails.instagram:""}}</td>
                                <th>twitter</th>
                                <td>{{storeDetails ? storeDetails.twitter:""}}</td>
                            </tr>
                            <tr>
                                <th>active</th>
                                <td>{{storeDetails ? storeDetails.active:""}}</td>
                                <th></th>
                                <td></td>
                            </tr>
                            <tr>
                                <th>createdAt</th>
                                <td>{{storeDetails ? storeDetails.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="storeDetails!=undefined">
                                    {{ storeDetails?storeDetails.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
</div>