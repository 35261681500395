import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-csvread',
  templateUrl: './csvread.component.html',
  styleUrls: ['./csvread.component.css']
})
export class CsvreadComponent implements OnInit {

	public userArray: Usercsv[] = [];

    constructor(private http: HttpClient){
    this.http.get('assets/credit.csv', {responseType: 'text'})
    .subscribe(
        data => {
            let csvToRowArray = data.split("\n");
            for (let index = 1; index < csvToRowArray.length - 1; index++) {
              let row = csvToRowArray[index].split(",");
              this.userArray.push(new Usercsv( parseInt( row[0], 10), row[1], row[2].trim()));
            }
            console.log(this.userArray);
        },
        error => {
            console.log(error);
        }
    );
  }

  uploadListener($event: any): void {
		
    	let text = [];  
    	let files = $event.srcElement.files;
		console.log(files); 
  }

  ngOnInit(): void {
  }

}

export class Usercsv{
  id: number;
  name: String;
  lastName: String;

  constructor(id: number, name: String, lastName: String){
    this.id = id;
    this.name = name;
    this.lastName = lastName;
  }
}
