<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <div class="row">
						<div class="col-lg-12 ">
							<h3 class="text-lg mb-0 mt-0">
								Crystals
								
								
							</h3>
							
							
						</div>
                    </div>
					<div class="row">
						<div class="col-md-3 ">

							<div class="search-box" style="margin-bottom: 5px;">
								<form class="search-form">
									<input class="form-control" placeholder="Search crystal name here..." type="text" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup)="onSearch($event)">
									<span class="search-btn">
										
									</span>
								</form>
							</div>
						</div>
						<div class="col-md-9 ">
							<div class="form-group text-right" style="margin-left: 10px;">
								<span>
									Showing 
									<b>
										<ng-container *ngIf="crystalList">
											{{ crystalList.length>0?(currentPages - 1) * postsPerPage+1:0}}

										</ng-container>
									</b>
									-
									<b>{{ ((currentPages - 1) * postsPerPage+1) + (crystalList?crystalList.length-1:0)}}</b>
									of 
									<b>{{count}}</b>
									 items
								</span>
							</div>
						</div>
					</div>
                    <div class="table-responsive">
                    <table width="100%" class="table table-bordered table-striped table-crystal">
                    	<thead>
							<th>GUID</th>
							<th>Crystal Id</th>
                    		<th>Crystal Name</th>
                    		<th>Description</th>
                    		<th>Known As</th>
                    		<th>Known For</th>
                    		<th>Physical</th>
                    		<th>Primary Chakra</th>
                    		<th>Secondary Chakra</th>
                    		<th>Spiritual</th>
                    		<th>Rarity</th>
                    		<th>Color</th>
                    		<th>Chemical Composition</th>
                    		<th>Mineral Class</th>
                    		<th>Crystal System</th>
                    		<th>Astrological Sign</th>
                    		<th>Ethical Sourced</th>
                    		<th>Hardness</th>
                    		<th>Location</th>
                    		<th>Numerical Vibration</th>
                    		<th>Pronunciation</th>
							<th>Affirmation</th>
							<th>Emotions</th>
                    		<th>Alternate StoneName</th>
							<th>Brain</th>
						

                    		<th width="10%">Actions</th>
                    	</thead>
                    	<tbody>
                    		<tr *ngFor="let crystal of crystalList | paginate : {
								itemsPerPage: tableSize,
								currentPage: page,
								totalItems: count
							  }; index as i">
								<td>{{crystal._id}}</td>
								<td>{{crystal._id}}</td>
                    			<td>{{crystal.crystalName}}</td>
                    			<td class="truncate" [innerHTML]="crystal.description"></td>
                    			<td>{{crystal.knownAs}}</td>
                    			<td>{{crystal.knownFor}}</td>
                    			<td class="truncate">{{crystal.physical}}</td>
                    			<td>{{crystal.primaryChakra}}</td>
                    			<td>{{crystal.secondaryChakra}}</td>
                    			<td class="truncate">{{crystal.spiritual}}</td>
                    			<td>{{crystal.rarity}}</td>
                    			<td>{{crystal.color}}</td>
                    			<td class="truncate">{{crystal.chemicalComposition}}</td>
                    			<td>{{crystal.mineralClass}}</td>
                    			<td>{{crystal.crystalSystem}}</td>
                    			<td>{{crystal.astrologicalSign}}</td>
                    			<td>{{crystal.ethicalSourced}}</td>
                    			<td>{{crystal.hardness}}</td>
                    			<td class="truncate">{{crystal.location}}</td>
                    			<td>{{crystal.numericalVibration}}</td>
                    			<td>{{crystal.pronunciation}}</td>
                    			<td class="truncate">{{crystal.affirmation}}</td>
								<td class="truncate">{{crystal.emotional}}</td>
								<td class="truncate">{{crystal.alternateStoneName}}</td>
								<td class="truncate">{{crystal.brain}}</td>
							

								<td style="min-width: 120px;">
									<a data-toggle="modal" data-target="#viewCrystal" (click)="viewCrystal(crystal)" class="text-succes mr-1">
										<i class="fa fa-eye"></i></a>
									<a data-toggle="modal" data-target="#viewStore" (click)="viewCrystal(crystal)" class="text-succes mr-1">
										<i class="fa fa-pencil-square-o"></i>
									</a>
									<a (click)="deleteCrystal(crystal._id)" class="text-danger"><i class="fa fa-trash"></i></a>
								</td>
                    		</tr>
							<tr class="intro-x" *ngIf="crystalList!=undefined && crystalList.length==0">
								<td class="text-center" colspan="27">
									No record found
								</td>
							</tr>
							
                    	</tbody>
                    </table>
				</div>
				<div class="d-flex justify-content-center">
					<pagination-controls 
					  previousLabel="Prev" 
					  nextLabel="Next" 
					  (pageChange)="onTableDataChange($event)">
					</pagination-controls>
				  </div>
                </div>
            </div>
        </div>
	</div>
	
	<div class="modal" id="viewCrystal">
		<div class="modal-dialog modal-lg">
		  <div class="modal-content">
			<div class="modal-header p-0">
			  <button type="button" class="close" data-dismiss="modal">
				&times;
			  </button>
			</div>
				<div class="modal-body">
					<h4 class="mb-4">Crystal Info</h4>
					<div class="row">
					   <!-- <div class="col-md-4">
							<div class="form-group mb-">
								<div class="uploadimageprofile ">
								   <img src="{{
									crystalDetails
									  ? base + '/'+ crystalDetails.thumbnail_image
									  : 'assets/img/user.png'
								  }}" class="img-rounded">
								</div>
							</div>
						</div>
						<div class="col-md-8">
							<div class="form-group mb-">
								<div class="uploadimageprofile ">
								   <img src="{{
									crystalDetails
									  ? base + '/'+ crystalDetails.image
									  : 'assets/img/user.png'
								  }}" class="img-rounded">
								</div>
							</div>
						</div> -->
						<div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="rawImageUrl?rawImageUrl:'assets/img/user.png'" class="w-100 h-100" />
									</div>
									<!-- <img class="profile-pic" src="{{
									selected_image
										? base + '/'+ selected_image
										: 'assets/img/user.png'
									}}"> -->
								</div>
							</div>
						</div>
						<div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="raw_thumbnail_imageUrl?raw_thumbnail_imageUrl:'assets/img/user.png'" class="w-100 h-100" />
									</div>
									<!-- <img class="profile-pic" src="{{
									selected_image
										? base + '/'+ selected_image
										: 'assets/img/user.png'
									}}"> -->
								</div>
							</div>
						</div>
						<div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="polishedImageUrl?polishedImageUrl:'assets/img/user.png'" class="w-100 h-100" />
									</div>
									<!-- <img class="profile-pic" src="{{
									selected_image
										? base + '/'+ selected_image
										: 'assets/img/user.png'
									}}"> -->
								</div>
							</div>
						</div>
						<div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="polished_thumbnail_imageUrl?polished_thumbnail_imageUrl:'assets/img/user.png'" class="w-100 h-100" />
									</div>
									<!-- <img class="profile-pic" src="{{
									selected_image
										? base + '/'+ selected_image
										: 'assets/img/user.png'
									}}"> -->
								</div>
							</div>
						</div>
						
					</div>
					<div class="detailed-view table-responsive">
                        <table width="100%" class="crystal-detail table table-bordered table-striped">
                             <tr>
                                  <th>Crystal Name</th>
                                  <td>{{crystalDetails ? crystalDetails.crystalName:""}}</td>
                                  <th>Known As</th>
                                  <td>{{crystalDetails ? crystalDetails.knownAs:""}}</td>
                             </tr>
                             <tr>
                                  <th>Known For</th>
                                  <td>{{crystalDetails ? crystalDetails.knownFor:""}}</td>
                                  <th>Physical</th>
                                  <td>{{crystalDetails ? crystalDetails.physical:""}}</td>
                             </tr>
                             <tr>
                                  <th>Description</th>
                                  <td [innerHTML]="crystalDetails ? crystalDetails.description:''"></td>
                                  <th>Primary Chakra</th>
                                  <td>{{crystalDetails ? crystalDetails.primaryChakra:""}}</td>
                             </tr>
                             <tr>
                                  <th>Secondary Chakra</th>
                                  <td>{{crystalDetails ? crystalDetails.secondaryChakra:""}}</td>
                                  <th>Spiritual</th>
                                  <td>{{crystalDetails ? crystalDetails.spiritual:""}}</td>
                             </tr>
                             <tr>
                                  <th>Color</th>
                                  <td>{{crystalDetails ? crystalDetails.color:""}}</td>
                                  <th>Rarity</th>
                                  <td>{{crystalDetails ? crystalDetails.rarity:""}}</td>
                             </tr>
                             <tr>
                                  <th>Chemical Composition</th>
                                  <td>{{crystalDetails ? crystalDetails.chemicalComposition:""}}</td>
                                  <th>Mineral Class</th>
                                  <td>{{crystalDetails ? crystalDetails.mineralClass:""}}</td>
                             </tr>
                             <tr>
                                  <th>Crystal System</th>
                                  <td>{{crystalDetails ? crystalDetails.crystalSystem:""}}</td>
                                  <th>Astrological Sign</th>
                                  <td>{{crystalDetails ? crystalDetails.astrologicalSign:""}}</td>
                             </tr>
                             <tr>
                                  <th>Ethical Sourced</th>
                                  <td>{{crystalDetails ? crystalDetails.ethicalSourced:""}}</td>
                                  <th>Hardness</th>
                                  <td>{{crystalDetails ? crystalDetails.hardness:""}}</td>
                             </tr>
                             <tr>
                                  <th>Location</th>
                                  <td>{{crystalDetails ? crystalDetails.location:""}}</td>
                                  <th>Numerical Vibration</th>
                                  <td>{{crystalDetails ? crystalDetails.numericalVibration:""}}</td>
                             </tr>
                             <tr>
                                  <th>Pronunciation</th>
                                  <td>{{crystalDetails ? crystalDetails.pronunciation:""}}</td>
                                  <th>Affirmation</th>
                                  <td>{{crystalDetails ? crystalDetails.affirmation:""}}</td>
                             </tr>
                             <tr>
                                  <th>Emotional</th>
                                  <td>{{crystalDetails ? crystalDetails.emotional:""}}</td>
                                  <th>Alternate StoneName</th>
                                  <td>{{crystalDetails ? crystalDetails.alternateStoneName:""}}</td>
                             </tr>
							 <tr>
                                <th>Created At</th>
                                <td>
									<ng-container *ngIf="crystalDetails!=undefined">
										{{crystalDetails.createdAt | date:'short'}}
									</ng-container>
								</td>
                                <th>Updated At</th>
                                <td>
									<ng-container *ngIf="crystalDetails!=undefined">
                                    	{{ crystalDetails.updatedAt| date:'short' }} 
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="viewStore">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header p-0">
			  <button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>   
			  <div class="card">
					  <div class="card-content">
						  <div class="row">
							 
							  <div class="col-lg-12 ">
								  <h3 class="text-lg mb-0">
									Crystal Info 
								  </h3>
							  </div>
						  </div>
						  <div class="row mt-3">
							  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
								  <div class="card">
									  <div class="card-body">
										<form [formGroup]="updateUser" (ngSubmit)="updateCrystal()" class="custom-mt-form">
											<div class="d-flex flex-wrap">
												<!-- <div>
													<h4 class="text-center">Image</h4>
													<div class="columns">
											
														<div class="circle">
															<img class="profile-pic" 
															src="{{
																crystalDetails
																? base + '/'+ crystalDetails.image
																: 'assets/img/user.png'
															}}">
														</div>
														<div class="p-image">
											
															<label for="fileupload">
																<i class="fa fa-camera upload-button"></i>
											
															</label>
															<input id="fileupload" class="file-upload" (change)="onFileChange($event)" type="file" accept="image/*">
											
														</div>
													</div>
											
												</div>
												<div class="ml-4">
													<h4 class="text-center">Thumbnail Image</h4>
											
													<div class="columns">
														<div class="circle">
															<img class="profile-pic" 
															src="{{
																crystalDetails
																? base + '/'+ crystalDetails.thumbnail_image
																: 'assets/img/user.png'
															}}">
														</div>
														<div class="p-image">
															<label for="fileuploads">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploads" class="file-upload" (change)="onImageChange($event)" type="file"
																accept="image/*">
														</div>
													</div>
											
												</div> -->
												<div class="">
													<h4 class="text-center">Raw Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="rawImageUrl?rawImageUrl:'assets/img/user.png'" class="w-100 h-100" />
                                                            </div>
															<!-- <img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}"> -->
														</div>
														<div class="p-image">
															<label for="fileuploadss">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploadss" class="file-upload"
																(change)="onImageChangeRaw($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
												<div class="ml-4">
													<h4 class="text-center">Raw Thumbnail Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="raw_thumbnail_imageUrl?raw_thumbnail_imageUrl:'assets/img/user.png'" class="w-100 h-100" />
                                                            </div>
															<!-- <img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}"> -->
														</div>
														<div class="p-image">
															<label for="fileuploads2">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploads2" class="file-upload"
																(change)="onImageChangeraw_thumbnail($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
												<div class="ml-4">
													<h4 class="text-center">Polished Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="polishedImageUrl?polishedImageUrl:'assets/img/user.png'" class="w-100 h-100" />
                                                            </div>
															<!-- <img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}"> -->
														</div>
														<div class="p-image">
															<label for="fileuploads3">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploads3" class="file-upload"
																(change)="onImageChangepolishedImage($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
												<div class="ml-4">
													<h4 class="text-center">Polished Thumbnail Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="polished_thumbnail_imageUrl?polished_thumbnail_imageUrl:'assets/img/user.png'" class="w-100 h-100" />
                                                            </div>
															<!-- <img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}"> -->
														</div>
														<div class="p-image">
															<label for="fileuploads4">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploads4" class="file-upload"
																(change)="onImageChangepolished_thumbnail_image($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
											</div>
											<div class="row m-0">
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Crystal Name</label> 
														<input 
														formControlName="crystalName"
														type="text"
														class="form-control" 
														placeholder="Enter Crystal Name"
														>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Known As</label>
														<input 
														formControlName="knownAs"
														type="text" 
														class="form-control" 
														placeholder="Known As"
														>
													</div>
												</div>
	  
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Known For</label> 
														<input 
														formControlName="knownFor"
														type="text"
														class="form-control" 
														placeholder="Known For"
														 >
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Physical</label>
													<button class="button_add_upper" type="button"
														(click)="addPhysical('')">
														<i class="fa fa-plus"></i>
													</button>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of physicalArray; let i=index;trackBy:trackByFn" >
															
															<div class="input-group">
																
																<input 
																	[(ngModel)]="physicalArray[i]" [ngModelOptions]="{standalone: true}"
																	type="text" 
																	class="form-control" 
																	placeholder="Enter Physical"
																>
																<button type="button"
																	(click)="removeButton('physicalArray',i)">
																	<i class="fa fa-minus"></i>
																</button>
															</div>
														</div>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Description</label>
														<input 
														formControlName="description"
														type="text" 
														class="form-control" 
														placeholder="Enter Description"
														>
													</div>
												</div>

												<!-- <div class=" col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Description</label> <editor
														formControlName="description"
														[init]="{
															height: 500,
															menubar: false,
															plugins: [
															'advlist autolink lists link image charmap print preview anchor',
															'searchreplace visualblocks code fullscreen',
															'insertdatetime media table paste code help wordcount'
															],
															toolbar:
															'undo redo | formatselect | bold italic backcolor | \
															alignleft aligncenter alignright alignjustify | \
															bullist numlist outdent indent | removeformat | help'
														}"
														></editor>
													</div>
												</div> -->
	  
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Primary Chakra</label>
														<button class="button_add_upper" type="button"
															(click)="addPrimaryChakra('')">
															<i class="fa fa-plus"></i>
														</button>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of primaryChakraArray; let i=index;trackBy:trackByFn" >
															<div class="input-group">
																<input 
																[(ngModel)]="primaryChakraArray[i]" [ngModelOptions]="{standalone: true}"
																	type="text" 
																	class="form-control" 
																	placeholder="Enter Primary Chakra"
																>
																<button type="button"
																	(click)="removeButton('primaryChakraArray',i)">
																	<i class="fa fa-minus"></i>
																</button>
															</div>
														</div>
													</div>
												</div>
	  
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Secondary Chakra</label>
													<button class="button_add_upper" type="button"
														(click)="addSecondaryChakra('')">
														<i class="fa fa-plus"></i>
													</button>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of secondaryChakraArray; let i=index;trackBy:trackByFn" >
															<div class="input-group">
																<input 
																[(ngModel)]="secondaryChakraArray[i]" [ngModelOptions]="{standalone: true}"
																	type="text" 
																	class="form-control" 
																	placeholder="Enter Secondary Chakra"
																>
																<button type="button"
																	(click)="removeButton('secondaryChakraArray',i)">
																	<i class="fa fa-minus"></i>
																</button>
															</div>
														</div>
													</div>
												</div>
												
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Spiritual</label>
													<button class="button_add_upper" type="button"
														(click)="addSpiritual('')">
														<i class="fa fa-plus"></i>
													</button>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of spiritualArray; let i=index;trackBy:trackByFn" >
															<div class="input-group">
																<input 
																[(ngModel)]="spiritualArray[i]" [ngModelOptions]="{standalone: true}"
																	type="text" 
																	class="form-control" 
																	placeholder="Enter Spiritual"
																>
																<button type="button"
																	(click)="removeButton('spiritualArray',i)">
																	<i class="fa fa-minus"></i>
																</button>
															</div>
														</div>
													</div>
												</div>

												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Color</label>
													<button class="button_add_upper" type="button"
														(click)="addColor('')">
														<i class="fa fa-plus"></i>
													</button>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of colorArray; let i=index;trackBy:trackByFn" >
															
															<div class="input-group">
																<input 
																[(ngModel)]="colorArray[i]" [ngModelOptions]="{standalone: true}"
																	type="text" 
																	class="form-control" 
																	placeholder="Enter Color"
																>
																<button type="button"
																	(click)="removeButton('colorArray',i)">
																	<i class="fa fa-minus"></i>
																</button>
															</div>
															
														</div>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Rarity</label> 
														<input 
														formControlName="rarity"
														type="text"
														class="form-control" 
														placeholder="Enter Rarity">
													</div>
												</div>
											  <div class="col-lg-6 col-md-6 col-12">
												  <div class="form-group custom-mt-form-group">
													  <label class="control-label">Chemical Composition</label> 
													  <input 
													  formControlName="chemicalComposition"
													  type="text"
													  class="form-control" 
													  placeholder="Chemical Composition">
												  </div>
											  </div>
											  <div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Mineral Class</label>
													<button class="button_add_upper" type="button"
														(click)="addMineralClass('')">
														<i class="fa fa-plus"></i>
													</button>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of mineralClassArray; let i=index;trackBy:trackByFn" >
															
															<div class="input-group">
																<input 
																[(ngModel)]="mineralClassArray[i]" [ngModelOptions]="{standalone: true}"
																	type="text" 
																	class="form-control" 
																	placeholder="Enter Mineral Class"
																>
																<button type="button"
																	(click)="removeButton('mineralClassArray',i)">
																	<i class="fa fa-minus"></i>
																</button>
															</div>
														</div>
													</div>
												</div>

											  <div class="col-lg-6 col-md-6 col-12">
												  <div class="form-group custom-mt-form-group">
													  <label class="control-label">Crystal System</label> 
													  <input 
													  formControlName="crystalSystem"
													  type="text"
													  class="form-control" 
													  placeholder="Crystal System">
												  </div>
											  </div>
											  <div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Astrological Sign</label>
													<button class="button_add_upper" type="button"
														(click)="addAstrologicalSign('')">
														<i class="fa fa-plus"></i>
													</button>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of astrologicalSignArray; let i=index;trackBy:trackByFn" >
														
														<div class="input-group">
															<input 
															[(ngModel)]="astrologicalSignArray[i]" [ngModelOptions]="{standalone: true}"
																type="text" 
																class="form-control" 
																placeholder="Astrological Sign"
															>
															<button type="button"
																(click)="removeButton('astrologicalSignArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</div>
													</div>
												</div>
											</div>

											  <div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Pronunciation</label> 
													<input 
													formControlName="pronunciation"
													type="text"
													class="form-control" 
													placeholder="Pronunciation">
												</div>
											 </div>
											  <div class="col-lg-6 col-md-6 col-12">
												  <div class="form-group custom-mt-form-group">
													  <label class="control-label">Ethical Sourced</label> 
													  <input 
													  formControlName="ethicalSourced"
													  type="text"
													  class="form-control" 
													  placeholder="Ethical Sourced">
												  </div>
											  </div>

											  <div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Hardness</label> 
													<input 
													formControlName="hardness"
													type="text"
													class="form-control" 
													placeholder="Hardness">
												</div>
											</div>
											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Location</label>
													<button class="button_add_upper" type="button"
														(click)="addLocation('')">
														<i class="fa fa-plus"></i>
													</button>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of locationArray; let i=index;trackBy:trackByFn" >
														
														<div class="input-group">
															<input 
															[(ngModel)]="locationArray[i]" [ngModelOptions]="{standalone: true}"
																type="text" 
																class="form-control" 
																placeholder="Location"
															>
															<button type="button"
																(click)="removeButton('locationArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</div>
													</div>
												</div>
											</div>

											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Affirmation</label> 
													<input 
													formControlName="affirmation"
													type="text"
													class="form-control" 
													placeholder="Affirmation">
												</div>
											</div>
											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Emotional</label>
												<button class="button_add_upper" type="button"
													(click)="addEmotional('')">
													<i class="fa fa-plus"></i>
												</button>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of emotionalArray; let i=index;trackBy:trackByFn" >
														
														<div class="input-group">
															<input 
															[(ngModel)]="emotionalArray[i]" [ngModelOptions]="{standalone: true}"
																type="text" 
																class="form-control" 
																placeholder="Emotional"
															>
															<button type="button"
																(click)="removeButton('emotionalArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</div>
													</div>
												</div>
											</div>
											
											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Alternate Stone Name</label>
												<button class="button_add_upper" type="button"
													(click)="addAlternateStoneName('')">
													<i class="fa fa-plus"></i>
												</button>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of alternateStoneNameArray; let i=index;trackBy:trackByFn" >
														
														<div class="input-group">
															<input 
															[(ngModel)]="alternateStoneNameArray[i]" [ngModelOptions]="{standalone: true}"
																type="text" 
																class="form-control" 
																placeholder="Alternate Stone Name"
															>
															<button type="button"
																(click)="removeButton('alternateStoneNameArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</div>
													</div>
												</div>
											</div>

											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Numerical Vibration</label>
												<button class="button_add_upper" type="button"
													(click)="addNumericalVibration('')">
													<i class="fa fa-plus"></i>
												</button>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of numericalVibrationArray; let i=index;trackBy:trackByFn" >
														<div class="input-group">
															<input 
															[(ngModel)]="numericalVibrationArray[i]" [ngModelOptions]="{standalone: true}"
																type="text" 
																class="form-control" 
																placeholder="Numerical Vibration"
															>
															<button type="button"
																(click)="removeButton('numericalVibrationArray',i)">
																<i class="fa fa-minus"></i>
															</button>
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Brain</label> 
													
													<select class="form-control" formControlName="brain">
														<option value="0">0</option>
														<option value="1">1</option>

													</select>
												</div>
											</div>


											<div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Created At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="createdAt"
                                                    placeholder="Created At">
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Updated At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="updatedAt"
                                                    placeholder="Updated At">
                                                </div>
                                                
                                            </div>
											
											</div>
											<div class="col-lg-12 col-md-12 col-sm-12 col-12">
												<div class="form-group custom-mt-form-group">
													<button class="btn btn-success mr-2" type="submit">Submit</button>
												</div>
											</div>
										</form>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	</div>