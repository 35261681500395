/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from '../app/components/login/login.component';
import { ForgotPasswordComponent } from '../app/components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '../app/components/change-password/change-password.component';
import { AuthGuard } from  './auth/auth.guard';
import { CsvreadComponent } from './csvread/csvread.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'changePassword/:token', component: ChangePasswordComponent },
  { path: 'usercsv', component: CsvreadComponent },
  { path: 'admin',
  loadChildren: () =>
  import("../app/components/main/main.module").then((m) => m.MainModule),
  canActivate: [AuthGuard]
},
{
  path: "**",redirectTo: 'login' 
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
