import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { ApiUrl } from "../../../../utils/config";//changed from src/utils
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators,FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.css']
})
export class CompanyTableComponent implements OnInit {

  storeList: any;
  currentPages = 1;
  updateUser !: FormGroup;
  postsPerPage = 10;
  file !: File;
  submitted = false;
  loading = false;
  filesToUpload: any;
  idToShow: any
  count = 0;
  page = 1;
  tableSize = 10;
  storeDetails: any;
  imgUrl !: String
  imageToUpload: any;
  base: any = ApiUrl;
  latitude:any
  longitude:any
  address:any
  image:any
  selected_image:any
    thumbnail_image:any
    imageUrl= "/assets/img/user.png";
    thumbnail_imageUrl= "/assets/img/user.png";

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private notify: NotificationsService
  ) { }


  ngOnInit(): void {
    this.getStoresList(1);
    this.updatefromConntorl()
    // this.autocompleteSearch()

  }
  
  autocompleteSearch() {
    // var input = document.getElementById('address');
    // var autocomplete = new google.maps.places.Autocomplete(input);
    // google.maps.event.trigger(autocomplete, 'resize');
    
    // autocomplete.addListener("place_changed", ()=>{

        
    //     let place = autocomplete.getPlace();
    //     if(place.geometry === undefined || place.geometry === null) {
    //        return;
    //     }
    //     this.latitude = place.geometry.location.lat();
    //     this.longitude = place.geometry.location.lng();
    //     this.address = place.formatted_address;
        
    //     this.updateUser.controls['address'].setValue(this.address);
    //     this.updateUser.controls['latitude'].setValue(this.latitude);
    //     this.updateUser.controls['longitude'].setValue(this.longitude);
        
    // })
  }
    onImageChangeRaw= (fileInput: any,type:any) => {
        if(type=='image'){
            this.image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.image[0]);
            reader.onload = (e: any) => {
                this.imageUrl = e.target.result;
            };
        }
        if(type=='thumbnail_image'){
            this.thumbnail_image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.thumbnail_image[0]);
            reader.onload = (e: any) => {
                this.thumbnail_imageUrl = e.target.result;
            };
        }
    
    }
  getStoresList = (page: Number) => {
    page = page ? page : 1
    this.userService.getStoresLists(page).toPromise().then(response => {
      if (response.success) {
        this.storeList = response.data.result;
        this.count = response.data.count;
      }
    });
  }

  getStoreDetail = () => {

    this.imageUrl ="/assets/img/user.png";
    this.thumbnail_imageUrl ="/assets/img/user.png";
    if(this.storeDetails.storeImage!==undefined && this.storeDetails.storeImage!==''){
        this.imageUrl = this.base + '/'+ this.storeDetails.storeImage
    }

    if(this.storeDetails.thumbnail_image!==undefined && this.storeDetails.thumbnail_image!==''){
        this.thumbnail_imageUrl = this.base + '/'+ this.storeDetails.thumbnail_image
    }
    

    this.updateUser = this.formbuilder.group({
      category: [this.storeDetails.category, Validators.required],
      shopName: [this.storeDetails.shopName, Validators.required],
      website: [this.storeDetails.website, Validators.required],
      address: [this.storeDetails.address, Validators.required],
      state: [this.storeDetails.state, Validators.required],
      city: [this.storeDetails.city, Validators.required],
      zip: [this.storeDetails.zip, Validators.required],
      country: [this.storeDetails.country, Validators.required],
      mobileNumber: [this.storeDetails.mobileNumber, Validators.required],
      email: [this.storeDetails.email, Validators.required],
      speciality: [this.storeDetails.speciality, Validators.required],
      ethicalRanking: [this.storeDetails.ethicalRanking, Validators.required],
      qualityRanking: [this.storeDetails.qualityRanking, Validators.required],
      experienceRanking: [this.storeDetails.experienceRanking, Validators.required],
      description: [this.storeDetails.description, Validators.required],
      briefDescription: [this.storeDetails.briefDescription, Validators.required],
      firstName: [this.storeDetails.briefDescription, Validators.required],
      lastName: [this.storeDetails.briefDescription, Validators.required],
      title: [this.storeDetails.briefDescription, Validators.required],
    //   longitude: [this.storeDetails.location?this.storeDetails.location.coordinates[0]:"", Validators.required],
    //   latitude: [this.storeDetails.location?this.storeDetails.location.coordinates[1]:"", Validators.required],
      EW_Partner: [this.storeDetails.EW_Partner],
      investigated: [this.storeDetails.investigated],
      active: [this.storeDetails.active],
      onlineStore: [this.storeDetails.onlineStore],
      ecommerceLink: [this.storeDetails.ecommerceLink],
      facebook: [this.storeDetails.facebook],
      instagram: [this.storeDetails.instagram],
      twitter: [this.storeDetails.twitter],
    })
  }

  onFileChange = (fileInput: any) => {
    this.imageToUpload = <Array<File>>fileInput.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
      this.imgUrl = e.target.result;
    };
  };

  updatefromConntorl = () => {
    this.updateUser = this.formbuilder.group({
      category: ['', Validators.required],
      shopName: ['', Validators.required],
      website: ['', Validators.required],
      address: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', Validators.required],
      speciality: ['', Validators.required],
      ethicalRanking: ['', Validators.required],
      qualityRanking: ['', Validators.required],
      experienceRanking: ['', Validators.required],
      description: ['', Validators.required],
      briefDescription: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      title: ['', Validators.required],
      EW_Partner: [false],
      investigated: [false],
      active: [false],
      onlineStore: [false],
      ecommerceLink: [''],
      facebook: [''],
      instagram: [''],
      twitter: [''],
    })
  }

  deleteStore = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteStore(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getStoresList(1)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getStoresList(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getStoresList(1);
  }

  viewStore = (data: any) => {
    this.storeDetails = data;
    
    this.idToShow = data._id;
    this.getStoreDetail();
    this.autocompleteSearch()
  }

  updateStore = () => {
    this.submitted = true;
    if (this.updateUser.value['category']=='') {
      this.notify.showError('Please select category')
      return;
    }
    if (this.updateUser.value['shopName']=='') {
      this.notify.showError('Please enter shop name')
      return;
    }
    if (this.updateUser.invalid) {

    
    }
    
    const formData = new FormData();
   
    if (this.image && this.image[0]) {
        formData.append('storeImage', this.image[0]);
    }
    if (this.thumbnail_image && this.thumbnail_image[0]) {
        formData.append('thumbnail_image', this.thumbnail_image[0]);
    }
    for (let key in this.updateUser.value) {
      formData.append(key, this.updateUser.value[key]);
    }
    this.file = this.filesToUpload;
    this.loading = true;
    this.userService
      .updateStore(formData, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Store Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.getStoresList(this.page);
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }
}
