<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
	<div class="mt-8 row">
		<div class="intro-y h-10 col-md-12 mb-4">
			<div class="card">
				<div class="card-content">
					<div class="row">
						<div class="col-lg-12 ">
							<h3 class="text-lg mb-0 mt-0">
								{{currentTable.table}}<span class="float-right">
                                    </span>
							</h3>
						</div>
					</div>
					<div class="table-responsive">
						<table width="100%" class="table table-bordered table-striped table-crystal">
							<thead>
								<th>Sr No.</th>
								<th>Field Name</th>
								
							</thead>
							<tbody>
								<tr *ngFor="let item of currentTable.data; index as i">

									<td>{{  i+1 }}</td> 
									<td> {{item}}</td>
									
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="d-flex justify-content-center">
					
				</div>
			</div>
		</div>
	</div>
	