import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { ApiUrl } from "../../../../utils/config";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-subscription',
  templateUrl: './payment-subscription-dummy.component.html',
  styleUrls: ['./payment-subscription-dummy.component.css']
})
export class PaymentSubscriptionComponentDummy implements OnInit {

 
  list: any;
  currentPages = 1;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;
  rawImageUrl = "/assets/img/user.png";
  one= 1
  zero = 0
  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getAllPayment(1);
  }

  getAllPayment = (page: Number) => {
    page = page ? page : this.one
    this.userService.paymentManagement(this.one).subscribe(data => {
      this.count = data.data.length
      this.list = data.data;
    })
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getAllPayment(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = this.one;
    this.getAllPayment(this.page);
  }

  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deletePaymentManagement(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getAllPayment(this.one);
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

}
