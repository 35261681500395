import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUrl } from "../../../../utils/config";

@Component({
  selector: 'app-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.css']
})
export class AddPageComponent implements OnInit {
  addSubscriptionForm !: FormGroup;
    submitted = false;
    loading = false;
    base: any = ApiUrl;

    constructor(private userService: UserService, private formbuilder:FormBuilder, private router:Router,private activateRoute: ActivatedRoute) { }


  ngOnInit(): void {
      this.addSubscriptionControler()
  }
  get f() {
      return this.addSubscriptionForm.controls;
  }
    addSubscriptionControler = () => {
        this.addSubscriptionForm = this.formbuilder.group({
            page:['',[Validators.required,Validators.pattern(/^[0-9]+$/)]]
         
        })
    }

    addSubscription = () => {
      this.submitted = true;
      
      if (this.addSubscriptionForm.invalid) {
          return;
      }
      this.addSubscriptionForm.value;
      this.loading = true;
      this.userService.addPage(this.addSubscriptionForm.value).toPromise()
      .then((resp) => {
          if (resp.success) {
              Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Page Added Successfully',
                  showConfirmButton: false,
                  timer: 1500
              })
              this.router.navigate(["/admin/pageTable"]);
          } else {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
              })
          }
      })
      .catch((err) => {
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
          })
      });
  }

}
