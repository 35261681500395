import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { ApiUrl } from "../../../../utils/config";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-credit-management',
  templateUrl: './credit-management.component.html',
  styleUrls: ['./credit-management.component.css']
})
export class CreditManagementComponent implements OnInit {

  reportedPostList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;  
  reportedPostDetails: any;
  base: any = ApiUrl;
  search: any = '';
  public userArray: Usercsv[] = [];

  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getallReportedPost(1);
  }
  onSearch(e:any){
    this.getallReportedPost(1);
 }

// importCredit

   csvChanged = (fileInput: any) => {
    this.userArray =[]
		var csvFile = fileInput.target.files;
		console.log("FILE DATA",csvFile);
		
		var file = csvFile[0];
		
		let reader = new FileReader();
		reader.readAsText(file);
		reader.onload = (e: any) => {
			var data = e.target.result;
			
			let csvToRowArray = data.split("\n");
            for (let index = 1; index < csvToRowArray.length - 1; index++) {
              let row = csvToRowArray[index].split(",");
            
              this.userArray.push(new Usercsv(row[0].trim(), row[1].trim(), row[2].trim(), row[3].trim()));
            }

            console.log("data",this.userArray);

			   this.userService.importCredit(this.userArray).toPromise()
		      .then(response => {
		        if (response.success) {
		          
			 Swal.fire({
			                  position: 'center',
			                  icon: 'success',
			                  title: 'Credit Import Successfully',
			                  showConfirmButton: false,
			                  timer: 1500
			              })
		        }
		      });
		
		};
	};

  getallReportedPost = (page: any) => {
    this.currentPages = page;
    this.page=page;
    this.userService.getCreditsSearch(page,this.search).toPromise()
      .then(response => {
        if (response.success) {
          this.reportedPostList = response.data.result;
          this.count = response.data.count;
        }
      });
  }
  
  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getallReportedPost(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getallReportedPost(this.page);
  }
  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteCredits(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getallReportedPost(1)
          }
        })
      }
    }).catch(err => {
        Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

}

export class Usercsv{
  email: String;
  number_of_weeks: String;
  duration_type: String;
  subscriptionid: String;

  constructor(email: String, number_of_weeks: String, duration_type: String, subscriptionid: String){
    this.email = email;
    this.number_of_weeks = number_of_weeks;
    this.duration_type = duration_type;
    this.subscriptionid = subscriptionid;
  }
}
