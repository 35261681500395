
export const Tables = [
    {
        table:'my crystal table',
        handle:'my-crystal-table',
        data:[
            'crystalName',
            'identification',
            'name',
            'scan_img',
            'isFavourite',
            'isNote',
            'note',
            'finish',
            'crystalId',
            'added_by',
            'collections',
            'crystalImg',
            'description',
            'location',
        ]
    },
    {
        table:'profile table',
        handle:'profile-table',
        data:[
            'firstName',
            'lastName',
            'displayName',
            'token',
            'guId',
            'deviceId',
            'deviceType',
            'email',
            'password',
            'role',
            'status',
            'profileImg',
            'dob',
            'tob',
            'country',
            'state',
            'city',
            'isTrial',
            'distribution',
            'trialEndDate',
            'completeProfile',
            'isVerified',
            'total_crystal_count',
            'used_count',
            'resetToken',
            'fcmToken',
            'toggleStatus',
        ]
    },
    {
        table:'content criteria',
        handle:'content-criteria',
        data:[
            'name',
            'query',
        ]
    },
    {
        table:'credit table',
        handle:'credit-table',
        data:[
            'creditId',
            'creditTo',
            'durationType',
            'quantity',
        ]
    },
    {
        table:'auto id history',
        handle:'auto-id-history',
        data:[
            'crystalName',
            'confidence',
            'scan_img',
            'selected',
            'added_by',
        ]
    },
    {
        table:'crystal library',
        handle:'crystal-library',
        data:[
            'crystalName',
            'crystalImg',
            'raw_thumbnail_image',
            'rawImage',
            'polished_thumbnail_image',
            'polishedImage',
            'description',
            'knownAs',
            'knownFor',
            'physical',
            'primaryChakra',
            'secondaryChakra',
            'spiritual',
            'rarity',
            'color',
            'chemicalComposition',
            'mineralClass',
            'crystalSystem',
            'astrologicalSign',
            'ethicalSourced',
            'hardness',
            'location',
            'numericalVibration',
            'pronunciation',
            'affirmation',
            'viewCrystal',
            'emotional',
            'alternateStoneName',
        ]
    },
    {
        table:'favourite',
        handle:'favourite',
        data:[
            'crystal',
            'user',
        ]
    },
    {
        table:'notes',
        handle:'notes',
        data:[
            'crystal',
            'notes',
            'user',
        ]
    },
    {
        table:'page table',
        handle:'page-table',
        data:[
            'page',
        ]
    },
    {
        table:'community wall',
        handle:'community-wall',
        data:[
            'postImg',
            'text',
            'hiddenByUsers',
            'postCount',
            'isProfileImg',
            'created_by',
            'likes',
            'postStatus',
        ]
    },
    {
        table:'post report',
        handle:'post-report',
        data:[
            'reportReason',
            'reported_by',
            'postId',
        ]
    },
    {
        table:'rating',
        handle:'rating',
        data:[
            'rating',
            'rate_by',
        ]
    },
    {
        table:'constant config',
        handle:'constant-config',
        data:[
            'status',
            'webAdmin',
            'viewOrder',
            'system',
            'default',
            'UX',
            'local',
            'heading',
            'type',
            'location',
            'title',
            'description',
            'image',
            'subHeading',
        ]
    },
    {
        table:'company table',
        handle:'company-table',
        data:[
            'shopName',
            'firstName',
            'lastName',
            'title',
            'description',
            'briefDescription',
            'address',
            'speciality',
            'city',
            'state',
            'zip',
            'country',
            'website',
            'mobileNumber',
            'email',
            'category',
            'ethicalRanking',
            'qualityRanking',
            'experienceRanking',
            'onlineStore',
            'EW_Partner',
            'investigated',
            'ecommerceLink',
            'storeImage',
            'thumbnail_image',
            'facebook',
            'instagram',
            'twitter',
            'active',
        ]
    },
    {
        table:'payment subscription',
        handle:'payment-subscription',
        data:[
            'subscriptionId',
            'userId',
            'start_date',
            'end_date',
            'transactionId',
            'status',
            'amount',
            'planName',
            'quantity',
            'durationType',
            'paymentStatus',
        ]
    },
    {
        table:'subscription table',
        handle:'subscription-table',
        data:[
            'planName',
            'durationType',
            'quantity',
            'planCost',
            'description',
            'status',
        ]
    },
    {
        table:'update table',
        handle:'update-table',
        data:[
            'tableName',
            'descripton',
            // 'type',
        ]
    },
    {
        table:'content creation',
        handle:'content-creation',
        data:[
            'title',
            'description',
            'hiddenByUsers',
            'interestFlag',
            'pushNotification',
            'notificationTemplate',
            'notificationCriteria',
            'notificationListFlag',
            'grouped',
            'groupType',
            'canClose',
            'contentOrder',
            'image',
            'link',
            'distribution',
            'postDate',
            'endDate',
            'location',
            'notificationTitle',
            'notificationSubTitle',
            'color',
            'notificationLink',
            'notificationDateTime',
            'crystal',
            'store',
            'url',
        ]
    },{
        table:'content grouping',
        handle:'content-grouping',
        data:[
            'groupName',
            'type',
            'description',
            'groupOrder',
            'groupType',
            'groupMaximumCount',
            'verticalViewCount',
        ]
    }
]