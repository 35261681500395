<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    
                    <div class="row">
						<div class="col-lg-12 ">
							<h3 class="text-lg mb-0 mt-0">
								Credit Management
								
								<span class="float-right" title="" >
									<a class="btn btn-primary" href="assets/credit.csv">Download Credit Demo File </a>
								</span>
								<span class="float-right">
									<input class="btn btn-primary" type="file" #fileImportInput name="File Upload" (change)="csvChanged($event)" id="txtFileUpload">
								</span>
								
								<span class="float-right">
									<a class="btn btn-primary" [routerLink]="['/admin/addCredit']">Add New
										Credit</a>
								</span>
										
							</h3>
						</div>
					</div>
                    
                    <div class="row">
                        <div class="col-md-3 ">
                          
                        </div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right" style="margin-left: 10px;">
                            <span>
                              Showing 
                              <b>
                                <ng-container *ngIf="reportedPostList">
                                  {{ reportedPostList.length>0?(currentPages - 1) * postsPerPage+1:0}}
            
                                </ng-container>
                              </b>
                              -
                              <b>{{ ((currentPages - 1) * postsPerPage+1) + (reportedPostList?reportedPostList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">Credit Id</th>
                                    <th class="text-center whitespace-no-wrap">Duration Type</th>
                                    <th class="text-center whitespace-no-wrap">Subscription Id </th>
                                    <th class="text-center whitespace-no-wrap">Plan Name </th>
                                    <th class="text-center whitespace-no-wrap">Collection Quantity  </th>
                                    <th class="text-center whitespace-no-wrap">Scan Quantity  </th>
                                    
                                    <th class="text-center whitespace-no-wrap">Credit To</th>
                                    <th class="text-center whitespace-no-wrap">Number of Weeks</th>
                                    <th class="text-center whitespace-no-wrap">Consumed</th>
                                    <th class="text-center whitespace-no-wrap">Created At</th>
                                    <th class="text-center whitespace-no-wrap">Updated At</th>

                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let post of reportedPostList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{post.creditId}}
                                        
                                    </td>
                                    <td class="text-center">{{post.durationType}}</td>
                                    <td class="text-center">{{post.subscriptionId._id}}</td>
                                    <td class="text-center">{{post.subscriptionId.planName}}</td>
                                    <td class="text-center">{{post.subscriptionId.collectionQuantity}}</td>
                                    <td class="text-center">{{post.subscriptionId.scanQuantity}}</td>
                                 
                                    <td class="text-center" title="{{post.imported_email}}">
                                        <ng-container *ngIf="post.creditTo!==undefined && post.creditTo!==null && post.creditTo.email!==0 ; else elseBlock">
                                    
                                            <!-- {{post.creditTo.firstName?post.creditTo.firstName:''}} {{post.creditTo.lastName?post.creditTo.lastName:''}} -->
                                            <!-- <ng-container *ngIf="post.creditTo.email && post.creditTo.email!==0 "> -->
                                                {{post.creditTo.email}}
                                            <!-- </ng-container> -->
                                           
                                            <!-- <ng-container *ngIf="post.creditTo.guId">
                                                - {{post.creditTo.guId}}
                                            </ng-container> -->
                                        </ng-container>
                                        <ng-template #elseBlock >
                                            {{post.imported_email}}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">{{post.quantity}}</td>


                                    <td class="text-center">
                                       <!-- {{post.consumed}} -->
                                       <ng-container *ngIf="post.consumed == 1 ; else ElseBlock">
                                        True
                                       </ng-container>
                                       <ng-template #ElseBlock >
                                        False
                                       </ng-template>
                                    </td>


                                    <td class="text-center">{{post.createdAt | date:'short'}}</td>
                                    <td class="text-center">{{post.updatedAt | date:'short'}}</td>

                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            
                                            <a class="flex items-center text-danger mr-3" data-toggle="modal"
                                                data-target="#viewUser" (click)="deleteSubscription(post._id)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-trash"></i>

                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewStore">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Update Table Info
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form class="custom-mt-form">
                                        <div class="row m-0">
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Table</label>
                                                    
                                                    <select class="form-control">
                                                        <option>Table Name</option>
                                                        
                                                    </select>
                                                    
                                                    
                                                </div>
                                            </div> 
                                            
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>