<!-- /*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/ -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <div class="row">
                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Ethical Sourcing
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="profile-sidebar">
                                <div class="card card-topline-aqua">
                                    <div class="card-body no-padding text-center">
                                        <div class="row">

                                            <div class="profile-userpic text-center w-100">
                                                <img src="{{
                                                    ethicalSourcingDetail
                                                      ? base + '/'+ ethicalSourcingDetail.image
                                                      : 'assets/img/user.png'
                                                  }}" class="w-100">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="profile-content">
                                <div class="row">
                                    <div class="tab-content col-md-12 mt-2">

                                        <div class="card">
                                            <div class="card-header">
                                                <h4>Ethical Sourcing</h4>
                                            </div>
                                            <div class="card-body">

                                                <ul class="list-group list-group-unbordered">
                                                    <li class="list-group-item">
                                                        <b>Title </b>
                                                        <div class="profile-desc-item float-right">
                                                            {{ethicalSourcingDetail ? ethicalSourcingDetail.title:""}}
                                                        </div>
                                                    </li>

                                                    <li class="list-group-item">
                                                        <b>Description </b>
                                                        <div class="profile-desc-item float-right" [innerHTML]="ethicalSourcingDetail?.description">
                                                        </div>
                                                    </li>

                                                    <li class="list-group-item">
                                                        <b>Heading</b>
                                                        <div class="profile-desc-item float-right">
                                                            {{ethicalSourcingDetail ? ethicalSourcingDetail.heading:""}}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right mb-4">
                                        <a href="javascript:void(0);" data-toggle="modal" data-target="#editUser"
                                            class="btn btn-success">Edit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="editUser" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Edit 
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="updateUser" (ngSubmit)="updateProfile()" class="custom-mt-form">
                                        <div class="columns">

                                            <div class="circle">
                                                <div class="pic-upload">
                                                    <img [src]="imgUrl" class="w-100 h-100" />
                                                </div>
                                           
                                                <img class="profile-pic" src="{{
                                                    ethicalSourcingDetail
                                                      ? base + '/'+ ethicalSourcingDetail.image
                                                      : 'assets/img/user.png'
                                                  }}">
                                            </div>
                                            <div class="p-image">
                                                <label for="fileupload">
                                                    <i class="fa fa-camera upload-button"></i>
                                                </label>
                                                <input id="fileupload" class="file-upload" type="file"
                                                    (change)="onFileChange($event)" accept="image/*">
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Title</label>
                                                    <input formControlName="title" type="text" class="form-control"
                                                        placeholder="Enter Title">

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Heading</label>
                                                    <input formControlName="heading" type="text" class="form-control"
                                                        placeholder="Enter Heading">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Location</label>
                                                    <select formControlName="location" class="form-control">
                                                        <option value="">Select</option>
                                                        <option *ngFor="let pages of page" value="{{pages.page}}">{{pages.page}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label>
                                                    <editor formControlName="description"></editor>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>