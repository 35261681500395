import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiUrl } from "../../../../utils/config";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-table',
  templateUrl: './update-table.component.html',
  styleUrls: ['./update-table.component.css']
})
export class UpdateTableComponent implements OnInit {

  reportedPostList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;
  subscriptionForm !: FormGroup;
  detail: any;
  loading = false;
  idToShow: any;
  submitted = false;

  constructor(
    private userService: UserService,
    private notify: NotificationsService,
    private formbuilder: FormBuilder,
    
  ) { }

  ngOnInit(): void {
    this.getAll(1);
    this.updateformControl()
  }

  getAll = (page: Number) => {
    page = page ? page : 1
    this.userService.getUpdateTable(page).toPromise()
      .then(response => {
        if (response.success) {
          this.reportedPostList = response.data;
          this.count = response.data.count;
        }
      });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getAll(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll(this.page);
  }

  view = (data: any) => {
    this.detail = data;
    this.getSubscriptionDetail()
  }

  getSubscriptionDetail = () => {
    this.subscriptionForm = this.formbuilder.group({
      tableName: [this.detail.tableName, Validators.required],
      descripton: [this.detail.descripton, Validators.required],
    })
  }

  updateformControl = () => {
    this.subscriptionForm = this.formbuilder.group({
      tableName: ['', Validators.required],
      descripton: ['', Validators.required],
      
    })
  }

  get f() {
    return this.subscriptionForm.controls;
  }

  update = () => {
    this.submitted = true;
    
    if (this.subscriptionForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .updateUpdateTable(this.subscriptionForm.value)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Update table Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.getAll(this.page);
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }
}
