import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../services/user.service";
import { ApiUrl } from "../../../utils/config";
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators,FormArray } from "@angular/forms";

@Component({
  selector: 'app-backup-data',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.css']
})
export class BackupDataComponent implements OnInit {
    crystalList:any;
    currentPages = 1;
    updateUser !: FormGroup;
    postsPerPage= 10;
    count= 0;
    page = 1;
    file !: File;
    submitted = false;
    loading = false;
    filesToUpload: any;
    idToShow: any
    tableSize = 10;
    crystalDetails:any;
    imgUrl !: String
    imageToUpload: any;
    thumbnailUrl!: String
    thumbnail: any;
    base: any = ApiUrl;
    secondaryChakraArray: any = [];
    primaryChakraArray: any = [];
    physicalArray: any = [];
    spiritualArray: any = [];
    colorArray: any = [];
    mineralClassArray: any = [];
    astrologicalSignArray: any = [];
    locationArray: any = [];
    numericalVibrationArray: any = [];
    alternateStoneNameArray: any = [];
    emotionalArray: any = [];
    search: any = '';

    rawImageUrl = "/assets/img/user.png";
    raw_thumbnail_imageUrl = "/assets/img/user.png";
    polishedImageUrl = "/assets/img/user.png";
    polished_thumbnail_imageUrl = "/assets/img/user.png";

    rawImage: any;
    raw_thumbnail_image: any;
    polishedImage: any;
    polished_thumbnail_image: any;
    selected_image:any;
 
    constructor(
        private userService: UserService,
        private formbuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.getCrystalList(1);
        this.updatefromControl()
    }
    
    removeButton(type:any, i:any){
        
        if(type=='physicalArray'){
            this.physicalArray.splice(i,1)
        }
        if(type=='primaryChakraArray'){
            this.primaryChakraArray.splice(i,1)
        }
        if(type=='alternateStoneNameArray'){
            this.alternateStoneNameArray.splice(i,1)
        }
        if(type=='emotionalArray'){
            this.emotionalArray.splice(i,1)
        }
        if(type=='numericalVibrationArray'){
            this.numericalVibrationArray.splice(i,1)
        }
        if(type=='locationArray'){
            this.locationArray.splice(i,1)
        }
        if(type=='astrologicalSignArray'){
            this.astrologicalSignArray.splice(i,1)
        }
        if(type=='mineralClassArray'){
            this.mineralClassArray.splice(i,1)
        }
        if(type=='colorArray'){
            this.colorArray.splice(i,1)
        }
        if(type=='spiritualArray'){
            this.spiritualArray.splice(i,1)
        }
        if(type=='secondaryChakraArray'){
            this.secondaryChakraArray.splice(i,1)
        }
    }
    
    onSearch(e:any){
        // e.target.value;
        this.search = e.target.value
        this.getCrystalList(1);
        
    }
    getCrystalList = (page:any)=>{
        // page = page?page:1

        this.currentPages = page?page:1;
        this.page = page?page:1;
        let searchinput = this.search
        var obj = {
            page:page?page:1,
            search:searchinput
        }
        this.userService.getCrystalLists(obj).toPromise().then(response=>{
            if(response.success){
                this.crystalList = [];
                this.count = 0;
            }
        });
    }
    onTableDataChange(event:any){
        this.page = event;
        this.currentPages = this.page;
        this.getCrystalList(this.page);
    } 
    onTableSizeChange(event:any): void {
        this.tableSize = event.target.value;
        this.page = 1;
        this.getCrystalList(1);
    } 
    onImageChangeRaw = (fileInput: any) => {

        this.rawImage = <Array<File>>fileInput.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(this.rawImage[0]);
        reader.onload = (e: any) => {
            this.rawImageUrl = e.target.result;
        };
    };
    onImageChangeraw_thumbnail = (fileInput: any) => {
        
        this.raw_thumbnail_image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.raw_thumbnail_image[0]);
            reader.onload = (e: any) => {
                this.raw_thumbnail_imageUrl = e.target.result;
        };
    };
    onImageChangepolishedImage = (fileInput: any) => {

        this.polishedImage = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.polishedImage[0]);
            reader.onload = (e: any) => {
                this.polishedImageUrl = e.target.result;
        };
    };
    onImageChangepolished_thumbnail_image = (fileInput: any) => {

        this.polished_thumbnail_image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.polished_thumbnail_image[0]);
            reader.onload = (e: any) => {
                this.polished_thumbnail_imageUrl = e.target.result;
            };
    };

    viewCrystal = (data: any) => {
        
        this.crystalDetails = data;
        this.idToShow = data._id;
        this.physicalArray = data.physical
        this.secondaryChakraArray = data.secondaryChakra
        this.primaryChakraArray = data.primaryChakra
        this.spiritualArray = data.spiritual
        this.mineralClassArray = data.mineralClass	
        this.colorArray = data.color
        this.astrologicalSignArray = data.astrologicalSign
        this.locationArray = data.location
        this.numericalVibrationArray = data.numericalVibration
        this.alternateStoneNameArray = data.alternateStoneName
        this.emotionalArray = data.emotional
        this.getStoreDetail();
    }

    onFileChange = (fileInput: any) => {
        this.imageToUpload = <Array<File>>fileInput.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(this.imageToUpload[0]);
        reader.onload = (e: any) => {
            this.imgUrl = e.target.result;
        };
    };  

    updatefromControl = () => {
        this.updateUser = this.formbuilder.group({
            crystalName: ['', Validators.required],
            knownAs: [''],
            knownFor: [''],
            description: [''],
            rarity: [''],
            thumbnail_image: [''],
            image: [''],
            chemicalComposition: [''],
            crystalSystem: [''],
            astrologicalSign: [],
            ethicalSourced: [''],
            hardness: [''],
            pronunciation: [''],
            affirmation: [''],
            viewCrystal: ['', Validators.required],
            brain:['0'],
            createdAt: [''],
            updatedAt:['']
        })
    }

    addSecondaryChakra(newSecondaryChakra: string) {
        
            this.secondaryChakraArray.push(newSecondaryChakra);
      
    }

    addPrimaryChakra(dataToSet: string) {
    
            this.primaryChakraArray.push(dataToSet);
        
    }

    addPhysical(dataToSet: string) {
        
            this.physicalArray.push(dataToSet);
        
    }

    addColor(dataToSet: string) {
       
            this.colorArray.push(dataToSet);
      
    }

    addMineralClass(dataToSet: string) {
  
           
            this.mineralClassArray.push(dataToSet);
     
    }

    addAstrologicalSign(dataToSet: string) {
       
            this.astrologicalSignArray.push(dataToSet);
       
    }

    addSpiritual(dataToSet: string) {
       
            
            this.spiritualArray.push(dataToSet);
        
    }

    addLocation(dataToSet: string) {
       
            this.locationArray.push(dataToSet);
       
    }

    addNumericalVibration(dataToSet: string) {

            this.numericalVibrationArray.push(dataToSet);

    }

    addAlternateStoneName(dataToSet: string) {

            this.alternateStoneNameArray.push(dataToSet);
       
    }

    addEmotional(dataToSet: string) {
        
            this.emotionalArray.push(dataToSet);
        
    }

    getStoreDetail = () => {

        if(this.crystalDetails.rawImage!==undefined && this.crystalDetails.rawImage!==''){
            this.rawImageUrl = this.base + '/'+ this.crystalDetails.rawImage
        }
        if(this.crystalDetails.raw_thumbnail_image!==undefined && this.crystalDetails.raw_thumbnail_image!==''){
            this.raw_thumbnail_imageUrl = this.base + '/'+ this.crystalDetails.raw_thumbnail_image
        }
        if(this.crystalDetails.polishedImage!==undefined && this.crystalDetails.polishedImage!==''){
            this.polishedImageUrl = this.base + '/'+ this.crystalDetails.polishedImage
        }
        if(this.crystalDetails.polished_thumbnail_image!==undefined && this.crystalDetails.polished_thumbnail_image!==''){
            this.polished_thumbnail_imageUrl = this.base + '/'+ this.crystalDetails.polished_thumbnail_image
        }
        

        this.updateUser = this.formbuilder.group({
            crystalName: [this.crystalDetails.crystalName, Validators.required],
            knownAs: [this.crystalDetails.knownAs, Validators.required],
            knownFor: [this.crystalDetails.knownFor, Validators.required],
            
            description: [this.crystalDetails.description, Validators.required],
            
            rarity: [this.crystalDetails.rarity, Validators.required],
            chemicalComposition: [this.crystalDetails.chemicalComposition, Validators.required],
            
            crystalSystem: [this.crystalDetails.crystalSystem, Validators.required],
            
            ethicalSourced: [this.crystalDetails.ethicalSourced, Validators.required],
            hardness: [this.crystalDetails.hardness, Validators.required],
            
            pronunciation: [this.crystalDetails.pronunciation, Validators.required],
            affirmation: [this.crystalDetails.affirmation, Validators.required],
            brain: [ this.crystalDetails.brain ],
            createdAt: [this.crystalDetails.createdAt],
            updatedAt: [this.crystalDetails.updatedAt]
        })
    }

    trackByFn(index: any, item: any) {
        return index;
     }
    updateCrystal = () => {
        this.submitted = true;
        const formData = new FormData();
    
        if (this.rawImage && this.rawImage[0]) {
            formData.append('rawImage', this.rawImage[0]);
        }
        if (this.raw_thumbnail_image && this.raw_thumbnail_image[0]) {
            formData.append('raw_thumbnail_image', this.raw_thumbnail_image[0]);
        }
        if (this.polishedImage && this.polishedImage[0]) {
            formData.append('polishedImage', this.polishedImage[0]);
        }
        if (this.polished_thumbnail_image && this.polished_thumbnail_image[0]) {
            formData.append('polished_thumbnail_image', this.polished_thumbnail_image[0]);
        }
        for (let key in this.updateUser.value) {
            formData.append(key, this.updateUser.value[key]);
        }
        
        // return false
        formData.append('secondaryChakra', this.secondaryChakraArray);
        formData.append('primaryChakra', this.primaryChakraArray);
        formData.append('physical', this.physicalArray);
        formData.append('spiritual', this.spiritualArray);
        formData.append('color', this.colorArray);
        formData.append('mineralClass', this.mineralClassArray);
        formData.append('astrologicalSign', this.astrologicalSignArray);
        formData.append('location', this.locationArray);
        formData.append('numericalVibration', this.numericalVibrationArray);
        formData.append('alternateStoneName', this.alternateStoneNameArray);
        formData.append('emotional', this.emotionalArray);
        this.file = this.filesToUpload;
        this.loading = true;
        this.userService
            .updateCrystal(formData, this.idToShow)
            .toPromise()
            .then((resp) => {
                if (resp.success) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Crystal Updated Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.getCrystalList(this.page);
                    let modalToClose = <HTMLElement>document.querySelector('#viewStore .modal-header.p-0 .close');
                    modalToClose.click();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            });
    }

    onImageChange = (fileInput: any) => {
        this.thumbnail = <Array<File>>fileInput.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(this.thumbnail[0]);
        reader.onload = (e: any) => {
            this.thumbnailUrl = e.target.result;
        };
    };


    deleteCrystal = (id: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.userService.deleteCrystal(id).toPromise().then(response => {
                    if (response.success) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        this.getCrystalList(1)
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
              })
        });
    }
}
