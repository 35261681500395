<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->


<div class="wrapper wrapper-full-page">
  <div class="full-page login-page" data-color="blue">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-12 mx-auto">
            <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
              <div class="card card-login">
                <a class="navbar-brand text-center mx-auto w-100" href="#">
                  <img src="assets/img/logo.png??thumbnail==227" class="img-fluid mx-auto" />
                </a>
                <div class="card-header text-center borderbottom0 pt-0">
                  <h3 class="card-title">Log In</h3>
                </div>
                <div class="card-content">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="material-icons mt-3">email</i>
                    </span>
                    <div class="form-group label-floating">
                      <label class="control-label">Email</label>
                      <input type="email" class="form-control" formControlName="email" required="required"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                        />
                    </div>
                    <div
                    *ngIf="submitted && f.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.email.errors.required">
                      Please enter your email address
                    </div>
                    <div *ngIf="f.email.errors.email">
                      Please enter valid Email
                    </div>
                  </div>
                  </div>
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="material-icons mt-3">lock_outline</i>
                    </span>
                    <div class="form-group label-floating">
                      <label class="control-label">Password</label>
                      <input type="password" class="form-control" formControlName="password" required="required"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    </div>
                    <div
                    *ngIf="submitted && f.password.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.password.errors.required">
                      Please enter password
                    </div>
                    <div *ngIf="f.password.errors.minlength">
                      Password should be minimum 6 digit
                    </div>
                  </div>
                  </div>
                  
                    <div class="row ml-0">
                        <div class="col-sm-6">
                            <div class="form-group pl-2">
                                <div class="custom-control custom-checkbox">
                                    <input formControlName="remember" type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                                    <label class="custom-control-label" for="customCheck">Remember Me</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group text-right">
                                <a routerLink="/forgotPassword" class="text-black">Forgot Password?</a>
                                
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Log In</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>