/*@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators} from '@angular/forms'
import { UserService } from '../../../services/user.service'
import { Router } from '@angular/router'
import { ConfirmedValidator } from './old-pwd.validators'
import {NotificationsService} from "../../../services/notifications.service";
import Swal from 'sweetalert2';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
    resetPasswordForm!: FormGroup;
    submitted = false;
    loading = false;

    constructor(private formbuilder:FormBuilder, private userService: UserService, private router:Router,private notifierService: NotificationsService) { }

    ngOnInit(): void {
        this.changePasswordControls()
    }

    changePasswordControls = () => {
        this.resetPasswordForm = this.formbuilder.group({
            oldPassword:['', Validators.required],
            password:['',Validators.required],
            confirmPassword:['',Validators.required]
        },
        {
            validator: ConfirmedValidator('password', 'confirmPassword')
        } 
        )
    };

    get f() {
        return this.resetPasswordForm.controls;
      }

    changePassword = () => {
        this.submitted = true;
        if (this.resetPasswordForm.invalid) {
          return;
        }
        this.loading = true;
        
        delete this.resetPasswordForm.value.confirmPassword;
        this.userService
        .resetPassword(this.resetPasswordForm.value)
        .toPromise()
        .then(resp => {
        try {
            if (resp.success) {
                this.notifierService.showSuccess(resp.message);
                
            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
              })
            
        }
        });
    };

}
