import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import {NotificationsService} from "../../../../services/notifications.service";
import Swal from 'sweetalert2';
import { ApiUrl } from "../../../../utils/config";

@Component({
  selector: 'app-profile-table',
  templateUrl: './profile-table.component.html',
  styleUrls: ['./profile-table.component.css']
})
export class ProfileTableComponent implements OnInit {

  userList:any;
  currentPages = 1;
  postsPerPage= 10;
  count= 0;
  page = 1;
  tableSize = 10;
  userDetails:any;
  base: any = ApiUrl;

  constructor(private userService: UserService,private notify:NotificationsService) { }

  ngOnInit(): void {
    this.getUserList(1);
  }

  getUserList = (page:Number)=>{
    page = page?page:1
    this.userService.getUserLists(page).toPromise().then(response=>{
      if(response.success){
        this.userList = response.data.result;
        this.count = response.data.count;
      }
    });
  }
  
  onChange =(e:any,id:any)=>{
    let message = !e?'user is blocked now':"user is active now"
    let data = {
      userId:id,
      status:!e?"BLOCK":"ACTIVE"
    }
    this.userService.updateStatus(data).toPromise().then(response=>{
      if(response.success){
        this.notify.showSuccess(message)
      }
    })
  }

  deleteUser = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteUser(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getUserList(1)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

  onTableDataChange(event:any){
    this.page = event;
    this.currentPages = this.page;
    this.getUserList(this.page);
  }  

  onTableSizeChange(event:any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getUserList(1);
  }  

  viewUser = (data: any) => {
    
    this.userDetails = data;
  }

}
