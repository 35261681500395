/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private toastr: ToastrService,
  ) {}

  /**
   * show success response
   * @param {Message, title}
   */
  showSuccess(title:any) {
    this.toastr.success("", title);
  }

  /**
   * show error response
   * @param {Message, title}
   */
  showError(title:any) {
    try{
      this.toastr.error("", title);
    }catch(err){
      throw err;
    }
  }

  /**
   * show information response
   * @param {Message, title}
   */
  showInfo(title:any) {
    this.toastr.info("", title);
  }

}
