
/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from '../app/components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DashboardComponent } from '../app/components/dashboard/dashboard.component';
import { TokenInterceptor } from "./auth/httpconfig.interceptor";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSortableModule } from 'ngx-sortable';
import { NgSelect2Module } from 'ng-select2';

import { CrytalComponent } from './components/crystal/crytal/crytal.component';

import { BackupDataComponent } from './components/backup-data/backup.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { PasswordComponent } from './components/password/password.component';
import { AddCrystalComponent } from './components/crystal/add-crystal/add-crystal.component';
import { NewComponent } from './new/new.component';
import { StoreComponent } from './components/store/store.component';
import { StoreAddComponent } from './components/store/store-add/store-add.component';
import { PostsComponent } from './components/posts/posts.component';
import { ReportedPostsComponent } from './components/reported-posts/reported-posts.component';
import { AboutUsComponent } from './components/master-config/about-us/about-us.component';
import { EthicalSourcingComponent } from './components/master-config/ethical-sourcing/ethical-sourcing.component';
import { AppTutorialComponent } from './components/master-config/app-tutorial/app-tutorial.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { GroupManagementComponent } from './components/master-config/group-management/group-management.component';
import { AddGroupComponent } from './components/master-config/group-management/group-add/add-group/add-group.component';
import { AddAppTutorialComponent } from './components/master-config/app-tutorial/app-tutorial-add/add-app-tutorial/add-app-tutorial.component';
import { HomeCreationComponent } from './components/master-config/home-creation/home-creation/home-creation.component';
import { AddContentCriteriaComponent } from './components/master-config/content-criteria/add-content-criteria/add-content-criteria.component';
import { ContentCriteriaComponent } from './components/master-config/content-criteria/content-criteria.component';
import { AddHomeCreationComponent } from './components/master-config/home-creation/add-home-creation/add-home-creation.component';
import { ApiOperationsComponent } from './components/api-operations/api-operations.component';
import { UpdateTablesComponent } from './components/update-tables/update-tables.component';
import { CreditManagementComponent } from './components/credit-managements/credit-management/credit-management.component';
import { AddCreditComponent } from './components/credit-managements/add-credit/add-credit.component';
import { PageTableComponent } from './components/page-tables/page-table/page-table.component';
import { AddPageComponent } from './components/page-tables/add-page/add-page.component';
import { ConstantConfigComponent } from './components/master-config/constant-config/constant-config.component';
import { AddConstantConfigComponent } from './components/master-config/add-constant-config/add-constant-config.component';
import { NotificationComponent } from './components/master-config/notifications/notification/notification.component';
import { AddNotificationComponent } from './components/master-config/notifications/add-notification/add-notification.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AllTablesComponent } from './components/all-tables/all-tables.component';
import { MyCrystalTableComponent } from './components/all-tables/my-crystal-table/my-crystal-table.component';
import { CrystalLibraryComponent } from './components/all-tables/crystal-library/crystal-library.component';
import { CompanyTableComponent } from './components/all-tables/company-table/company-table.component';
import { CommunityWallComponent } from './components/all-tables/community-wall/community-wall.component';
import { PostReportComponent } from './components/all-tables/post-report/post-report.component';
import { AutoIdHistoryComponent } from './components/all-tables/auto-id-history/auto-id-history.component';
import { PageTablesComponent } from './components/all-tables/page-tables/page-tables.component';
import { ConstantConfigsComponent } from './components/all-tables/constant-configs/constant-configs.component';
import { ContentCriteriasComponent } from './components/all-tables/content-criterias/content-criterias.component';
import { ContentGroupingComponent } from './components/all-tables/content-grouping/content-grouping.component';
import { ContentCreationComponent } from './components/all-tables/content-creation/content-creation.component';
import { ProfileTableComponent } from './components/all-tables/profile-table/profile-table.component';
import { SubscriptionTableComponent } from './components/all-tables/subscription-table/subscription-table.component';
import { UpdateTableComponent } from './components/all-tables/update-table/update-table.component';
import { CreditTableComponent } from './components/all-tables/credit-table/credit-table.component';
import { FavouriteComponent } from './components/all-tables/favourite/favourite.component';
import { NotesComponent } from './components/all-tables/notes/notes.component';
import { RatingComponent } from './components/all-tables/rating/rating.component';
import { PaymentSubscriptionComponent } from './components/all-tables/payment-subscription/payment-subscription.component';
import { PaymentSubscriptionComponentDummy } from './components/all-tables/payment-subscription-dummy/payment-subscription-dummy.component';
import { LogTableComponent } from './components/log-table/log-table.component';
import { RouterModule } from '@angular/router';
import { DemoComponent } from './demo/demo.component';
import { NotificationManagementComponent } from './notification-management/notification-management.component';
import { AddNotificationManagementComponent } from './add-notification-management/add-notification-management.component';
import { PaymentManagementComponent } from './payment-management/payment-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserSubscriptionComponent } from './components/all-tables/user-subscription/user-subscription.component';
import { CsvreadComponent } from './csvread/csvread.component';
import {Comment}from './components/Comment/reported-posts.component'
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CrytalComponent,
    UserProfileComponent,
    PasswordComponent,
    AddCrystalComponent,
    StoreComponent,
    NewComponent,
    StoreAddComponent,
    PostsComponent,
    ReportedPostsComponent,
    AboutUsComponent,
    EthicalSourcingComponent,
    AppTutorialComponent,
    GroupManagementComponent,
    AddGroupComponent,
    AddAppTutorialComponent,
    HomeCreationComponent,
    ContentCriteriaComponent,
    AddContentCriteriaComponent,
    AddHomeCreationComponent,
    ApiOperationsComponent,
    UpdateTablesComponent,
    CreditManagementComponent,
    AddCreditComponent,
    PageTableComponent,
    AddPageComponent,
    ConstantConfigComponent,
    AddConstantConfigComponent,
    NotificationComponent,
    AddNotificationComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    AllTablesComponent,
    MyCrystalTableComponent,
    CrystalLibraryComponent,
    CompanyTableComponent,
    CommunityWallComponent,
    PostReportComponent,
    AutoIdHistoryComponent,
    PageTablesComponent,
    ConstantConfigsComponent,
    ContentCriteriasComponent,
    ContentGroupingComponent,
    ContentCreationComponent,
    ProfileTableComponent,
    SubscriptionTableComponent,
    UpdateTableComponent,
    CreditTableComponent,
    FavouriteComponent,
    NotesComponent,
    BackupDataComponent,
    RatingComponent,
    PaymentSubscriptionComponent,
    PaymentSubscriptionComponentDummy,
    LogTableComponent,
    DemoComponent,
    NotificationManagementComponent,
    AddNotificationManagementComponent,
    PaymentManagementComponent,
    UserManagementComponent,
    UserSubscriptionComponent,
    CsvreadComponent,
    Comment,
  ],
  imports: [
    BrowserModule,
    CKEditorModule,
    EditorModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    UiSwitchModule,
    NgxPaginationModule,
    NgxSortableModule,
    NgSelect2Module,
    RouterModule
  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: TokenInterceptor, 
      multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
