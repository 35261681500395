import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user.service";
import { NotificationsService } from "../../services/notifications.service";
import { ApiUrl } from "../../utils/config";
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.css']
})
export class NotificationManagementComponent implements OnInit { 

  subscriptionForm !: FormGroup;
  submitted = false;
  loading = false;
  reportedPostList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;  
  reportedPostDetails: any;
  notificationDetails:any;
  base: any = ApiUrl;
  search: any = '';
  logoImageToUpload: any;
  detail:any
  idToShow:any
  selected_image:any
  logoImgUrl = "/assets/img/user.png";
  notificationTitle=""
  zero = 0
  constOne = 1
  filterStatus = ''
  fifteenHundred = 1500
  constructor(
    private userService: UserService,
    private notify: NotificationsService,
    private formBuilder:FormBuilder
  ) { }

  ngOnInit(): void {
    this.getallReportedPost(this.constOne,this.filterStatus);
    this.updateformControl();
  }
  onSearch(e:any){
    this.getallReportedPost(this.constOne,this.filterStatus);
 }

 onChange =(e:any,id:any)=>{
  let message = !e?'Notification is an Inactive now':"Notification is an active now"
  let data = {
    id:id,
    status:!e?false:true
  }
  this.userService.notificationStatus(data).toPromise().then(response=>{
    if(response.success){
      this.notify.showSuccess(message)
    }
  })
}

 onFileChangeLogoImage(evt:any){

  this.logoImageToUpload = <Array<File>>evt.target.files;
  let reader = new FileReader();
  reader.readAsDataURL(this.logoImageToUpload[this.zero]);
  reader.onload = (e: any) => {
      this.logoImgUrl = e.target.result;
  };
}

  getallReportedPost = (page: any,st:any) => {
    this.currentPages = page;
    this.page=page;
    let newstatus = st
    this.userService.getAllNotificationManagement(page,newstatus).toPromise()
      .then(response => {
        if (response.success) {
          this.reportedPostList = response.data.result;
          this.count = response.data.count;
        }
      });
  }

  formatDate(date:any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  getSubscriptionDetail = () => {
    let notificationDate = this.formatDate(this.notificationDetails.notificationDate)
    this.subscriptionForm = this.formBuilder.group({
      notificationTitle: [this.notificationDetails.notificationTitle, Validators.required],
      notificationSubTitle:[this.notificationDetails.notificationSubTitle],
      notificationLink:[this.notificationDetails.notificationLink],
      pushNotification: [this.notificationDetails.pushNotification],
      
      notificationStatus:[this.notificationDetails.notificationStatus],
      notificationDate:[notificationDate,Validators.required],
      createdAt: [this.notificationDetails.createdAt],
      updatedAt: [this.notificationDetails.updatedAt],
     
    })
  }

  changeFilter(e:any){
    let newstatus = e.target.value
    this.getallReportedPost(this.constOne,newstatus);

    
  }
  
  view = (data: any) => {
    this.logoImgUrl = this.base + '/' + data.logo
    this.notificationDetails = data;
    this.idToShow = data._id;
    this.getSubscriptionDetail();
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getallReportedPost(this.page,this.filterStatus);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = this.constOne;
    this.getallReportedPost(this.page,this.filterStatus);
  }
  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteNotificationByAdmin(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your Notification has been deleted.',
              'success'
            )
            this.getallReportedPost(this.constOne,this.filterStatus)
          }
        })
      }
    }).catch(err => {
        Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

  get f() {
    return this.subscriptionForm.controls;
  }

  // getNotificationDetail = () => {
  //   this.subscriptionForm = this.formBuilder.group({
  //     notificationDate: [this.notificationDetails.notificationDate, [Validators.required]],
  //     notificationLink: [this.notificationDetails.notificationLink],
  //     notificationSubTitle: [this.notificationDetails.notificationSubTitle],
  //     pushNotification: [this.notificationDetails.pushNotification, [Validators.required]],
  //     notificationTitle: [this.notificationDetails.notificationTitle, [Validators.required]],
  //     status: [this.notificationDetails.status, [Validators.required]],

  //   })
  // }

  updateformControl = () => {
    this.subscriptionForm = this.formBuilder.group({
      notificationDate: ['', [Validators.required]],
      notificationLink: [''],
      notificationSubTitle: [''],
      pushNotification: [false],
      notificationTitle: ['', [Validators.required]],
      notificationStatus: ['None'],
      createdAt: [''],
      updatedAt:['']
    })
  }

  updateSubscription = () => {
    this.submitted = true;
    const formData = new FormData();

    if (this.logoImageToUpload && this.logoImageToUpload[this.zero]) {
      formData.append('logo', this.logoImageToUpload[this.zero]);
    }

    for (let key in this.subscriptionForm.value) {
      formData.append(key, this.subscriptionForm.value[key]);
    }
    
    if (this.subscriptionForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .updateNotificationManagement(formData, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Notification Updated Successfully',
            showConfirmButton: false,
            timer: this.fifteenHundred
          })
          this.getallReportedPost(this.constOne,this.filterStatus)
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }
  

}
