import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { ApiUrl } from "../../../../utils/config";

@Component({
  selector: 'app-post-report',
  templateUrl: './post-report.component.html',
  styleUrls: ['./post-report.component.css']
})
export class PostReportComponent implements OnInit {

  reportedPostList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;

  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getallReportedPost(1);
  }

  getallReportedPost = (page: Number) => {
    page = page ? page : 1
    this.userService.getReportedPost(page).toPromise()
      .then(response => {
        if (response.success) {
          this.reportedPostList = response.data.result;
          this.count = response.data.count;
        }
      });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getallReportedPost(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getallReportedPost(this.page);
  }

  viewPost = (data: any) => {
    this.reportedPostDetails = data;
  }

}
