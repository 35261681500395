import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { ApiUrl } from "../../../../utils/config";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.css']
})
export class SubscriptionTableComponent implements OnInit {

  subscriptionList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  submitted = false;
  loading = false;
  idToShow: any;
  subscriptionForm !: FormGroup;
  subscriptionDetails: any;
  base: any = ApiUrl;

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getSubscriptionList(1);
    this.updateformControl()
  }
  getSubscriptionList = (page: Number) => {
    page = page ? page : 1 
    this.userService.getSubscriptionLists(page).toPromise().then(response => {
      if (response.success) {
        this.subscriptionList = response.data.result;
        
        this.count = response.data.count;
      }
    });
  }
  get f() {
    return this.subscriptionForm.controls;
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getSubscriptionList(this.page);
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getSubscriptionList(1);
  }

  viewSubscription = (data: any) => {
    this.subscriptionDetails = data;
    this.idToShow = data._id;
    this.getSubscriptionDetail();
  }

  getSubscriptionDetail = () => {
    this.subscriptionForm = this.formbuilder.group({
      planName: [this.subscriptionDetails.planName, Validators.required],
      planCost: [this.subscriptionDetails.planCost, [Validators.required, Validators.pattern(/^[0-9.]+$/)]],
      description:[this.subscriptionDetails.description,Validators.required],
      durationType:[this.subscriptionDetails.durationType,Validators.required],
      scanQuantity:[this.subscriptionDetails.scanQuantity,[Validators.required,Validators.pattern(/^[0-9]+$/)]],
      collectionQuantity:[this.subscriptionDetails.collectionQuantity,[Validators.required,Validators.pattern(/^[0-9]+$/)]]
    })
  }

  updateformControl = () => {
    this.subscriptionForm = this.formbuilder.group({
      planName: ['', Validators.required],
      planCost: ['', [Validators.required, Validators.pattern(/^[0-9.]+$/)]],
      description:['',Validators.required],
      durationType:['',Validators.required],
      scanQuantity:['',[Validators.required,Validators.pattern(/^[0-9]+$/)]],
      collectionQuantity:['',[Validators.required,Validators.pattern(/^[0-9]+$/)]]
    })
  }

  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteSubscription(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getSubscriptionList(1)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

  updateSubscription = () => {
    this.submitted = true;
    const formData = new FormData();

    for (let key in this.subscriptionForm.value) {
      formData.append(key, this.subscriptionForm.value[key]);
    }
    
    if (this.subscriptionForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .updateSubscription(this.subscriptionForm.value, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Subscription Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.getSubscriptionList(this.page);
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

}
