<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                      User (profile table)
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * postsPerPage+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * postsPerPage+1) + (userList?userList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="whitespace-no-wrap">Sr No.</th>
                                    <th class="whitespace-no-wrap">firstName </th>
                                    <th class="whitespace-no-wrap"> lastName</th>
                                    <th class="text-center whitespace-no-wrap">displayName</th>
                                    <th class="text-center whitespace-no-wrap">email</th>
                                    <th class="text-center whitespace-no-wrap">completeProfile</th>
                                    <th class="text-center whitespace-no-wrap">Push Notification</th>
                                    <th class="text-center whitespace-no-wrap">Platform</th>
                                    <th class="text-center whitespace-no-wrap">deviceType</th>
                                    <th class="text-center whitespace-no-wrap">country</th>
                                    <th class="text-center whitespace-no-wrap">state</th>
                                    <th class="text-center whitespace-no-wrap">city</th>
                                    <th class="text-center whitespace-no-wrap">distribution</th>
                                    <th class="text-center whitespace-no-wrap">isVerified</th>
                                    <th class="text-center whitespace-no-wrap">resetToken</th>
                                    <th class="text-center whitespace-no-wrap">fcmToken</th>

                                    <th class="text-center whitespace-no-wrap">guId </th>
                                    <th class="text-center whitespace-no-wrap">role </th>
                                    <th class="text-center whitespace-no-wrap">dob </th>
                                    <th class="text-center whitespace-no-wrap">tob </th>
                                    <th class="text-center whitespace-no-wrap">status</th>
                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>

                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let user of userList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{user.firstName}} 
                                    </td>
                                    <td class="text-center"> {{user.lastName}}
                                    </td>
                                    <td class="text-center">{{user.displayName}}</td>
                                    <td class="text-center">{{user.email}}</td>
                                    <td class="text-center">{{user.completeProfile}}</td>
                                    <td class="text-center">{{user.toggleStatus}}</td>
                                    <td class="text-center">{{user.deviceId}}</td>
                                    <td class="text-center">{{user.deviceType}}</td>
                                    <td class="text-center">{{user.country}}</td>
                                    <td class="text-center">{{user.state}}</td>
                                    <td class="text-center">{{user.city}}</td>
                                    <td class="text-center">{{user.distribution}}</td>
                                    <td class="text-center">{{user.isVerified}}</td>
                                    <td class="text-center">{{user.resetToken}}</td>
                                    <td class="text-center">{{user.fcmToken}}</td>

                                    <td class="text-center">{{user.guId}}</td>
                                    <td class="text-center">{{user.role}}</td>
                                    <td class="text-center">{{user.dob | date: "shortDate" }}</td>
                                    <td class="text-center">{{user.tob }}</td>
                                    <td class="text-center">
                                        {{user.status}}
                                    </td>
                                    <td class="text-center">{{user.createdAt}}</td>
                                    <td class="text-center">{{user.updatedAt}}</td>

                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                        <a class="flex items-center text-success mr-3"  data-toggle="modal"
                                        data-target="#viewUser" (click)="viewUser(user)"
                                        href="javascript:void(0);"> 
                                            <i class="fa fa-eye"></i>
                                          </a>
                                            
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteUser" role="dialog"> 
        <div class="modal-dialog">
        
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
              <h4 class="modal-title">Delete</h4>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete this data?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <pagination-controls 
          previousLabel="Prev" 
          nextLabel="Next" 
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
</div>

<div class="modal" id="viewUser">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">profile table info</h4>
                  <div class="row">
                    
                      <div class="">
                          <div class="columns">
                              <div class="circle">
                                  
                                <img src="{{
                                    userDetails 
                                        ? base + '/'+ userDetails.profileImg
                                        : 'assets/img/user.png'
                                    }}" class="w-100 h-100" />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>firstName</th>
                                <td>{{ userDetails?userDetails.firstName:'' }}
                                </td>
                                <th>lastName</th>
                                <td>{{userDetails ? userDetails.lastName:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>displayName</th>
                                <td>{{ userDetails?userDetails.displayName:'' }}
                                </td>
                                <th>Push Notification</th>
                                <td>{{ userDetails?userDetails.toggleStatus:'' }}
                                </td>
                            </tr>
                            <tr>
                                <th>guId</th>
                                <td>{{ userDetails?userDetails.guId:'' }}
                                </td>
                                <th>Platform</th>
                                <td>{{userDetails ? userDetails.deviceId:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>deviceType</th>
                                <td>{{ userDetails?userDetails.deviceType:'' }}
                                </td>
                                <th>email</th>
                                <td>{{userDetails ? userDetails.email:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>role</th>
                                <td>{{ userDetails?userDetails.role:'' }}
                                </td>
                                <th>status</th>
                                <td>{{userDetails ? userDetails.status:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>dob</th>
                                <td>{{ userDetails?userDetails.dob:'' }}
                                </td>
                                <th>tob</th>
                                <td>{{userDetails ? userDetails.tob:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>country</th>
                                <td>{{ userDetails?userDetails.country:'' }}
                                </td>
                                <th>state</th>
                                <td>{{userDetails ? userDetails.state:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>city</th>
                                <td>{{ userDetails?userDetails.city:'' }}
                                </td>
                                <th>distribution</th>
                                <td>{{ userDetails?userDetails.distribution:'' }}
                                </td>
                                
                            </tr>
                          
                            <tr>
                                <th>completeProfile</th>
                                <td>{{ userDetails?userDetails.completeProfile:'' }}
                                </td>
                                <th>isVerified</th>
                                <td>{{userDetails ? userDetails.isVerified:""}}
                                </td>
                          
                            <tr>
                                <th>resetToken</th>
                                <td>{{ userDetails?userDetails.resetToken:'' }}
                                </td>
                                <th>fcmToken</th>
                                <td>{{userDetails ? userDetails.fcmToken:""}}
                                </td>
                            </tr>
                            <tr>
                              <th>createdAt</th>
                              <td>{{userDetails ? userDetails.createdAt:""}}</td>
                              <th>updatedAt</th>
                              <td><ng-container *ngIf="userDetails!=undefined">
                                  {{ userDetails?userDetails.updatedAt:'' }} 
                                  </ng-container>
                              </td>
                          </tr>
                            
                            
                            
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
    
      
</div>