import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/services/notifications.service';
import { UserService } from 'src/services/user.service';
import { ApiUrl } from 'src/utils/config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  idToShow: any;
  list: any;
  currentPages = 1;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;
  rawImageUrl = "/assets/img/user.png";
  one = 1
  year=365;
  month=30;

  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getAllPayment(this.one);
  }

  getAllPayment = (page: Number) => {
    page = page ? page : this.one
    this.userService.userManagement(page).subscribe(data => {
      console.log("object",data.data)
      this.count = data.data.count
      this.list = data.data.getSubscription;
    })
  }

  viewSubscription = (data: any) => {
    this.idToShow = data._id;
  }


 /** If EndDate not found then as per  PLAN Type and Start Plan Date - return EndDate */

  checkEndDate = (startdate: any,enddate: any,plan: any) => {
	if(!enddate) {
		
		var sdate = new Date(startdate); 
			//enddate = sdate.setFullYear(sdate.getFullYear() + 1);
		    	
		if(plan == "Monthly") {
			enddate = sdate.setDate(sdate.getDate() + this.month);
		} else if(plan == "Yearly") {
			enddate = sdate.setDate(sdate.getDate() + this.year);
		}		
		
		return enddate;

	} else {
		return enddate;
	}
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getAllPayment(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = this.one;
    this.getAllPayment(this.page);
  }

  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deletePaymentManagement(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getAllPayment(this.one);
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

}
