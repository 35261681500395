<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->


<div class="wrapper wrapper-full-page">
    <div class="full-page login-page" data-color="blue">
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-12 offset-md-4">
              <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
                <div class="card card-login">
                  <a class="navbar-brand text-center mx-auto w-100" href="#">
                    <img src="assets/img/logo.png??thumbnail==227" class="img-fluid mx-auto" />
                  </a>
                  <div class="card-header text-center borderbottom0 pt-2 pb-2">
                    <h3 class="card-title mt-2">Forgot Password</h3>
                    <p class="mb-3">Please enter your valid email address.</p>
                  </div>
                  <div class="card-content">
                    <div class="input-group">
                      <span class="input-group-addon">
                        <i class="material-icons mt-3">email</i>
                      </span>
                      <div class="form-group label-floating">
                        <label class="control-label">Your Email</label>
                        <input type="email" class="form-control" formControlName="email" required="required"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                          />
                      </div>
                      <div
                      *ngIf="submitted && f.email.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.email.errors.required">
                        Please enter your email address
                      </div>
                      <div *ngIf="f.email.errors.email">
                        Please enter valid Email
                      </div>
                    </div>
                    </div>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>







