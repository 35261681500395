<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <div class="row">
						<div class="col-lg-12 ">
							<h3 class="text-lg mb-0 mt-0">
								Crystals (crystal library)
								
							</h3>
						</div>
                    </div>
					<div class="row">
						<div class="col-md-3 "></div>
						<div class="col-md-9 ">
						  <div class="form-group text-right showing_line" >
							<span>
							  Showing 
							  <b>{{ (currentPages - 1) * postsPerPage+1}}</b>
							  -
							  <b>{{ ((currentPages - 1) * postsPerPage+1) + (crystalList?crystalList.length-1:0)}}</b>
							  of 
							  <b>{{count}}</b>
							   items
							</span>
						  </div>
						</div>
					  </div>
                    <div class="table-responsive">
                    <table width="100%" class="table table-bordered table-striped table-crystal">
                    	<thead>
                    		<th>ID</th>
                    		<th>crystalName</th>
                    		<th>description</th>
                    		<th>knownAs</th>
                    		<th>knownFor</th>
                    		<th>physical</th>
                    		<th>primaryChakra</th>
                    		<th>secondaryChakra</th>
                    		<th>spiritual</th>
                    		<th>rarity</th>
                    		<th>color</th>
                    		<th>chemicalComposition</th>
                    		<th>mineralClass</th>
                    		<th>crystalSystem</th>
                    		<th>astrologicalSign</th>
                    		<th>ethicalSourced</th>
                    		<th>hardness</th>
                    		<th>location</th>
                    		<th>numericalVibration</th>
                    		<th>pronunciation</th>
							<th>affirmation</th>
							<th>emotional</th>
                    		<th>alternateStoneName</th>
							<th>viewCrystal</th>
							<th>crystalImg</th>
							<th>rawImage</th>
							<th>polishedImage</th>
							<th>polishedImage</th>
							<th>polished_thumbnail_image</th>
							

							


							<th class="">createdAt</th>
                            <th class="">updatedAt</th>
                    		<th>Actions</th>
                    	</thead>
                    	<tbody>
                    		<tr *ngFor="let crystal of crystalList | paginate : {
								itemsPerPage: tableSize,
								currentPage: page,
								totalItems: count
							  }; index as i">
                    			<td>{{crystal._id}}</td>
                    			<td>{{crystal.crystalName}}</td>
                    			<td class="truncate">{{crystal.description}}</td>
                    			<td>{{crystal.knownAs}}</td>
                    			<td>{{crystal.knownFor}}</td>
                    			<td class="truncate">{{crystal.physical}}</td>
                    			<td>{{crystal.primaryChakra}}</td>
                    			<td>{{crystal.secondaryChakra}}</td>
                    			<td class="truncate">{{crystal.spiritual}}</td>
                    			<td>{{crystal.rarity}}</td>
                    			<td>{{crystal.color}}</td>
                    			<td class="truncate">{{crystal.chemicalComposition}}</td>
                    			<td>{{crystal.mineralClass}}</td>
                    			<td>{{crystal.crystalSystem}}</td>
                    			<td>{{crystal.astrologicalSign}}</td>
                    			<td>{{crystal.ethicalSourced}}</td>
                    			<td>{{crystal.hardness}}</td>
                    			<td class="truncate">{{crystal.location}}</td>
                    			<td>{{crystal.numericalVibration}}</td>
                    			<td>{{crystal.pronunciation}}</td>
                    			<td class="truncate">{{crystal.affirmation}}</td>
								<td class="truncate">{{crystal.emotional}}</td>
								<td class="truncate">{{crystal.alternateStoneName}}</td>

								<td>{{crystal.viewCrystal}}</td>
							<td>{{crystal.crystalImg}}</td>
							<td>{{crystal.rawImage}}</td>
							<td>{{crystal.polishedImage}}</td>
							<td>{{crystal.polishedImage}}</td>
							<td>{{crystal.polished_thumbnail_image}}</td>
								<td class="">{{ crystal.createdAt }}</td>
                                    <td class="">{{ crystal.updatedAt }}</td>
								<td>
									<a data-toggle="modal" data-target="#viewCrystal" (click)="viewCrystal(crystal)" class="text-succes mr-1">
										<i class="fa fa-eye"></i></a>
									
								</td>
                    		</tr>
                    	</tbody>
                    </table>
				</div>
				<div class="d-flex justify-content-center">
					<pagination-controls 
					  previousLabel="Prev" 
					  nextLabel="Next" 
					  (pageChange)="onTableDataChange($event)">
					</pagination-controls>
				  </div>
                </div>
            </div>
        </div>
	</div>
	
	<div class="modal" id="viewCrystal">
		<div class="modal-dialog modal-lg">
		  <div class="modal-content">
			<div class="modal-header p-0">
			  <button type="button" class="close" data-dismiss="modal">
				&times;
			  </button>
			</div>
				<div class="modal-body">
					<h4 class="mb-4">Crystal Info</h4>
					<div class="row">
					  <div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="rawImageUrl" class="w-100 h-100" />
										<img class="profile-pic" src="{{
											selected_image
												? base + '/'+ selected_image
												: 'assets/img/user.png'
											}}">
									</div>
									
								</div>
							</div>
						</div>
						<div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="raw_thumbnail_imageUrl" class="w-100 h-100" />
									</div>
									<img class="profile-pic" src="{{
									selected_image
										? base + '/'+ selected_image
										: 'assets/img/user.png'
									}}">
								</div>
							</div>
						</div>
						<div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="polishedImageUrl" class="w-100 h-100" />
									</div>
									<img class="profile-pic" src="{{
									selected_image
										? base + '/'+ selected_image
										: 'assets/img/user.png'
									}}">
								</div>
							</div>
						</div>
						<div class="">
							<div class="columns">
								<div class="circle">
									<div class="pic-upload">
										<img [src]="polished_thumbnail_imageUrl" class="w-100 h-100" />
									</div>
									<img class="profile-pic" src="{{
									selected_image
										? base + '/'+ selected_image
										: 'assets/img/user.png'
									}}">
								</div>
							</div>
						</div>
						
					</div>
					<div class="detailed-view table-responsive">
                        <table width="100%" class="crystal-detail table table-bordered table-striped">
                             <tr>
                                  <th>crystalName</th>
                                  <td>{{crystalDetails ? crystalDetails.crystalName:""}}</td>
                                  <th>knownAs</th>
                                  <td>{{crystalDetails ? crystalDetails.knownAs:""}}</td>
                             </tr>
                             <tr>
                                  <th>knownFor</th>
                                  <td>{{crystalDetails ? crystalDetails.knownFor:""}}</td>
                                  <th>physical</th>
                                  <td>{{crystalDetails ? crystalDetails.physical:""}}</td>
                             </tr>
                             <tr>
                                  <th>description</th>
                                  <td>{{crystalDetails ? crystalDetails.description:""}}</td>
                                  <th>primaryChakra</th>
                                  <td>{{crystalDetails ? crystalDetails.primaryChakra:""}}</td>
                             </tr>
                             <tr>
                                  <th>secondaryChakra</th>
                                  <td>{{crystalDetails ? crystalDetails.secondaryChakra:""}}</td>
                                  <th>spiritual</th>
                                  <td>{{crystalDetails ? crystalDetails.spiritual:""}}</td>
                             </tr>
                             <tr>
                                  <th>color</th>
                                  <td>{{crystalDetails ? crystalDetails.color:""}}</td>
                                  <th>rarity</th>
                                  <td>{{crystalDetails ? crystalDetails.rarity:""}}</td>
                             </tr>
                             <tr>
                                  <th>chemicalComposition</th>
                                  <td>{{crystalDetails ? crystalDetails.chemicalComposition:""}}</td>
                                  <th>mineralClass</th>
                                  <td>{{crystalDetails ? crystalDetails.mineralClass:""}}</td>
                             </tr>
                             <tr>
                                  <th>crystalSystem</th>
                                  <td>{{crystalDetails ? crystalDetails.crystalSystem:""}}</td>
                                  <th>astrologicalSign</th>
                                  <td>{{crystalDetails ? crystalDetails.astrologicalSign:""}}</td>
                             </tr>
                             <tr>
                                  <th>ethicalSourced</th>
                                  <td>{{crystalDetails ? crystalDetails.ethicalSourced:""}}</td>
                                  <th>hardness</th>
                                  <td>{{crystalDetails ? crystalDetails.hardness:""}}</td>
                             </tr>
                             <tr>
                                  <th>location</th>
                                  <td>{{crystalDetails ? crystalDetails.location:""}}</td>
                                  <th>numericalVibration</th>
                                  <td>{{crystalDetails ? crystalDetails.numericalVibration:""}}</td>
                             </tr>
                             <tr>
                                  <th>pronunciation</th>
                                  <td>{{crystalDetails ? crystalDetails.pronunciation:""}}</td>
                                  <th>affirmation</th>
                                  <td>{{crystalDetails ? crystalDetails.affirmation:""}}</td>
                             </tr>
                             <tr>
                                  <th>emotional</th>
                                  <td>{{crystalDetails ? crystalDetails.emotional:""}}</td>
                                  <th>alternateStoneName</th>
                                  <td>{{crystalDetails ? crystalDetails.alternateStoneName:""}}</td>
                             </tr>
							 <tr>
								<th>viewCrystal</th>
								<td>{{crystalDetails ? crystalDetails.viewCrystal:""}}</td>
								<th>crystalImg</th>
								<td>{{crystalDetails ? crystalDetails.crystalImg:""}}</td>
						   	</tr>
							<tr>
                                <th>createdAt</th>
                                <td>{{crystalDetails ? crystalDetails.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="crystalDetails!=undefined">
                                    {{ crystalDetails?crystalDetails.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="viewStore">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header p-0">
			  <button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>   
			  <div class="card">
					  <div class="card-content">
						  <div class="row">
							 
							  <div class="col-lg-12 ">
								  <h3 class="text-lg mb-0">
									Crystal Info 
								  </h3>
							  </div>
						  </div>
						  <div class="row mt-3">
							  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
								  <div class="card">
									  <div class="card-body">
										<form [formGroup]="updateUser" (ngSubmit)="updateCrystal()" class="custom-mt-form">
											<div class="d-flex flex-wrap">
											<div class="">
													<h4 class="text-center">Raw Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="rawImageUrl" class="w-100 h-100" />
                                                            </div>
															<img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}">
														</div>
														<div class="p-image">
															<label for="fileuploadss">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploadss" class="file-upload"
																(change)="onImageChangeRaw($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
												<div class="ml-4">
													<h4 class="text-center">Raw Thumbnail Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="raw_thumbnail_imageUrl" class="w-100 h-100" />
                                                            </div>
															<img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}">
														</div>
														<div class="p-image">
															<label for="fileuploads2">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploads2" class="file-upload"
																(change)="onImageChangeraw_thumbnail($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
												<div class="ml-4">
													<h4 class="text-center">Polished Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="polishedImageUrl" class="w-100 h-100" />
                                                            </div>
															<img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}">
														</div>
														<div class="p-image">
															<label for="fileuploads3">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploads3" class="file-upload"
																(change)="onImageChangepolishedImage($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
												<div class="ml-4">
													<h4 class="text-center">Polished Thumbnail Image</h4>
	
													<div class="columns">
														<div class="circle">
															<div class="pic-upload">
                                                                <img [src]="polished_thumbnail_imageUrl" class="w-100 h-100" />
                                                            </div>
															<img class="profile-pic" src="{{
															selected_image
																? base + '/'+ selected_image
																: 'assets/img/user.png'
															}}">
														</div>
														<div class="p-image">
															<label for="fileuploads4">
																<i class="fa fa-camera upload-button"></i>
															</label>
															<input id="fileuploads4" class="file-upload"
																(change)="onImageChangepolished_thumbnail_image($event)" type="file"
																accept="image/*">
														</div>
													</div>
													
												</div>
											</div>
											<div class="row m-0">
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Crystal Name</label> 
														<input 
														formControlName="crystalName"
														type="text"
														class="form-control" 
														placeholder="Enter Crystal Name"
														>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Known As</label>
														<input 
														formControlName="knownAs"
														type="text" 
														class="form-control" 
														placeholder="Known As"
														>
													</div>
												</div>
	  
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Known For</label> 
														<input 
														formControlName="knownFor"
														type="text"
														class="form-control" 
														placeholder="Known For"
														 readonly>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Physical</label>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of physicalArray; let i=index" >
															<input 
																value={{alias}}
																type="text" 
																class="form-control" 
																placeholder="Enter Physical"
															>
														</div>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Description</label>
														<input 
														formControlName="description"
														type="text" 
														class="form-control" 
														placeholder="Enter Description"
														>
													</div>
												</div>
	  
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Primary Chakra</label>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of primaryChakraArray; let i=index" >
															<input 
																value={{alias}}
																type="text" 
																class="form-control" 
																placeholder="Enter Primary Chakra"
															>
														</div>
													</div>
												</div>
	  
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Secondary Chakra</label>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of secondaryChakraArray; let i=index" >
															<input 
																value={{alias}}
																type="text" 
																class="form-control" 
																placeholder="Enter Secondary Chakra"
															>
														</div>
													</div>
												</div>
												
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Spiritual</label>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of spiritualArray; let i=index" >
															<input 
																value={{alias}}
																type="text" 
																class="form-control" 
																placeholder="Enter Spiritual"
															>
														</div>
													</div>
												</div>

												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Color</label>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of colorArray; let i=index" >
															<input 
																value={{alias}}
																type="text" 
																class="form-control" 
																placeholder="Enter Color"
															>
														</div>
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Rarity</label> 
														<input 
														formControlName="rarity"
														type="text"
														class="form-control" 
														placeholder="Enter Rarity">
													</div>
												</div>
											  <div class="col-lg-4 col-md-4 col-12">
												  <div class="form-group custom-mt-form-group">
													  <label class="control-label">Chemical Composition</label> 
													  <input 
													  formControlName="chemicalComposition"
													  type="text"
													  class="form-control" 
													  placeholder="Chemical Composition">
												  </div>
											  </div>
											  <div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
													<label class="control-label">Mineral Class</label>
														<div class="form-group custom-mt-form-group"
														*ngFor="let alias of mineralClassArray; let i=index" >
															<input 
																value={{alias}}
																type="text" 
																class="form-control" 
																placeholder="Enter Mineral Class"
															>
														</div>
													</div>
												</div>

											  <div class="col-lg-4 col-md-4 col-12">
												  <div class="form-group custom-mt-form-group">
													  <label class="control-label">Crystal System</label> 
													  <input 
													  formControlName="crystalSystem"
													  type="text"
													  class="form-control" 
													  placeholder="Crystal System">
												  </div>
											  </div>
											  <div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Astrological Sign</label>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of astrologicalSignArray; let i=index" >
														<input 
															value={{alias}}
															type="text" 
															class="form-control" 
															placeholder="Astrological Sign"
														>
													</div>
												</div>
											</div>

											  <div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Pronunciation</label> 
													<input 
													formControlName="pronunciation"
													type="text"
													class="form-control" 
													placeholder="Pronunciation">
												</div>
											 </div>
											  <div class="col-lg-6 col-md-6 col-12">
												  <div class="form-group custom-mt-form-group">
													  <label class="control-label">Ethical Sourced</label> 
													  <input 
													  formControlName="ethicalSourced"
													  type="text"
													  class="form-control" 
													  placeholder="Ethical Sourced">
												  </div>
											  </div>

											  <div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Hardness</label> 
													<input 
													formControlName="hardness"
													type="text"
													class="form-control" 
													placeholder="Hardness">
												</div>
											</div>
											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Location</label>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of locationArray; let i=index" >
														<input 
															value={{alias}}
															type="text" 
															class="form-control" 
															placeholder="Location"
														>
													</div>
												</div>
											</div>

											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
													<label class="control-label">Affirmation</label> 
													<input 
													formControlName="affirmation"
													type="text"
													class="form-control" 
													placeholder="Affirmation">
												</div>
											</div>
											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Emotional</label>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of emotionalArray; let i=index" >
														<input 
															value={{alias}}
															type="text" 
															class="form-control" 
															placeholder="Emotional"
														>
													</div>
												</div>
											</div>
											
											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Alternate Stone Name</label>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of alternateStoneNameArray; let i=index" >
														<input 
															value={{alias}}
															type="text" 
															class="form-control" 
															placeholder="Alternate Stone Name"
														>
													</div>
												</div>
											</div>

											<div class="col-lg-6 col-md-6 col-12">
												<div class="form-group custom-mt-form-group">
												<label class="control-label">Numerical Vibration</label>
													<div class="form-group custom-mt-form-group"
													*ngFor="let alias of numericalVibrationArray; let i=index" >
														<input 
															value={{alias}}
															type="text" 
															class="form-control" 
															placeholder="Numerical Vibration"
														>
													</div>
												</div>
											</div>
											
											</div>
											<div class="col-lg-12 col-md-12 col-sm-12 col-12">
												<div class="form-group custom-mt-form-group">
													<button class="btn btn-success mr-2" type="submit">Submit</button>
												</div>
											</div>
										</form>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	</div>