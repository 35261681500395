/*@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../services/user.service";
import { NotificationsService } from "../../../services/notifications.service";
import { ApiUrl } from "../../../utils/config";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reported-posts',
  templateUrl: './reported-posts.component.html',
  styleUrls: ['./reported-posts.component.css']
})
export class ReportedPostsComponent implements OnInit {
  reportedPostList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;
  search: any = '';
  defaultPage: any = 1;

  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getallReportedPost(1);
  }
  onSearch(e:any){
    this.getallReportedPost(1);
 }

  getallReportedPost = (page: any) => {
    this.currentPages = page;
    this.page=page;
    this.userService.getReportedPostSearch(page,this.search).toPromise()
      .then(response => {
        if (response.success) {
          this.reportedPostList = response.data.result;
          this.count = response.data.count;
        }
      });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getallReportedPost(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getallReportedPost(this.page);
  }

  viewPost = (data: any) => {
    this.reportedPostDetails = data;
  }
  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteReportedPost(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getallReportedPost(this.defaultPage)
          }
        })
      }
    }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
    });
  }
}
