import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { ApiUrl } from "../../../../utils/config";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-content-grouping',
  templateUrl: './content-grouping.component.html',
  styleUrls: ['./content-grouping.component.css']
})
export class ContentGroupingComponent implements OnInit {

  subscriptionList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;

  tableSize = 10;
  submitted = false;
  loading = false;
  idToShow: any;
  subscriptionForm !: FormGroup;
  groupDetails: any;
  base: any = ApiUrl;

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getSubscriptionList(1);
    this.updateformControl()
  }

  getSubscriptionList = (page: Number) => {
    page = page ? page : 1
    this.userService.getGroups(page).toPromise().then(response => {
      if (response.success) {
        this.subscriptionList = response.data.result;
        this.count = response.data.count;
      }
    });
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getSubscriptionList(this.page);
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getSubscriptionList(1);
  }

  listSorted(evt:any){
    

    var arr :any = [];
    
    evt.forEach((element:any,i:any) => {

      var order:any = i+1
      var single_obj = {
        id:element._id,
        groupOrder: order
      }
      arr.push(single_obj);
    });
  
  var obj = {
    dropDownData:arr
  }
  
    this.userService
      .updateGroupOrder(obj)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          
          this.getSubscriptionList(this.page);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
    
  }

  viewSubscription = (data: any) => {
    this.groupDetails = data;
    this.idToShow = data._id;
    this.getGroupDetail();
  }

  getGroupDetail = () => {
    this.subscriptionForm = this.formbuilder.group({
      groupName: [this.groupDetails.groupName, Validators.required],
      type: [this.groupDetails.type, Validators.required],
      groupOrder: [this.groupDetails.groupOrder, Validators.required],
      groupType: [this.groupDetails.groupType, Validators.required],
      verticalViewCount: [this.groupDetails.verticalViewCount, Validators.required],
      groupMaximumCount: [this.groupDetails.groupMaximumCount, Validators.required],
      description: [this.groupDetails.description, Validators.required]
    })
  }

  updateformControl = () => {
    this.subscriptionForm = this.formbuilder.group({
      groupName: ['', Validators.required],
      type: ['', Validators.required],
      groupOrder: ['', Validators.required],
      groupType: ['', Validators.required],
      verticalViewCount: ['', Validators.required],
      description: ['', Validators.required],
      groupMaximumCount: ['', Validators.required]
    })
  }

  updateGroupInfo = () => {
    this.submitted = true;
    this.loading = true;
    this.userService
      .updateGroup(this.subscriptionForm.value, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Group Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.getSubscriptionList(this.page);
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

}
