<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Home Creation & Notification (content creation)
                        
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * tableSize+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * tableSize+1) + (tutorialList?tutorialList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table width="100%" class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">title</th>
                                    <th class="text-center whitespace-no-wrap">description</th>
                                    <th class="text-center whitespace-no-wrap">hiddenByUsers</th>
                                    <th class="text-center whitespace-no-wrap">action</th>
                                    <th class="text-center whitespace-no-wrap">status</th>
                                    <th class="text-center whitespace-no-wrap">pushNotification</th>
                                    <th class="text-center whitespace-no-wrap">test</th>
                                    <th class="text-center whitespace-no-wrap">notificationCriteria</th>
                                    <th class="text-center whitespace-no-wrap">notificationListFlag</th>
                                    <th class="text-center whitespace-no-wrap">grouped</th>
                                    <th class="text-center whitespace-no-wrap">canClose</th>
                                    <th class="text-center whitespace-no-wrap">contentOrder</th>
                                    <th class="text-center whitespace-no-wrap">postDate</th>
                                    <th class="text-center whitespace-no-wrap">location</th>
                                    <th class="text-center whitespace-no-wrap">notificationTitle</th>
                                    <th class="text-center whitespace-no-wrap">color</th>
                                    <th class="text-center whitespace-no-wrap">notificationLink</th>
                                    <th class="text-center whitespace-no-wrap">notificationDateTime</th>
                                    <th class="text-center whitespace-no-wrap">crystal</th>
                                    <th class="text-center whitespace-no-wrap">store</th>
                                    <th class="text-center whitespace-no-wrap">url</th>

                                    
                                    <th class="text-center whitespace-no-wrap">groupType</th>
                                    <th class="text-center whitespace-no-wrap">notificationSubTitle</th>
                                    <th class="text-center whitespace-no-wrap">link</th>
                                    <th class="text-center whitespace-no-wrap">interestFlag</th>
                                    <th class="text-center whitespace-no-wrap">endDate</th>
                                    <th class="text-center whitespace-no-wrap">distribution</th>
                                    <th class="text-center whitespace-no-wrap">contentOrder</th>
                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let tutorial of tutorialList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }} </h4>
                                    </td>
                                    <td class="text-center">{{tutorial.title}}</td>
                                    <td class="text-center">{{tutorial.description?tutorial.description:''}}</td>
                                    
                                    <td class="text-center">{{tutorial.hiddenByUsers}}</td>
                                    <td class="text-center">{{tutorial.action}}</td>
                                    <td class="text-center">{{tutorial.status}}</td>
                                    <td class="text-center">{{tutorial.pushNotification}}</td>
                                    <td class="text-center">{{tutorial.test}}</td>
                                    <td class="text-center">{{tutorial.notificationCriteria}}</td>
                                    <td class="text-center">{{tutorial.notificationListFlag}}</td>
                                    <td class="text-center">{{tutorial.grouped}}</td>
                                    <td class="text-center">{{tutorial.canClose}}</td>
                                    <td class="text-center">{{tutorial.contentOrder}}</td>
                                    <td class="text-center">{{tutorial.postDate}}</td>
                                    <td class="text-center">{{tutorial.location}}</td>
                                    <td class="text-center">{{tutorial.notificationTitle}}</td>
                                    <td class="text-center">{{tutorial.color}}</td>
                                    <td class="text-center">{{tutorial.notificationLink}}</td>
                                    <td class="text-center">{{tutorial.notificationDateTime}}</td>
                                    <td class="text-center">{{tutorial.crystal}}</td>
                                    <td class="text-center">{{tutorial.store}}</td>
                                    <td class="text-center">{{tutorial.url}}</td>
                                    
                                    <td class="text-center">
                                        <ng-container *ngIf="tutorial.groupType!==undefined">
                                            {{tutorial.groupType._id?tutorial.groupType._id:''}}
                                        </ng-container>

                                    </td>
                                    <td class="text-center">{{tutorial.notificationSubTitle}}</td>
                                    <td class="text-center">{{tutorial.link}}</td>
                                    <td class="text-center">
                                        {{tutorial.interestFlag}}
                                        
                                    </td>
                                    <td class="text-center">{{tutorial.endDate | date: "short"}}</td>
                                    <td class="text-center">{{tutorial.distribution}}</td>
                                    <td class="text-center">
                                        {{tutorial.contentOrder}}
                                       
                                    </td>
                                    <td class="text-center">{{ tutorial.createdAt }}</td>
                                    <td class="text-center">{{ tutorial.updatedAt }}</td>

                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewStore" (click)="viewStore(tutorial)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                           
                                            
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
    
    
</div>

<div class="modal" id="viewStore">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">content creation info</h4>
                  <div class="row">
                    
                      <div class="">
                          <div class="columns">
                            <div class="circle">
                                <div class="pic-upload">
                                    <img [src]="imgUrl" class="w-100 h-100" />
                                </div>
                                
                                <img class="profile-pic" src="{{
                                    selected_image
                                        ? base + '/'+ selected_image
                                        : 'assets/img/user.png'
                                    }}">
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>title</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.title:'' }}
                                </td>
                                <th>description</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.description:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>hiddenByUsers</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.hiddenByUsers:'' }}
                                </td>
                                <th>interestFlag</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.interestFlag:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>action</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.action:'' }}
                                </td>
                                <th>status</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.status:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>pushNotification</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.pushNotification:'' }}
                                </td>
                              
                                <th>test </th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.test :""}}
                                </td>
                            </tr>
                            <tr>
                                <th>notificationCriteria</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.notificationCriteria:'' }}
                                </td>
                                <th>notificationListFlag</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.notificationListFlag:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>grouped</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.grouped:'' }}
                                </td>
                               
                                <th></th>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <th>groupType</th>
                                <td>
                                    <ng-container *ngIf="ethicalSourcingDetail">
                                        
                                        {{ ethicalSourcingDetail.groupType?ethicalSourcingDetail.groupType._id:'' }}
                                    </ng-container>
                                </td>
                                <th>canClose</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.canClose:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>contentOrder</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.contentOrder:'' }}
                                </td>
                                <th>link</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.link:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>distribution</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.distribution:'' }}
                                </td>
                                <th>postDate</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.postDate:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>endDate</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.endDate:'' }}
                                </td>
                                <th>location</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.location:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>notificationTitle</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.notificationTitle:'' }}
                                </td>
                                <th>notificationSubTitle</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.notificationSubTitle:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>color</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.color:'' }}
                                </td>
                                <th>notificationLink</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.notificationLink:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>notificationDateTime</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.notificationDateTime:'' }}
                                </td>
                                <th>crystal</th>
                                <td>
                                  
                                        
                                        {{ethicalSourcingDetail ? ethicalSourcingDetail.crystal:""}}
                               
                                </td>
                            </tr>
                            <tr>
                                <th>store</th>
                                <td>
                                   
                                        {{ ethicalSourcingDetail?ethicalSourcingDetail.store:'' }}
                                   

                                </td>
                                <th>url</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.url:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>createdAt</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="ethicalSourcingDetail!=undefined">
                                    {{ ethicalSourcingDetail?ethicalSourcingDetail.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
    
</div>