<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Api Operations 
                        <span class="float-right">
                                               <button (click)="download()"> Download </button>         
                                                </span>
                    </h3>
                    <br>
                    
                    <div class="row">
                      <div class="col-md-3 ">
          
                        <div class="search-box" style="">
                          <form class="search-form">
                            <input class="form-control" placeholder="Search crystal name here..." type="text" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup)="onSearch($event)">
                            <span class="search-btn">
                              
                            </span>
                          </form>
                        </div>
                      </div>
                      <div class="col-md-9 ">
                        <div class="form-group text-right" style="margin-left: 10px;">
                          <span>
                            Showing 
                            <b>
                              <ng-container *ngIf="list">
                                {{ list.length>0?(currentPages - 1) * tableSize+1:0}}
          
                              </ng-container>
                            </b>
                            -
                            <b>{{ ((currentPages - 1) * tableSize+1) + (list?list.length-1:0)}}</b>
                            of 
                            <b>{{count}}</b>
                             items
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">Image</th>
                                    <th class="text-center whitespace-no-wrap">Crystal Name</th>
                                    <th class="text-center whitespace-no-wrap">Finish Type</th>
                                    <th class="text-center whitespace-no-wrap">Selected</th>
                                    <th class="text-center whitespace-no-wrap">Confidence</th>
                                    <th class="text-center whitespace-no-wrap">AutoId</th>
                                  

                                    <th class="text-center whitespace-no-wrap">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let item of list | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * tableSize + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">
                                        <div class="columns">
                                            <div class="circle">
                                                <div class="pic-upload">
                                                    <img src="{{
                                                        item 
                                                          ? base + '/'+ item.scan_img
                                                          : 'assets/img/user.png'
                                                      }}" class="w-100 h-100" />
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <ng-container *ngFor="let crystalName of item.crystalName.split(' ');index as c">
                                            <ng-container *ngIf="c > 0">
                                                {{ crystalName }}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td class="text-center">{{ item.crystalName.split(" ")['0']	}}
                                    </td>
                                    <td class="text-center">{{ item.selected }}</td>
                                    <td class="text-center">{{ item.confidence }}</td>
                                    <td class="text-center">{{ item.added_by?item.added_by.guId:'' }}</td>
                                 

                                    <td class="text-center">
                                        <a data-toggle="modal" data-target="#viewSubscription" (click)="view(item)"
                                            class="text-succes mr-1">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                        <a (click)="deleteSubscription(item._id)"
                                            class="text-danger mr-1">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                    
                                </tr>
                                <tr class="intro-x" *ngIf="list!=undefined && list.length==0">
                                    <td class="text-center" colspan="10">
                                      No record found
                                    </td>
                                  </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>
<div class="modal" id="viewSubscription">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <h4 class="mb-4">Api Operation Info</h4>
                <div class="row">
                    <div class="">
                        <div class="columns">
                            <div class="circle">
                                <div class="pic-upload">
                                    <img src="{{
                                        reportedPostDetails 
                                          ? base + '/'+ reportedPostDetails.scan_img
                                          : 'assets/img/user.png'
                                      }}" class="w-100 h-100" />
                                </div>
                                
                               
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><label>Crystal Name</label>
                            <p> 
                                <ng-container *ngIf="reportedPostDetails!=undefined">
                                
                                    <ng-container *ngFor="let crystalName of reportedPostDetails.crystalName.split(' ');index as c">
                                        <ng-container *ngIf="c > 0">
                                            {{ crystalName }}
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"><label>Finish Type</label>
                            <p>
                                {{ reportedPostDetails?reportedPostDetails.crystalName.split(" ")['0']:""	}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><label>Selected</label>
                            <p>{{reportedPostDetails ? reportedPostDetails.selected:""}}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"><label>Confidence</label>
                            <p>{{reportedPostDetails ? reportedPostDetails.confidence:""}}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><label>AutoId</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails.added_by?reportedPostDetails.added_by.guId:'' }} 
                                </ng-container>
                            </p>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><label>Created At</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails.createdAt | date:'short' }} 
                                </ng-container>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"><label>Updated At</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails.updatedAt | date:'short' }} 
                                </ng-container>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>