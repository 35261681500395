<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->
<div class="mt-8 row">
    <div class="intro-y h-10 col-md-12 mb-4">
        <div class="card">
            <div class="card-content">
                <h3 class="text-lg mb-0">
                   Add Home Creation
                   
                </h3>
                <br>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="card-body">
                            <form  class="custom-mt-form" [formGroup]="form" (ngSubmit)="submitForm()">
                                <div class="row m-0">
                                    
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Group Type</label>
                                            
                                            <select class="form-control" formControlName="groupType" (change)="changeGroup($event)">
                                                
                                                <option value="">Select</option>
                                                <option *ngFor="let item of groupList" [value]="item._id">{{item.groupName}} </option>
                                                
                                            </select>
                                            
                                        </div>
                                        <div
                                            *ngIf="submitted && f.groupType.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.groupType.errors.required">
                                                Please select group type
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="selGroup===1 || selGroup===2 || selGroup===6|| selGroup===4 || selGroup===5" class="col-lg-12 col-md-12 col-12">
                                        <div class="col-lg-6 col-md-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <label class="control-label">Image</label>
                                                    
                                            </div>
                                            <div class="col-lg-2">
    
                                                
                                                    <div class="columns">
    
                                                    <div class="circle">
                                                        <div class="pic-upload">
                                                            <img [src]="imgUrl" class="w-100 h-100" />
                                                        </div>
                                                   
                                                        <img class="profile-pic" src="{{
                                                            selected_image
                                                                ? base + '/'+ selected_image
                                                                : 'assets/img/user.png'
                                                            }}">
                                                    </div>
                                                    <div class="p-image">
                                                        <label for="fileupload">
                                                            <i class="fa fa-camera upload-button"></i>
                                                        </label>
                                                        <input id="fileupload" class="file-upload" type="file"
                                                            (change)="onFileChange($event)" accept="image/*">
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="submitted && imageToUpload==null"
                                                class="invalid-feedback"
                                            >
                                                <div *ngIf="imageToUpload==null">
                                                    Please select a image
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>

                                    <div *ngIf="selGroup===1 || selGroup===2 || selGroup===6|| selGroup===4 || selGroup===5 || selGroup===7 || selGroup===3" class="col-lg-12 col-md-12 col-12">
                                       
                                        <div class="col-lg-6 col-md-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <label class="control-label">Logo Image</label>
                                                    
                                            </div>
                                            <div class="col-lg-2">
    
                                                
                                                    <div class="columns">
    
                                                    <div class="circle">
                                                        <div class="pic-upload">
                                                            <img [src]="logoImgUrl" class="w-100 h-100" />
                                                        </div>
                                                   
                                                        <img class="profile-pic" src="{{
                                                            selected_image
                                                                ? base + '/'+ selected_image
                                                                : 'assets/img/user.png'
                                                            }}">
                                                    </div>
                                                    <div class="p-image">
                                                        <label for="fileuploadd">
                                                            <i class="fa fa-camera upload-button"></i>
                                                        </label>
                                                        <input id="fileuploadd" class="file-upload" type="file"
                                                            (change)="onFileChangeLogoImage($event)" accept="image/*">
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="submitted && logoImageToUpload==null"
                                                class="invalid-feedback"
                                            >
                                                <!-- <div *ngIf="logoImageToUpload==null">
                                                    Please select a logo image
                                                </div> -->
                                            </div>
                                        </div>
                                        
                                    </div>
                                 
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Can Close</label>
                                            
                                            <select class="form-control" formControlName="canClose">
                                                <option [value]="true">True</option>
                                                <option [value]="false">False</option>
                                            </select>
                                        </div>
                                    </div> 
                                    
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Title</label>
                                            <input type="text" class="form-control" formControlName="title"
                                                placeholder="Enter Title">
                                        </div>
                                        <div
                                            *ngIf="submitted && f.title.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.title.errors.required">
                                                Please enter title
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="selGroup===1 || selGroup===2 || selGroup===6|| selGroup===4 || selGroup===5" class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Description</label>
                                            <input  type="text" class="form-control" formControlName="description"
                                                placeholder="Enter Description">
                                        </div>
                                      
                                    </div>
                                    <div *ngIf="selGroup===3" class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Crystal</label>
                                            
                                           
                                            <ng-select2 
                                                [(ngModel)]="crystal"
                                                [ngModelOptions]="{standalone: true}"
                                                [data]="selectToCrystal"
                                                [placeholder]="'Please select a crystal...'">		
                                            </ng-select2>
                                        </div>
                                        <div
                                            *ngIf="submitted && crystal==''"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf=" crystal==''">
                                                Please select crystal
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                    <div *ngIf="selGroup===7" class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Store</label>
                                            
                                            
                                            <ng-select2 
                                                [(ngModel)]="store"
                                                [ngModelOptions]="{standalone: true}"
                                                [data]="selectToStore"
                                                [placeholder]="'Please select a store...'">		
                                            </ng-select2>

                                        </div>
                                        <div
                                            *ngIf="submitted && store==''"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="store==''">
                                                Please select store
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Interest Flag</label>
                                            
                                            <select class="form-control" formControlName="interestFlag">
                                                <option [value]="true">True</option>
                                                <option [value]="false">False</option>
                                            </select>
                                            
                                            
                                        </div>
                                    </div> 
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Content Order</label>
                                            <input  type="text" class="form-control" formControlName="contentOrder"
                                                placeholder="Enter Content Order" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                                        </div>
                                        <div
                                            *ngIf="submitted && f.contentOrder.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.contentOrder.errors.required">
                                                Please enter content order
                                            </div>
                                            <div *ngIf="f.contentOrder.errors.pattern">
                                                Please enter number
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Distribution</label>
                                            
                                                <select class="form-control" formControlName="distribution">
                                                    <option value="">Select</option>
                                                  
                                                    <option value="All">All</option>
                                                   
                                                    
                                                </select>
                                        </div>
                                        <div
                                            *ngIf="submitted && f.distribution.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.distribution.errors.required">
                                                Please enter distribution
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div  class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Post Date</label>
                                            <input  type="date" class="form-control" formControlName="postDate"
                                                placeholder="Post Date">
                                        </div>
                                        <div
                                            *ngIf="submitted && f.postDate.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.postDate.errors.required">
                                                Please enter post date
                                            </div>
                                        </div>
                                    </div>
                                    <div  class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">End Date</label>
                                            <input  type="date" class="form-control" formControlName="endDate"
                                                placeholder="End Date">
                                        </div>
                                        <div
                                            *ngIf="submitted && f.endDate.errors; else elseBlock"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.endDate.errors.required">
                                                Please enter end date
                                            </div>
                                        </div>
                                         <ng-template #elseBlock>
                                        <div
                                            class="invalid-feedback"
                                            
                                        > 
                                            <div *ngIf="submitted && f.endDate.value<f.postDate.value">
                                                End date should be greater than post date
                                            </div>
                                        </div>
                                        </ng-template>
                                    </div>

                                    <div  class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Date</label>
                                            <input  type="date" class="form-control" formControlName="notificationDate"
                                                placeholder="Notification Date">
                                        </div>
                                        <div
                                            *ngIf="submitted && f.notificationDate.errors; else elseBlock"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.notificationDate.errors.required">
                                                Please enter Notification Date
                                            </div>
                                        </div>
                                         
                                    </div>
                                    
                                    
                                    
                                    <div  class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Link</label>
                                            <input  type="text" class="form-control" formControlName="link"
                                            placeholder="Link">
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Sub Title</label>
                                            <input  type="text" class="form-control" formControlName="notificationSubTitle"
                                            placeholder="Notification Sub Title">
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Title</label>
                                            <input  type="text" class="form-control" formControlName="notificationTitle"
                                            placeholder="Notification Title">
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Link</label>
                                            <input  type="text" class="form-control" formControlName="notificationLink"
                                            placeholder="Notification Link">
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Color</label>
                                            <input  type="text" class="form-control" formControlName="color"
                                            placeholder="Notification Color">
                                        </div>
                                        
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Push Notification </label>
                                            
                                            <select class="form-control" formControlName="pushNotification">
                                                <option [value]="true">True</option>
                                                <option [value]="false">False</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Status</label>
                                            
                                            <select class="form-control" formControlName="notificationStatus">
                                                <option value="None">None</option>
                                                <option value="Send">Send</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Sent">Sent</option>
                                            </select>
                                        </div>
                                    </div>




                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Location</label>
                                            
                                            <select class="form-control" formControlName="location">
                                                
                                                <option value="">Select</option>
                                                <option *ngFor="let item of locationList" value="{{item.page}}">{{item.page}}</option>
                                            </select>
                                        </div>
                                        <div
                                            *ngIf="submitted && f.location.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.location.errors.required">
                                                Please select location
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Action</label>
                                            
                                            <select class="form-control" formControlName="action">
                                                <option value="New">New</option>
                                                <option value="Edit">Edit</option>
                                                <option value="Delete">Delete</option>
                                            </select>
                                            
                                        </div>
                                    </div> 
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Test</label>
                                            
                                            <select class="form-control" formControlName="test">
                                                <option [value]="true">True</option>
                                                <option [value]="false">False</option>
                                            </select>
                                            
                                            
                                        </div>
                                    </div> 
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Status</label>
                                            
                                            <select class="form-control" formControlName="status">
                                                <option value="Draft">Draft</option>
                                                <option value="Sent">Sent</option>
                                            </select>
                                            
                                            
                                        </div>
                                    </div> 
                                    
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group custom-mt-form-group">
                                        <button class="btn btn-success mr-2" type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
