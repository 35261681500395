import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import {NotificationsService} from "../../../services/notifications.service";
import { SessionService } from "../../../services/session.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  loginForm!: FormGroup;
  submitted = false;
  token: any
  
  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private router: Router,private route: ActivatedRoute,private sessionService: SessionService,private notify:NotificationsService) {
   }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get("token")
    this.loginControls()
  }

  loginControls = () => {
    this.loginForm = this.formBuilder.group({
      
      newPassword: ["", [Validators.minLength(6), Validators.required]],
      confirmNewPassword: ["", [Validators.minLength(6), Validators.required]],
      
    });    
  };
  
  get f() {
    return this.loginForm.controls;
  }

  submitForm = ()=>{
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    
    this.userService.setPassword(this.loginForm.value, this.token).toPromise().then((resp)=>{
      if(resp.success ){

        this.notify.showSuccess('Password set successfully');
	  		this.router.navigate(['/login']);
        
      }else{
        this.notify.showError("you are not authorized")
        this.router.navigate(['/login']);
      }
    }); 
  }

}
