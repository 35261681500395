<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Constant config
                        
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * tableSize+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * tableSize+1) + (tutorialList?tutorialList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">title</th>
                                    <th class="text-center whitespace-no-wrap">label</th>
                                    <th class="text-center whitespace-no-wrap">description</th>
                                    <th class="text-center whitespace-no-wrap">location</th>
                                    <th class="text-center whitespace-no-wrap">status</th>
                                    <th class="text-center whitespace-no-wrap">type</th>
                                    <th class="text-center whitespace-no-wrap">image</th>
                                    <th class="text-center whitespace-no-wrap">subHeading</th>
                                    <th class="text-center whitespace-no-wrap">englishValue</th>

                                    <th class="text-center whitespace-no-wrap">webAdmin</th>
                                    <th class="text-center whitespace-no-wrap">viewOrder</th>
                                    <th class="text-center whitespace-no-wrap">system</th>
                                    <th class="text-center whitespace-no-wrap">default</th>
                                    <th class="text-center whitespace-no-wrap">UX</th>
                                    <th class="text-center whitespace-no-wrap">local</th>
                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>
                                    <th width="10%" class="text-center whitespace-no-wrap">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let tutorial of tutorialList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{tutorial.title}}</td>
                                    <td class="text-center">{{tutorial.label}}</td>
                                    <td class="text-center" [innerHTML]="tutorial.description"></td>
                                    <td class="text-center">{{tutorial.location}}</td>

                                    <td class="text-center">{{tutorial.status}}</td>
                                    <td class="text-center">{{tutorial.type}}</td>
                                    <td class="text-center">{{tutorial.image}}</td>
                                    <td class="text-center">{{tutorial.subHeading}}</td>
                                    <td class="text-center">{{tutorial.englishValue}}</td>

                                    <td class="text-center">{{tutorial.webAdmin}}</td>
                                    <td class="text-center">{{tutorial.viewOrder}}</td>
                                    <td class="text-center">{{tutorial.system}}</td>
                                    <td class="text-center">{{tutorial.default}}</td>
                                    <td class="text-center">{{tutorial.UX}}</td>
                                    <td class="text-center">{{tutorial.local}}</td>
                                    <td class="text-center">{{ tutorial.createdAt }}</td>
                                    <td class="text-center">{{ tutorial.updatedAt }}</td>
                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewStore" (click)="viewStore(tutorial)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                            
                                            
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteStore" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 class="modal-title">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this data?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewStore">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">constant config info</h4>
                 
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>title</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.title:'' }}
                                </td>
                                <th>label</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.label:""}}
                                </td>
                               
                            </tr>
                            
                            <tr>
                                <th>description</th>
                                <td>
                                    <ng-container *ngIf="ethicalSourcingDetail">
                                        <div [innerHTML]="ethicalSourcingDetail.description"></div>
                                    </ng-container>
                                </td>
                                <th>location</th>
                                <td>
                                    {{ethicalSourcingDetail ? ethicalSourcingDetail.location:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>webAdmin</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.webAdmin:'' }}
                                </td>
                                <th>viewOrder</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.viewOrder:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>system</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.system:'' }}
                                </td>
                                <th>default</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.default:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>UX</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.UX:'' }}
                                </td>
                                <th>local</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.local:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>status</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.status:'' }}
                                </td>
                                <th>type</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.type:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>image</th>
                                <td>{{ ethicalSourcingDetail?ethicalSourcingDetail.image:'' }}
                                </td>
                                <th>subHeading</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.subHeading:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>englishValue</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.englishValue:""}}
                                </td>
                                <th></th>
                                <td></td>
                            </tr>
                            <tr>
                                <th>createdAt</th>
                                <td>{{ethicalSourcingDetail ? ethicalSourcingDetail.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="ethicalSourcingDetail!=undefined">
                                    {{ ethicalSourcingDetail?ethicalSourcingDetail.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
    
</div>