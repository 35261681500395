/*@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUrl } from "../../../utils/config";

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
    adminDetail:any;
    updateUser !: FormGroup;
    imageSrc !: string;
    file !: File;
    submitted = false;
    loading = false;
    filesToUpload:any
    imgUrl !: String
    imageToUpload: any;
    base: any = ApiUrl;

    constructor(private userService: UserService, private formbuilder:FormBuilder, private router:Router,private activateRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.getAdminDetail();
        this.updatefromConntorl()
    }
    // getDate

    get currentDate(){
    var today = new Date();
    return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  
    }

    updatefromConntorl=()=>{
        this.updateUser=this.formbuilder.group({
            firstName:['', Validators.required],
            lastName:['', Validators.required],
            email:['',Validators.required],
            tob:['', Validators.required],
            dob:['',Validators.required],
            state:['',Validators.required],
            city:['',Validators.required],
            country:['',Validators.required],

        })
    }
    getAdminDetail=()=>{
        this.userService.viewAdminDetail().toPromise().then((resp)=>{
            if(resp.success==true){
                this.adminDetail= resp.data;
                this.updateUser=this.formbuilder.group({
                    firstName:[this.adminDetail.firstName, Validators.required],
                    lastName:[this.adminDetail.lastName, Validators.required],
                    email:[this.adminDetail.email,Validators.required],
                    tob:[this.adminDetail.tob, Validators.required],
                    dob:[this.adminDetail.dob,Validators.required],
                    state:[this.adminDetail.state,Validators.required],
                    city:[this.adminDetail.city,Validators.required],
                    country:[this.adminDetail.country,Validators.required],
                })
                let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
                modalToClose.click();
            }
        }).catch(err=>{
            Swal.fire(
                (err.message),
            )
        })
    }
    get f() {
        return this.updateUser.controls;
    }

  

    onFileChange = (fileInput: any) => {
        this.imageToUpload = <Array<File>>fileInput.target.files;
        
        var reader = new FileReader();
        reader.readAsDataURL(this.imageToUpload[0]);
        reader.onload = (e:any) => {
          this.imgUrl = e.target.result;
        };
    };

    updateProfile=()=>{
        this.submitted = true;
        
        const formData = new FormData();
        if(this.imageToUpload && this.imageToUpload[0]){
            formData.append('image',this.imageToUpload[0]);
        }
        for (let key in this.updateUser.value) {
            if(this.updateUser.value[key] !== null){
                formData.append(key, this.updateUser.value[key]);
            }
        }
    
        this.file = this.filesToUpload;
        this.loading = true;
        this.userService
        .editAdmin(formData)
        .toPromise()
        .then((resp) => {
            if (resp.success) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Profile Updated Successfully',
                    showConfirmButton: false,
                    timer: 1500
                  })

          this.getAdminDetail()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        })
        .catch((err) => { 
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        });
    }

}
