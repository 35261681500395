<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="addSubscriptionForm" (ngSubmit)="addSubscription()"
                                        class="custom-mt-form">

                                        <div class="row m-0">
                                          

                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Label</label>
                                                    <input formControlName="label" type="text"
                                                        class="form-control" placeholder="Enter label">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.label.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.label.errors.required">
                                                    Please enter label
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">English Value</label>
                                                    <input formControlName="englishValue" type="text"
                                                        class="form-control" placeholder="Enter English Value">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.englishValue.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.englishValue.errors.required">
                                                    Please enter english value
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Type</label>
                                                   
                                                        <select class="form-control" formControlName="type">
                                                            <option value="Text">
                                                                Text
                                                            </option>
                                                            <option value="Image">
                                                                Image
                                                            </option>
                                                        </select>
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.type.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.type.errors.required">
                                                    Please enter type 
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Location</label>
                                                    <select class="form-control" formControlName="location">
                                                        <option value="">Select</option>
                                                        <option *ngFor="let pages of locationList" value="{{pages.page}}">{{pages.page}}</option>
                                                    </select>
                                                    
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.location.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.location.errors.required">
                                                    Please select location
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label>
                                                    
                                                    <input type="text" class="form-control" formControlName="description" placeholder="Enter Description"/>
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.description.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.description.errors.required">
                                                    Please enter description
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Web Admin</label>
                                                    <select class="form-control" formControlName="webAdmin">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">View Order</label>
                                                    <input type="text" formControlName="viewOrder"
                                                        class="form-control" placeholder="View Order">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.viewOrder.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.viewOrder.errors.required">
                                                    Please enter view order
                                                    </div>
                                                    <div *ngIf="f.viewOrder.errors.pattern">
                                                    Please enter valid number
                                                    </div>
                                                        
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">System	</label>
                                                    <select class="form-control" formControlName="system">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Default</label>
                                                    <select class="form-control" formControlName="default">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">UX</label>
                                                    <select class="form-control" formControlName="UX">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Local</label>
                                                    <select class="form-control" formControlName="local">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
