/* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.*/

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service'

declare var $: any
declare var Chart: any

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    totalRecord: any;

    constructor(private userService:UserService) { }

    ngOnInit(): void {
        this.getDashboardData()
        this.getChart()
    }

    getDashboardData = () => {
        this.userService
          .getAdminDashboard()
          .toPromise()
          .then((resp) => {
            if (resp.success) {
              this.totalRecord = resp.data;
            }
        });
    };
    getChart(){

      var d1 = [[0,30],[1,35],[2,35],[3,30],[4,30],[5,35],[6,32],[7,37],[8,30],[9,35],[10,30],[11,31]];
      var d2 = [[0,50],[1,40],[2,45],[3,60],[4,50],[5,50],[6,60],[7,55],[8,50],[9,50],[10,60],[11,35]];
      var d3 = [[0,40],[1,50],[2,35],[3,25],[4,40],[5,45],[6,55],[7,50],[8,35],[9,40],[10,48],[11,40]];
      var options = {
        series: {
            shadowSize: 0,
            lines: {
                show: true,
            },
        },
        grid: {
            borderWidth: 1,
            labelMargin:10,
			      mouseActiveRadius:6,
            borderColor: '#eee',
            show : true,
            hoverable : true,
            clickable : true
            
        },
        xaxis: {
            tickColor: '#eee',
            tickDecimals: 0,
            font :{
                lineHeight: 15,
                style: "normal",
                color: "#000"
            },
            shadowSize: 0,
            ticks: [[0,"Jan"], [1,"Feb"], [2,"Mar"], [3,"Apr"], [4,"May"], [5,"Jun"], [6,"Jul"], [7,"Aug"], [8,"Sep"], [9,"Oct"], [10,"Nov"], [11,"Dec"]]
        },
        
        yaxis: {
            tickColor: '#eee',
            tickDecimals: 0,
            font :{
                lineHeight: 15,
                style: "normal",
                color: "#000",
            },
            shadowSize: 0
        },
        
        legend: {
            container: '.flc-line',
            backgroundOpacity: 0.5,
            noColumns: 0,
            backgroundColor: "white",
            lineWidth: 0
        },
        colors: ["#6baa01", "#33bdda", "#008ee4"]
      };
      if ($("#line-chart")[0]) {
      $.plot($("#line-chart"), [
        {data: d1, lines: { show: true  }, label: 'Product A', stack: true, color: '#F9D900' },
              {data: d2, lines: { show: true }, label: 'Product B', stack: true, color: '#A9E200' },
              {data: d3, lines: { show: true }, label: 'Product C', stack: true, color: '#22BAD9' }
          ], options);
        }

      if($('#doughnutChart')[0]){
        
        var doughnutChartCanvas = $("#doughnutChart").get(0).getContext("2d");
        
        var config = {
          type: 'doughnut',
          data: {
            datasets: [{
              data: [
                11,
                16,
                7,
                3,
                14
              ],
              backgroundColor: [
                "#FF6384",
                "#4BC0C0",
                "#FFCE56",
                "#E7E9ED",
                "#36A2EB"
              ],
              label: 'My dataset' // for legend
    
              
            }],
            labels: [
              "USA",
              "Germany",
              "Austalia",
              "Canada",
              "France"
            ]
          },
          options: {
            responsive: true,
            legend: {
              display: false
            }
          }
        };
        
         var myDoughnutChart = new Chart(doughnutChartCanvas, config);
    
      }	
    }

}
