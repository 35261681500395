import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUrl } from "../../../../utils/config";

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.css']
})
export class AddCreditComponent implements OnInit {
  addSubscriptionForm !: FormGroup;
    submitted = false;
    loading = false;
    base: any = ApiUrl;
    creditCode:any
    allUser:any
    creditTo:any=''
    selectToData:any = []
    subscriptionList:any = []
    page:any=1

    constructor(private userService: UserService, private formbuilder:FormBuilder, private router:Router,private activateRoute: ActivatedRoute) { }

    ngOnInit(): void {
      this.addSubscriptionControler()
      this.getAllUser()
      
      this.getSubscriptionList(this.page);
  }

  getSubscriptionList = (page: any) => {
    this.userService.getSubscriptionLists(page).toPromise().then(response => {
      if (response.success) {
        this.subscriptionList = response.data.result;
      }
    });
  }

  get f() {
      return this.addSubscriptionForm.controls;
  }

  addSubscriptionControler = () => {
      this.addSubscriptionForm = this.formbuilder.group({
        creditId:['',Validators.required],
        creditTo:[''],
        durationType:['weekly',Validators.required],
        quantity:['',[Validators.required,Validators.pattern(/^[0-9.,]+$/)]],
        subscriptionId:['',Validators.required],
      })
  }
  

  getCreditCode(evt:any){
    evt.preventDefault()
    this.userService.getCreditCode().toPromise()
      .then((resp) => {
        if (resp.success) {
          this.creditCode= resp.data
          this.addSubscriptionForm.controls['creditId'].setValue(this.creditCode);
        }
      })
  }
  getAllUser(){
    
    this.userService.getAllUser().toPromise()
      .then((resp) => {
        if (resp.success) {
          this.allUser = resp.data

          var arr = [];
          for(let item of resp.data) {
            if(item.email){
                let name;
                let email;
                let guid;

                name = item.firstName+' '+item.lastName
                if(item.email!='' && item.email!=null){
                    email=' ('+item.email+') ';
                }
                if(item.guId!='' && item.guId!=null){
                    guid = '- ' +item.guId;
                }

                var obj =  {
                    id: item._id,
                    text: item.email+item.guId
                }
                arr.push(obj);

            }
            
          }
          this.selectToData = arr;
        }
      })
  }
  addSubscription = () => {
      this.submitted = true;
      const formData = new FormData;
      
      
      if (this.addSubscriptionForm.invalid || this.creditTo=='') {
          return;
      }

      var obj = {
        creditId: this.addSubscriptionForm.value['creditId'],
        creditTo: this.creditTo,
        durationType: this.addSubscriptionForm.value['durationType'],
        quantity: this.addSubscriptionForm.value['quantity'],
        subscriptionId: this.addSubscriptionForm.value['subscriptionId'],
      }
      
      this.loading = true;
      this.userService.addCredit(obj).toPromise()
      .then((resp) => {
          if (resp.success) {
              Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Credit Added Successfully',
                  showConfirmButton: false,
                  timer: 1500
              })
              this.router.navigate(["/admin/creditManagement"]);
          } else {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
              })
          }
      })
      .catch((err) => {
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
          })
      });
  }

}
