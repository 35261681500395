<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">

                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="addStoreForm" (ngSubmit)="addStore()" class="custom-mt-form">
                                     
                                        <div class="d-flex flex-wrap">
                                            <div class="">
												<h4 class="text-center"> Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="imageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploadss">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploadss" class="file-upload"
															(change)="onImageChangeRaw($event,'image')" type="file"
															accept="image/*">
													</div>
												</div>
												
											</div>
											<div class="ml-4">
												<h4 class="text-center"> Thumbnail Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="thumbnail_imageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploads2">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploads2" class="file-upload"
															(change)="onImageChangeRaw($event,'thumbnail_image')" type="file"
															accept="image/*">
													</div>
												</div>
												
											</div>
                                        </div>
                                        
                                       
                                        <div class="row m-0">
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Shop Name</label>
                                                    <input formControlName="shopName" type="text" class="form-control"
                                                        placeholder="Enter Shop Name">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Website</label>
                                                    <input formControlName="website" type="text" class="form-control">
                                                </div>
                                               
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">First Name</label>
                                                    <input formControlName="firstName" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Last Name</label>
                                                    <input formControlName="lastName" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Title</label>
                                                    <input formControlName="title" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Email</label>
                                                    <input formControlName="email" type="text" class="form-control">
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label> <input
                                                        formControlName="description" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Mobile Number</label> <input
                                                        formControlName="mobileNumber" type="text" class="form-control">
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Speciality</label>
                                                    <input formControlName="speciality" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>


                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Ethical Ranking</label> <input
                                                        formControlName="ethicalRanking" type="number"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Quality Ranking</label> <input
                                                        formControlName="qualityRanking" type="number"
                                                        class="form-control">
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Experience Ranking</label> <input
                                                        formControlName="experienceRanking" type="number"
                                                        class="form-control">
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Address</label> 
                                                        <input
                                                        formControlName="address" type="text" class="form-control" id="address" (change)="changeAddress($event)">
                                                </div>
                                            </div>


                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">City</label> <input
                                                        formControlName="city" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">State</label> <input
                                                        formControlName="state" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Zip</label> <input
                                                        formControlName="zip" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Country</label> <input
                                                        formControlName="country" type="text" class="form-control">
                                                </div>
                                            </div>


                                            
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Brief Description</label> <input
                                                        formControlName="briefDescription" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Ecommerce Link</label> <input
                                                        formControlName="ecommerceLink" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Facebook</label> <input
                                                        formControlName="facebook" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Instagram</label> <input
                                                        formControlName="instagram" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Twitter</label> <input
                                                        formControlName="twitter" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">EW Partner</label> 
                                                    
                                                    <select formControlName="EW_Partner" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Investigated</label> 
                                                    
                                                    <select formControlName="investigated" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Active</label> 
                                                    
                                                    <select formControlName="active" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Online Store</label> 
                                                    
                                                    <select formControlName="onlineStore" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Shop</label> 
                                                    
                                                    <select formControlName="shop" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Partner</label> 
                                                    
                                                    <select formControlName="partner" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Service</label> 
                                                    
                                                    <select formControlName="service" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>