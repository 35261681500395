<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
	<div class="mt-8 row">
		<div class="intro-y h-10 col-md-12 mb-4">
			<div class="card">
				<div class="card-content">
					<div class="row">
						<div class="col-lg-12 ">
							<h3 class="text-lg mb-0 mt-0">
								Subscriptions (subscription table)
                               
							</h3>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3 "></div>
						<div class="col-md-9 ">
						  <div class="form-group text-right showing_line" >
							<span>
							  Showing 
							  <b>
								  {{ (currentPages - 1) * postsPerPage+1}}</b>
							  -
							  <b>{{ ((currentPages - 1) * postsPerPage+1) + (subscriptionList?subscriptionList.length-1:0)}}</b>
							  of 
							  <b>{{count}}</b>
							   items
							</span>
						  </div>
						</div>
					  </div>
					<div class="table-responsive">
						<table width="100%" class="table table-bordered table-striped table-crystal">
							<thead>
								<th>Sr No.</th>
								<th>Plan Name</th>
								<th>Plan Cost</th>
								<th>Scan Quantity</th>
								<th>Collection Quantity</th>
								<th>Duration Type</th>
								<th>Apple Product ID</th>
								<th>Google Product ID</th>
								<th>Plan Label</th>
								<th>Saving Label</th>
								<th>Backup</th>
								<th>Is Default</th>
								<th>Description</th>
							</thead>
							<tbody>
								<tr *ngFor="let subscription of subscriptionList | paginate : {
								itemsPerPage: tableSize,
								currentPage: page,
								totalItems: count
							  }; index as i">

									<td>{{ (currentPages - 1) * postsPerPage + i + 1 }}</td>
									<td>{{subscription.planName}}</td>
									<td>{{subscription.planCost}}</td>
									<td>{{subscription.scanQuantity}}</td>
									<td>{{subscription.collectionQuantity}}</td>
									<td>{{subscription.durationType}}</td>
									<td>{{subscription.appleProductId}}</td>
									<td>{{subscription.googleProductId}}</td>
									<td>{{subscription.planLabel ? subscription.planLabel : ''}}</td>
									<td>{{subscription.savingLabel ? subscription.savingLabel : ''}}</td>
									<td>{{subscription.backup ? 'True' : 'False'}}</td>
									<td>{{subscription.isDefault ? 'True' : 'False'}}</td>
									<td>{{subscription.description}}</td>

									
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="d-flex justify-content-center">
					<pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
					</pagination-controls>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade" id="deleteSubscription" role="dialog">
		<div class="modal-dialog">
			
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal">
						&times;
					</button>
					<h4 class="modal-title">Delete</h4>
				</div>
				<div class="modal-body">
					<p>Are you sure you want to delete this data?</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-default" data-dismiss="modal">
						Close
					</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">
						Ok
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="viewSubscription">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal">&times;</button>
				</div>
				<div class="card">
					<div class="card-content">
						<div class="row">

							<div class="col-lg-12 ">
								<h3 class="text-lg mb-0">
									Subscription Info
								</h3>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-lg-12 col-md-12 col-sm-12 col-12">
								<div class="card">
									<div class="card-body">
										<form [formGroup]="subscriptionForm" (ngSubmit)="updateSubscription()"
											class="custom-mt-form">

											<div class="row m-0">
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Plan Name</label>
														<input formControlName="planName" type="text"
															class="form-control" placeholder="Enter Plan Name">
													</div>
													<div
														*ngIf="submitted && f.planName.errors"
														class="invalid-feedback"
													>
														<div *ngIf="f.planName.errors.required">
														Please enter plan name
														</div>
													</div>
												</div>
											
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Plan Cost</label>
														<input formControlName="planCost" type="text"
															class="form-control" placeholder="Enter Plan Cost">
													</div>
													<div
														*ngIf="submitted && f.planCost.errors"
														class="invalid-feedback"
													>
														<div *ngIf="f.planCost.errors.required">
														Please enter plan cost
														</div>
														<div *ngIf="f.planCost.errors.pattern">
														Please enter valid cost
														</div>
													</div>
												</div>
												
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Scan Quantity</label>
														<input formControlName="scanQuantity" type="text" 
															class="form-control"  placeholder="Quantity">
													</div>
													 <div
														*ngIf="submitted && f.scanQuantity.errors"
														class="invalid-feedback"
													>
														<div *ngIf="f.scanQuantity.errors.required">
															Please enter quantity
														</div>
														<div *ngIf="f.scanQuantity.errors.pattern">
															Please enter valid scan quantity
														</div>
															
													</div>
	
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Collection Quantity</label>
														<input formControlName="collectionQuantity" type="text" 
															class="form-control"  placeholder="Quantity">
													</div>
													 <div
														*ngIf="submitted && f.collectionQuantity.errors"
														class="invalid-feedback"
													>
														<div *ngIf="f.collectionQuantity.errors.required">
															Please enter quantity
														</div>
														<div *ngIf="f.collectionQuantity.errors.pattern">
															Please enter valid collection quantity
														</div>
															
													</div>
	
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Duration Type</label> <select
															class="form-control" formControlName="durationType">
															<option>Monthly</option>
															<option>Yearly</option>
														</select>
													</div>
													 <div
														*ngIf="submitted && f.durationType.errors"
														class="invalid-feedback"
													>
														<div *ngIf="f.durationType.errors.required">
														Please select duration type
														</div>
															
													</div>
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Description</label> 
														<input formControlName="description" type="text"
															class="form-control"  placeholder="Description">
													</div>
													<div
														 *ngIf="submitted && f.description.errors" 
														class="invalid-feedback"
													>
														<div *ngIf="f.description.errors.required">
														Please enter duration type
														</div>
															
													</div> 
												</div>
												
											</div>
											<div class="col-lg-12 col-md-12 col-sm-12 col-12">
												<div class="form-group custom-mt-form-group">
													<button class="btn btn-success mr-2" type="submit">Submit</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>