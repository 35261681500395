<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        All App Tutorial
                        <span class="float-right">
                            <a class="btn btn-primary" [routerLink]="['/admin/addAppTutorial']">Add App Tutorial</a>
                        </span>
                    </h3>
                    <br>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">Title</th>
                                    <th class="text-center whitespace-no-wrap">Heading</th>
                                    <th class="text-center whitespace-no-wrap">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let tutorial of tutorialList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{tutorial.title}}</td>
                                    <td class="text-center">{{tutorial.heading}}</td>
                                 
                                    <td class="text-center" [innerHTML]="tutorial.description"></td>
                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewStore" (click)="viewStore(tutorial)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                            <a class="flex items-center text-theme-6 text-danger"
                                                (click)="deleteStore(tutorial._id)" href="javascript:void(0);">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-trash-2 w-4 h-4 mr-1">
                                                    <polyline points="3 6 5 6 21 6"></polyline>
                                                    <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                    </path>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                </svg>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteStore" role="dialog">
        <div class="modal-dialog">
         
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 class="modal-title">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this data?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewStore">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                App Tutorial Info
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="updateUser" (ngSubmit)="updateProfile()" class="custom-mt-form">
                                        <div class="row m-0">
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Title</label>
                                                    <input formControlName="title" type="text" class="form-control"
                                                        placeholder="Enter Title">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Heading</label>
                                                    <input formControlName="heading" type="text" class="form-control"
                                                        placeholder="Enter Heading">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Location</label>
                                                    <select formControlName="location" class="form-control">
                                                        <option value="">Select</option>
                                                        <option *ngFor="let pages of locationList" value="{{pages.page}}">{{pages.page}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label>
                                                    <editor formControlName="description"
                                                    ></editor>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>