import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { Router } from "@angular/router";
import {NotificationsService} from "../../../services/notifications.service";
import { SessionService } from "../../../services/session.service";


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  loginForm!: FormGroup;
  submitted = false;
  
  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private router: Router,private sessionService: SessionService,private notify:NotificationsService) {
   }

  ngOnInit(): void {
    this.loginControls()


  }

  loginControls = () => {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      
    });    
  };
  
  get f() {
    return this.loginForm.controls;
  }

  submitForm = ()=>{
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    
    this.userService.forgotPassword(this.loginForm.value).toPromise().then((resp)=>{
      if(resp.success ){
        this.notify.showSuccess("Email sent successfully to your registered email address")
        this.router.navigate(['/login']);
      }else{
        this.notify.showError("you are not authorized")
      }
    }); 
  }

}
