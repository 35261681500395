import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError, retry } from 'rxjs/operators';
import { ApiUrl } from "../utils/config";

 
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  get token(){
    let tokena;
    var localtok = localStorage.getItem('token');
    var sessiontok = sessionStorage.getItem('token');
    if(localtok!==null){
      tokena = localtok
    }else{
      tokena = sessiontok
    }

    
    let obj={}

    if(tokena==null){
      obj = {
        Authorization: "Bearer "
      }
    }else{
      obj = {
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')|| localStorage.getItem('token') || '')
      }
    }
   
    return obj;
  }
  
  get httpOptions(){
    var httpOptions = {
      headers: new HttpHeaders(this.token)
    }
    return httpOptions;
  }
 
  
  get isLoggedIn(): boolean {
    const  user  =  localStorage.getItem('token') || sessionStorage.getItem('token');

    return  user  !==  null;
  }
  login(data:any): Observable<any> {
    return this.http.post(ApiUrl + "/auth/login",data, this.httpOptions)
  }
  forgotPassword(data:any): Observable<any> {
    return this.http.put(ApiUrl + "/auth/forgotPassword",data, this.httpOptions)
  }
  setPassword(data:any, token:any): Observable<any> {
    return this.http.put(ApiUrl + `/auth/resetPassword/${token}`, data, this.httpOptions)
  }

  getUserLists(page:any):Observable<any>{
    return this.http.get(ApiUrl+"/auth/getUsers?page="+page+"&search=&pageSize=10", this.httpOptions)
  }
  
  getUserListsSearch(page:any,search:any):Observable<any>{
    return this.http.get(ApiUrl+"/auth/getUsers?page="+page+"&search="+search+"&pageSize=10", this.httpOptions)
  }

  updateStatus(data:any):Observable<any>{
    return this.http.post(ApiUrl+"/auth/blockUser",data, this.httpOptions)
  }

  deleteUser(id:any):Observable<any>{
    return this.http.delete(ApiUrl+"/auth/delete/"+id, this.httpOptions)
  }
  viewAdminDetail():Observable<any>{
    return this.http.get(ApiUrl+"/auth/getById", this.httpOptions)
  }
  resetPassword(data:any):Observable<any>{
    return this.http.put(ApiUrl+"/auth/changePassword",data, this.httpOptions)
  }
  editAdmin(data:any):Observable<any>{
    return this.http.put(ApiUrl+ "/auth/editProfile",data, this.httpOptions)
  }
  getCrystalLists(obj:any):Observable<any>{
    return this.http.get(ApiUrl+"/crystal/getCrystals?page="+obj.page+"&pageSize=10"+"&search="+obj.search, this.httpOptions)
  }
  getPageCrystalLists(page:any):Observable<any>{
    return this.http.get(ApiUrl+"/crystal/getCrystals?page="+page+"&pageSize=10", this.httpOptions)
  }
  getAdminDashboard():Observable<any>{
    return this.http.get(ApiUrl+"/auth/dashboardInfo", this.httpOptions)
    .pipe(
      retry(2),
      )
  }

  addStore(data: any): Observable<any> {
    return this.http.post(ApiUrl + `/store/addStore/`, data, this.httpOptions)
  }

  addCrystal(data: any): Observable<any> {
    return this.http.post(ApiUrl + `/crystal/addCrystal/`, data, this.httpOptions)
  }

  deleteCrystal(id: any): Observable<any> {
    return this.http.delete(ApiUrl + `/crystal/delete/${id}`, this.httpOptions)
  }

  updateStore(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/store/updateStore/${id}`, data, this.httpOptions)
  }

  deleteStore(id: any): Observable<any> {
    return this.http.delete(ApiUrl + `/store/delete/${id}`, this.httpOptions)
  }

  getStoresLists(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/store/getStores?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getStoresListsSearch(page: any,search:any): Observable<any> {
    return this.http.get(ApiUrl + "/store/getStores?page=" + page +"&search="+search+"&pageSize=10", this.httpOptions)
  }

  getSubscriptionLists(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/subscription/getSubscription?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getSubscriptionListsSearch(page: any,search:any): Observable<any> {
    return this.http.get(ApiUrl + "/subscription/getSubscription?page=" + page +"&search="+search+"&pageSize=10", this.httpOptions)
  }

  deleteSubscription(id:any):Observable<any>{
    return this.http.delete(ApiUrl+"/subscription/delete/"+id, this.httpOptions)
  }
  addSubscriptions(body:any):Observable<any>{
    return this.http.post(ApiUrl+"/subscription/addSubscription/",body, this.httpOptions)
  }
  getallPost(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/post/getAll?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getallPostSearch(page: any, search:any): Observable<any> {
    return this.http.get(ApiUrl + "/post/getAll?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }

  blockUnblockPost(data: any): Observable<any> {
    return this.http.post(ApiUrl + `/post/blockPost`, data, this.httpOptions)
  }

  getReportedPost(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/post/getAllReport?page=" + page + "&pageSize=10", this.httpOptions)
  }

  getComments(): Observable<any> {
    return this.http.get(ApiUrl + "/comment", this.httpOptions)
  }

  delComments(id:any): Observable<any> {
    return this.http.get(ApiUrl + "/comment/delete/"+id, this.httpOptions)
  }

  getReportedPostSearch(page: any, search:any): Observable<any> {
    return this.http.get(ApiUrl + "/post/getAllReport?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }

  updateSubscription(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/subscription/updateSubscription/${id}`, data, this.httpOptions)
  }

  getStaticPage(title: any): Observable<any> {
    return this.http.get(ApiUrl + "/static/getOne/" + title, this.httpOptions)
  }

  getByHeading(title: any): Observable<any> {
    return this.http.get(ApiUrl + "/static/getByHeading/" + title, this.httpOptions)
  }

  updateContent(data: any): Observable<any> {
    return this.http.put(ApiUrl + `/static/updateContent`, data, this.httpOptions)
  }

  updateCrystal(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/crystal/updateCrystal/${id}`, data, this.httpOptions)
  }

  getGroups(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/home/getGroups?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getGroupsSearch(page: any,search:any): Observable<any> {
    return this.http.get(ApiUrl + "/home/getGroups?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }

  updateGroup(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/home/updateGroup/${id}`, data, this.httpOptions)
  }

  addGroup(data: any): Observable<any> {
    return this.http.post(ApiUrl + `/home/addGroup`, data, this.httpOptions)
  }

  updateTutorial(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/static/updateTutorial/${id}`, data, this.httpOptions)
  }

  deleteStatic(id: any): Observable<any> {
    return this.http.delete(ApiUrl + "/static/delete/" + id, this.httpOptions)
  }

  addContent(data: any): Observable<any> {
    return this.http.post(ApiUrl + `/static/addContent`, data, this.httpOptions)
  }

  getAllContent(page: any): Observable<any> {
    return this.http.get(ApiUrl + `/home/getAllContent?page=` + page + `&pageSize=10&grouped=true`, this.httpOptions)
  }
  getAllContents(data: any): Observable<any> {
    return this.http.post(ApiUrl + `/home/filterGroup`, data, this.httpOptions)
  }
  addCriteria(data: any): Observable<any> {
    return this.http.post(ApiUrl + "/criteria/addCriteria/", data, this.httpOptions)
  }
  getAllCriteria(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/criteria/getAll?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getAllCriteriaSearch(page: any, search:any): Observable<any> {
    return this.http.get(ApiUrl + "/criteria/getAll?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }

  updateCriteria(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/criteria/updateCriteria/${id}`, data, this.httpOptions)
  }
  deleteCriteria(id: any): Observable<any> {
    return this.http.delete(ApiUrl + "/criteria/delete/" + id, this.httpOptions)
  }
  interestFlag(data: any): Observable<any> {
    return this.http.post(ApiUrl + `/home/interestFlag`, data, this.httpOptions)
  }

  splashData():Observable<any>{
    return this.http.get(ApiUrl+`/static/getText`, this.httpOptions)
  }

  languageList():Observable<any>{
    return this.http.get(ApiUrl+`/page/getAllPage`, this.httpOptions)
  }

  editSplash(id:any,data:any):Observable<any>{
    return this.http.put(ApiUrl+`/static/updateText/`+id,data, this.httpOptions)
  }

  getConstantConfig(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/static/getContents?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getConstantConfigs(obj: any): Observable<any> {
    return this.http.get(ApiUrl + "/static/getContents?page=" + obj.page + "&search="+obj.search+"&pageSize=10", this.httpOptions)
  }
  updateConstantConfig(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/static/updateContent/${id}`, data, this.httpOptions)
  }
  getUpdateTable(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/updateTable/getTable?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getUpdateTableSearch(page: any,search:any): Observable<any> {
    return this.http.get(ApiUrl + "/updateTable/getTable?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }
  updateUpdateTable(data:any): Observable<any> {
    return this.http.put(ApiUrl + `/updateTable/addTable`, data, this.httpOptions)
  }
  addHomeContent(data: any): Observable<any> {
    return this.http.post(ApiUrl + "/home/addHomeContent/", data, this.httpOptions)
  }
  updateHomeContent(data: any, id: any): Observable<any> {
    return this.http.put(ApiUrl + `/home/updateHomeContent/${id}`, data, this.httpOptions)
  }
  getAllGroup(): Observable<any> {
    return this.http.get(ApiUrl + "/home/getAllGroup", this.httpOptions)
  }
  getAllStore(): Observable<any> {
    return this.http.get(ApiUrl + "/store/getAllStores", this.httpOptions)
  }
  getAllCrystal(): Observable<any> {
    return this.http.get(ApiUrl + "/crystal/getAllCrystal", this.httpOptions)
  }
  getApiLog(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/operation/getApiLog?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getApiLogSearch(page: any,search:any): Observable<any> {
    return this.http.get(ApiUrl + "/operation/getApiLog?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }

  getNotification(page: any): Observable<any> {
    return this.http.get(ApiUrl + `/home/getAllContent?page=` + page + `&pageSize=10&grouped=false`, this.httpOptions)
  }
  getNotificationSearch(page: any, search:any): Observable<any> {
    return this.http.get(ApiUrl + `/home/getAllContent?page=` + page +"&search="+search+ `&pageSize=10&grouped=false`, this.httpOptions)
  }
  deleteHomeContent(id: any): Observable<any> {
    return this.http.delete(ApiUrl + `/home/delete/${id}`, this.httpOptions)
  }
  addCredit(data: any): Observable<any> {
    return this.http.post(ApiUrl + "/credit/addCredit", data, this.httpOptions)
  }
  importCredit(data: any): Observable<any> {
    return this.http.post(ApiUrl + "/credit/importCredit", data, this.httpOptions)
  }
  getCreditCode(): Observable<any> {
    return this.http.get(ApiUrl + "/credit/creditCode", this.httpOptions)
  }
  getAllUser(): Observable<any> {
    return this.http.get(ApiUrl + "/auth/getAllUser", this.httpOptions)
  }
  getCredits(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/credit/getCredits?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getCreditsSearch(page: any, search:any): Observable<any> {
    return this.http.get(ApiUrl + "/credit/getCredits?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }
  deleteCredits(id: any): Observable<any> {
    return this.http.delete(ApiUrl + `/credit/delete/${id}`, this.httpOptions)
  }
  getPages(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/page/getPages?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getPagesSearch(page: any,search:any): Observable<any> {
    return this.http.get(ApiUrl + "/page/getPages?page=" + page + "&search="+search+"&pageSize=10", this.httpOptions)
  }
  addPage(data: any): Observable<any> {
    return this.http.post(ApiUrl + "/page/addPage", data, this.httpOptions)
  }
  deletePage(id: any): Observable<any> {
    return this.http.delete(ApiUrl + `/page/delete/${id}`, this.httpOptions)
  }
  updateGroupOrder(data: any): Observable<any> {
    return this.http.post(ApiUrl + "/home/dropdownGroup", data, this.httpOptions)
  }
  deleteOperation(id:any):Observable<any> {
    return this.http.delete(ApiUrl + `/operation/delete/${id}`, this.httpOptions)
  }
  
  getMyCrystal(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/crystal/getMyCrystal?page=" + page + "&pageSize=10", this.httpOptions)
  }
  favouriteHistory(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/crystal/favouriteHistory?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getAllNote(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/crystal/getAllNote?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getAllRating(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/rate/getAllRating?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getContents(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/home/getContents?page=" + page + "&pageSize=10", this.httpOptions)
  }
  getLogTable(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/operation/logTable?page=" + page + "&pageSize=10", this.httpOptions)
  }
  deletePost(id:any):Observable<any> {
    return this.http.delete(ApiUrl + `/post/delete/${id}`, this.httpOptions)
  }
  deleteReportedPost(id:any):Observable<any> {
    return this.http.delete(ApiUrl + `/post/deleteReport/${id}`, this.httpOptions)
  }
  getAllPost(): Observable<any>{
    return this.http.get(ApiUrl + "/post/allpost", this.httpOptions)
  }
  updateSubscriptionStatus(data:any):Observable<any>{
    return this.http.put(ApiUrl+"/subscription/blockSubscription",data, this.httpOptions)
  }
  addNotificationManagement(data: any): Observable<any> {
    return this.http.post(ApiUrl + "/notification/addNotification", data, this.httpOptions)
  }
  getAllNotificationManagement(page: any,status: any): Observable<any> {
    return this.http.get(ApiUrl + "/notification/getAllNotification?page=" + page + "&pageSize=10" + "&notificationStatus=" + status, this.httpOptions)
  }
  deleteNotificationByAdmin(id:any):Observable<any> {
    return this.http.delete(ApiUrl + `/notification/deleteByAdmin/${id}`, this.httpOptions)
  }
  updateNotificationManagement(data: any, id: any):Observable<any>{
    return this.http.put(ApiUrl+`/notification/updateNotification/${id}`,data, this.httpOptions)
  }
  paymentManagement(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/subscription/getAllPayment?page=" + page + "&pageSize=10", this.httpOptions)
  }
  userManagement(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/subscription/getAllUserSubscription?page=" + page + "&pageSize=10", this.httpOptions)
  }
  deletePaymentManagement(id: any): Observable<any> {
    return this.http.delete(ApiUrl + `/subscription/deletePayment/${id}`, this.httpOptions)
  }
  userSubscription(page: any): Observable<any> {
    return this.http.get(ApiUrl + "/subscription/getAllSubscription?page=" + page + "&pageSize=10", this.httpOptions)
  }  

  notificationStatus(data: any): Observable<any> { 
    return this.http.post(ApiUrl + "/notification/blockNotification",data, this.httpOptions)
  } 
  
  getHomeSorting(page: any,type: any): Observable<any> {
    return this.http.get(ApiUrl + "/home/homeSorting?type=" + type +"&page=" + page + "&pageSize=10", this.httpOptions)
  }

  downloadFile(data:any, filename='data') {
    let csvData = this.ConvertToCSV(data, ['crystalName', 'finishType','selected','confidence','autoId']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray:any, headerList:any) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = 'S.No,';

     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
         let line = (i+1)+'';
         for (let index in headerList) {
            let head = headerList[index];

             line += ',' + array[i][head];
         }
         str += line + '\r\n';
     }
     return str;
 }
  

}
