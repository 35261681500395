/*@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../services/user.service";
import { NotificationsService } from "../../../services/notifications.service";
import { ApiUrl } from "../../../utils/config";
import Swal from 'sweetalert2';
declare var $ : any;

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {
  postList: any;
  currentPages = 1;
  postsPerPage = 10;
  count = 0;
  page = 1;
  tableSize = 10;
  postDetails: any;
  base: any = ApiUrl;
  search: any = 'Search Post Id';
  defaultPage: any = 1;
  selectToData:any = [];

  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getallPost(1,'');
    this.getPost();
  }
  onSearch(e:any){
    
    if(e === 'Search Post Id'){
        this.getallPost(this.defaultPage,'')
    }else{
        this.getallPost(this.defaultPage,e)
    }
    
 }

 getPost(){
  this.userService.getAllPost().toPromise().then(response => {
    if (response.success) {
      let array :any = [{
          id:'Search Post Id',
          text:'Search Post Id'
      }];
      response.data.map((item:any) => {
            array.push({
                id: item._id, 
                text: item._id
            });
      })
      this.selectToData = array;
      
    }
  });
 }

  getallPost = (page: any, search: any) => {
   
    this.currentPages = page;
    this.page=page;
    this.userService.getallPostSearch(page, search).toPromise().then(response => {
      if (response.success) {
        this.postList = response.data.result;
        
        this.count = response.data.count;
      }
    });
  }

  onChange = (e: any, id: any) => {
    let message = !e ? 'post is blocked now' : "post is active now"
    let data = {
      id: id,
      postStatus: !e ? "BLOCK" : "ACTIVE"
    }
    this.userService.blockUnblockPost(data).toPromise().then(response => {
      if (response.success) {

      }
    })
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    let search : any = '';
    if(this.search === 'Search Post Id'){
      this.getallPost(this.page,'');
    }else{
      this.getallPost(this.page,this.search);
    }
    
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getallPost(this.defaultPage,this.search);
  }

  viewPost = (data: any) => {
    this.postDetails = data;
  }

  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deletePost(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getallPost(this.defaultPage,'')
          }
        })
      }
    }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
    });
  }
}