import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import {NotificationsService} from "../../services/notifications.service";
import { SessionService } from "../../services/session.service";
import { ApiUrl } from "../../utils/config";
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-notification-management',
  templateUrl: './add-notification-management.component.html',
  styleUrls: ['./add-notification-management.component.css']
})
export class AddNotificationManagementComponent implements OnInit {

  form!: FormGroup;
  submitted = false;
  loading = false;
  base: any = ApiUrl;
  criteriaList :any
  count :any
  page = 1;
  tableSize = 10;
  selectedGroup:any
  selected_image:any
  imgUrl = "/assets/img/user.png";
  logoImgUrl = "/assets/img/user.png";
  imageToUpload: any;
  logoImageToUpload: any;
  locationList:any
  zero = 0
  // grouped

  constructor(private userService: UserService, private formBuilder:FormBuilder, private router:Router,private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.formControls()
  }

  formControls = () => {
    this.form = this.formBuilder.group({
      notificationDate: ['', [Validators.required]],
      notificationLink: [''],
      notificationSubTitle: [''],
      pushNotification: [false, [Validators.required]],
      notificationTitle: ['', [Validators.required]],
      status: ['Draft', [Validators.required]],
    });    
  };

  get f() {
    return this.form.controls;
  }
  get grouped() { return this.f.grouped.value; }

  onFileChangeLogoImage(evt:any){

    this.logoImageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.logoImageToUpload[this.zero]);
    reader.onload = (e: any) => {
        this.logoImgUrl = e.target.result;
    };
  }

  submitForm = () => {
    this.submitted = true;
    
    const formData = new FormData();
    
    if (this.logoImageToUpload && this.logoImageToUpload[this.zero]) {
      formData.append('logo', this.logoImageToUpload[this.zero]);
    }
    for (let key in this.form.value) {
        
        formData.append(key, this.form.value[key]);
    }
    if (this.form.invalid) {
        return;
    }
    
    this.form.value;
    this.loading = true;
    this.userService.addNotificationManagement(formData).toPromise()
    .then((resp) => {
        if (resp.success) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Notification Added Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            this.router.navigate(["/admin/notification-management"]);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
        })
    });
  }

}
