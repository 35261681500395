import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "./../../../../../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import {NotificationsService} from "./../../../../../services/notifications.service";
import { SessionService } from "./../../../../../services/session.service";
import { ApiUrl } from "../../../../../utils/config";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-home-creation',
  templateUrl: './add-home-creation.component.html',
  styleUrls: ['./add-home-creation.component.css']
})
export class AddHomeCreationComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  loading = false;
  base: any = ApiUrl;
  groupList :any
  count :any
  page = 1;
  tableSize = 10;
  selectedGroup:any
  selected_image:any
  imgUrl = "/assets/img/user.png";
  logoImgUrl = "/assets/img/user.png";
  imageToUpload: any;
  logoImageToUpload: any;
  locationList:any
  crystalList:any
  storeList:any
  selectedForm:any
  selectToStore:any = []
  selectToCrystal:any = []
  store: any = ''
  crystal: any = ''
  one = 1
  two=2
  three=3
  four = 4
  five =5
  six = 6
  seven = 7

  constructor(private userService: UserService, private formBuilder:FormBuilder, private router:Router,private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.formControls()
    this.getGroups()
    this.getLanguageList()
    this.getAllCrystal()
    this.getAllStore()
  }
  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }

  formControls = () => {
    this.form = this.formBuilder.group({
      grouped: [true, [Validators.required]],
      groupType: ['', [Validators.required]],
      canClose: [true, [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      interestFlag: [true, [Validators.required]],
      contentOrder: ['', [Validators.required,Validators.pattern(/^[0-9]+$/)]],
      distribution: ['', [Validators.required]],
      postDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      notificationDate: ['', [Validators.required]],
      link: ['', [Validators.required]],
      notificationSubTitle: ['', [Validators.required]],
      notificationTitle: ['', [Validators.required]],
      pushNotification: [false, [Validators.required]],
      notificationLink: ['', [Validators.required]],
      notificationStatus: ['None', [Validators.required]],
      color: ['', [Validators.required]],
      location: ['', [Validators.required]],
      crystal: ['', [Validators.required]],
      store: ['', [Validators.required]],
      action: ['New', [Validators.required]],
      test: [false, [Validators.required]],
      status: ['Draft', [Validators.required]],

    });    
  };

  
    getGroups = () => {
        this.userService.getAllGroup().toPromise().then(response => {
            if (response.success) {
                this.groupList = response.data;
            }
        });
    }
    getAllCrystal = () => {
        this.userService.getAllCrystal().toPromise().then(response => {
            if (response.success) {
                this.crystalList = response.data;

                var list: any = [];

                response.data.map((item:any)=>{

                    if(item.crystalName){
                    
                    list.push({
                        id: item._id,
                        text: item.crystalName
                    });
                  }
                })
                this.selectToCrystal = list
                
            }
        });
    }
    getAllStore = () => {
        this.userService.getAllStore().toPromise().then(response => {
            if (response.success) {
                this.storeList = response.data;
                
                var list: any = []
                var category: string = '';
                response.data.map((item:any)=>{
                    
                    if(item.category!==undefined && item.category!==null){
                        category = item.category
                    }
                    if(item.shopName){
                    list.push({
                        id: item._id,
                        text: item.shopName+' ('+item.category+')'
                    })
                }
                })

                this.selectToStore = list
            }
        });
    }
    

    get f() {
        return this.form.controls;
    }
  get grouped() { return this.f.grouped.value; }

  get selGroup() { return this.selectedGroup; }


  changeGroup(evt:any){
    let name  = evt.target.value
    let filtered = this.groupList.filter((dataf: any) =>
      dataf._id===name
    )
      this.selectedGroup=filtered[0]?filtered[0].type:''
      
  }
  onFileChange(evt:any){

    this.imageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
        this.imgUrl = e.target.result;
    };
  }
  onFileChangeLogoImage(evt:any){

    this.logoImageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.logoImageToUpload[0]);
    reader.onload = (e: any) => {
        this.logoImgUrl = e.target.result;
    };
  }
  submitForm = () => {
    this.submitted = true;
    
    const formData = new FormData();
    
    if (this.imageToUpload && this.imageToUpload[0]) {
        formData.append('image', this.imageToUpload[0]);
    }
    
    if(this.logoImageToUpload && this.logoImageToUpload[0]){
        formData.append("logo",this.logoImageToUpload[0])
    }
    
    if(this.selGroup==this.three) {
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            crystal:this.crystal,
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            notificationDate: this.form.value['notificationDate'],
            link: this.form.value['link'],
            notificationSubTitle: this.form.value['notificationSubTitle'],
            notificationTitle: this.form.value['notificationTitle'],
            pushNotification: this.form.value['pushNotification'],
            notificationLink: this.form.value['notificationLink'],
            notificationStatus: this.form.value['notificationStatus'],
            color: this.form.value['color'],
            location: this.form.value['location'],            
            action: this.form.value['action'],            
            test: this.form.value['test'],            
            status: this.form.value['status'],            
        }
    } else if(this.selGroup==this.seven){
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            store: this.store,
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            notificationDate: this.form.value['notificationDate'],
            link: this.form.value['link'],
            notificationSubTitle: this.form.value['notificationSubTitle'],
            notificationTitle: this.form.value['notificationTitle'],
            pushNotification: this.form.value['pushNotification'],
            notificationLink: this.form.value['notificationLink'],
            notificationStatus: this.form.value['notificationStatus'],
            color: this.form.value['color'],
            location: this.form.value['location'],   
            action: this.form.value['action'],            
            test: this.form.value['test'],            
            status: this.form.value['status'],          
        }
    }else{
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            description: this.form.value['description'],
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            notificationDate: this.form.value['notificationDate'],
            link: this.form.value['link'],
            notificationSubTitle: this.form.value['notificationSubTitle'],
            notificationTitle: this.form.value['notificationTitle'],
            pushNotification: this.form.value['pushNotification'],
            notificationLink: this.form.value['notificationLink'],
            notificationStatus: this.form.value['notificationStatus'],
            color: this.form.value['color'],
            location: this.form.value['location'],  
            action: this.form.value['action'],            
            test: this.form.value['test'],            
            status: this.form.value['status'],           
        }
    }
    
    
    for (let key in this.selectedForm) {
        
        formData.append(key, this.selectedForm[key]);
    }
    
        if (!this.validate) {
            
            return;
        }
        this.loading = true;
        
        
        this.userService.addHomeContent(formData).toPromise()
        .then((resp) => {
            if (resp.success) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Home Content Added Successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.router.navigate(["/admin/homeCreation"]);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        });
  }

  get validate(){

    var errors=0;
    
    if(this.selGroup == '' || this.selGroup == undefined){
        errors += 1
    }
    if(
        this.selGroup == 1 ||
        this.selGroup == 2 ||
        this.selGroup == 6 ||
        this.selGroup == 4 ||
        this.selGroup == 5 
    ){
        if(
            this.form.value['title']=='' ||
            this.form.value['contentOrder']=='' || 
            this.form.value['distribution']=='' ||
            this.form.value['postDate']=='' || 
            this.form.value['endDate']=='' || 
            this.form.value['location']=='' 
        
        ){
            errors += 1
        }
    }
    if(
        this.selGroup == 3 
    ){
        if(
            this.form.value['title']=='' ||
            this.form.value['contentOrder']=='' || 
            this.form.value['distribution']=='' ||
            this.form.value['postDate']=='' || 
            this.form.value['endDate']=='' || 
            this.form.value['location']=='' 
        
        ){
            errors += 1
        }
    }

    if(
        this.selGroup == 7 
    ){
        if(
            this.form.value['title'] == '' ||
            this.form.value['contentOrder'] == '' || 
            this.form.value['distribution'] == '' ||
            this.form.value['postDate'] == '' || 
            this.form.value['endDate'] == '' || 
            this.form.value['location'] == '' 
        
        ){
            errors += 1
        }
    }
    if(this.form.value['postDate'] !== '' && 
    this.form.value['endDate'] !== '' && 
        this.form.value['endDate'] < this.form.value['postDate'] 
    ){
        errors += 1
    }
    
    
    if(errors>0){
        return false;
    }else{
        return true;
    }
    
  }

}
