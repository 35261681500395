/* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.*/

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiUrl } from "../../../../../utils/config";
import { NotificationsService } from "../../../../../services/notifications.service";
declare var $: any;

@Component({
  selector: 'app-home-creation',
  templateUrl: './home-creation.component.html',
  styleUrls: ['./home-creation.component.css']
})
export class HomeCreationComponent implements OnInit {
  ethicalSourcingDetail: any;
  currentPages = 1;
  postsPerPage = 10;
  form !: FormGroup;
  tableSize = 10;
  indexZero=0;
  tutorialList: any;
  submitted = false;
  loading = false;
  count = 0;
  page = 1;
  idToShow: any
  base: any = ApiUrl;
  selectedGroup:any
  selected_image:any
  imgUrl = "/assets/img/user.png";
  logoImgUrl = "/assets/img/user.png";
  groupList :any
  imageToUpload: any;
  logoImageToUpload: any;
  locationList:any
  crystalList:any
  storeList:any
  selectedForm:any
  crystal:any
  store:any
  groupType:any = ''
  distribution:any = ''
  selectToStore:any = []
  selectToCrystal:any = []
  interestFlag: any = ""
  postDate = ""
  endDate = ""
  sortType = "DSC"
  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getTutorialList(1);
    this.updatefromControl()
    this.getGroups()
    this.getLanguageList()
    this.getAllCrystal()
    this.getAllStore()

  }

  
  changeFilter(e:any){
    this.getTutorialList(1);

    
  }

  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }

  getGroups = () => {
        this.userService.getAllGroup().toPromise().then(response => {
            if (response.success) {
                this.groupList = response.data;
                
            }
        });
    }
  getAllCrystal = () => {
      this.userService.getAllCrystal().toPromise().then(response => {
          if (response.success) {
              this.crystalList = response.data;

              var list: any = [];

                response.data.map((item:any)=>{
                    if(item.crystalName){
                    list.push({
                        id: item._id,
                        text: item.crystalName
                    })
                  }
                })
                this.selectToCrystal = list
          }
      });
  }
  getAllStore = () => {
      this.userService.getAllStore().toPromise().then(response => {
          if (response.success) {
              this.storeList = response.data;

                var list: any = [];
                var category: string = '';
                response.data.map((item:any)=>{
                    
                    if(item.category!==undefined && item.category!==null){
                        category = item.category
                    }
                    if(item.shopName){
                    list.push({
                        id: item._id,
                        text: item.shopName+' ('+item.category+')'
                    })
                  }
                })
                this.selectToStore = list
          }
      });
  }

  onFileChange(evt:any){

    this.imageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
        this.imgUrl = e.target.result;
    };
  }

  onFileChangeLogoImage(evt:any){

    this.logoImageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.logoImageToUpload[0]);
    reader.onload = (e: any) => {
        this.logoImgUrl = e.target.result;
    };
  }

  
  changeGroup(evt:any){
    let name  = evt.target.value
    let filtered = this.groupList.filter((dataf: any) =>
      dataf._id===name
    )
      this.selectedGroup=filtered[0]?filtered[0].type:''
      
  }
  get grouped() { return this.f.grouped.value; }

  get selGroup() { return this.selectedGroup; }
  updatefromControl = () => {
    this.form = this.formbuilder.group({
        grouped: [true, [Validators.required]],
        groupType: ['', [Validators.required]],
        canClose: [true, [Validators.required]],
        title: ['', [Validators.required]],
        description: ['', [Validators.required]],
        interestFlag: [true, [Validators.required]],
        contentOrder: ['', [Validators.required,Validators.pattern(/^[0-9]+$/)]],
        distribution: ['', [Validators.required]],
        postDate: ['', [Validators.required]],
        endDate: ['', [Validators.required]],
        notificationDate: ['', [Validators.required]],
        link: ['', [Validators.required]],
        pushNotification: [false, [Validators.required]],
        notificationSubTitle: ['', [Validators.required]],
        notificationTitle: ['', [Validators.required]],
        notificationStatus: ['None', [Validators.required]],
        color: ['', [Validators.required]],
        location: ['', [Validators.required]],
        crystal: ['', [Validators.required]],
        store: ['', [Validators.required]],
        action: ['', [Validators.required]],
        test: [false, [Validators.required]],
        status: ['', [Validators.required]],
        createdAt: [''],
        updatedAt:['']
    })
  }

  changeInterestFilter = (e:any) => {

    if(e.target.value == "true"){
      this.interestFlag = true
    }else if(e.target.value == "false"){
      this.interestFlag = false
    }else{
      this.interestFlag = ""
    }
    this.getTutorialList(1);
  }

  endDateChange = (e:any) => {
    this.endDate = e.target.value
    this.getTutorialList(1);
  }
  postDateChange = (e:any) => {
    this.postDate = e.target.value
  }
  onChange = (e: any, id: any) => {
    let message = !e ? false : true
    let data = {
      id: id,
      interestFlag: !e ? false : true
    }
    this.userService.interestFlag(data).toPromise().then(response => {
      if (response.success) {
      }
    })
  }

  sortingData(type:any){
    this.page = 1;
    this.sortType = type
    this.userService.getHomeSorting(this.page,type).toPromise().then(response => {
      if (response.success) {
        this.tutorialList = response.data.result;
        this.count = response.data.count;
      }
    });
  }

  getTutorialList = (page: any) => {
    this.currentPages = page?page:1;
    this.page = page?page:1;
    var obj = {
      pageNo:page,
      searchGroup:this.groupType,
      interestFlag: this.interestFlag,
      postDate: this.postDate,
      endDate: this.endDate,
      pageSize:10
    }
    if(this.groupType !=='' || this.interestFlag !== '' || this.postDate !== '' || this.endDate !== ''){
      
      this.userService.getAllContents(obj).toPromise().then(response => {
        if (response.success) {
            this.tutorialList = response.data[this.indexZero].result;
            this.count = response.data[this.indexZero].count;
        }
      });

    }else{
      this.userService.getAllContent(page).toPromise().then(response => {
        if (response.success) {
          this.tutorialList = response.data.result;
          this.count = response.data.count;
        }
      });

    }
  }
    formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
  getEthicalSourcingDetail = () => {
    let name  = this.ethicalSourcingDetail.groupType._id
    let filtered = this.groupList.filter((dataf: any) =>
      dataf._id===name
    )
      this.selectedGroup=filtered[0]?filtered[0].type:''

    if(this.ethicalSourcingDetail.crystal!==undefined){
        this.crystal = this.ethicalSourcingDetail.crystal
    }
    if(this.ethicalSourcingDetail.store!==undefined){
        this.store = this.ethicalSourcingDetail.store
    }

    if(this.ethicalSourcingDetail.image!==undefined && this.ethicalSourcingDetail.image!==''){
        this.imgUrl = this.base + '/'+ this.ethicalSourcingDetail.image
    }

    if(this.ethicalSourcingDetail.logo!==undefined && this.ethicalSourcingDetail.logo!==''){
      this.logoImgUrl = this.base + '/'+ this.ethicalSourcingDetail.logo
  }


    let postDate = this.formatDate(this.ethicalSourcingDetail.postDate)
    let endDate = this.formatDate(this.ethicalSourcingDetail.endDate)
    let notificationDate = this.formatDate(this.ethicalSourcingDetail.notificationDate)
    
    this.form = this.formbuilder.group({

        grouped: [this.ethicalSourcingDetail.grouped, [Validators.required]],
        groupType: [this.ethicalSourcingDetail.groupType._id, [Validators.required]],
        canClose: [this.ethicalSourcingDetail.canClose, [Validators.required]],
        title: [this.ethicalSourcingDetail.title, [Validators.required]],
        description: [this.ethicalSourcingDetail.description, [Validators.required]],
        interestFlag: [this.ethicalSourcingDetail.interestFlag, [Validators.required]],
        contentOrder: [this.ethicalSourcingDetail.contentOrder, [Validators.required,Validators.pattern(/^[0-9]+$/)]],
        distribution: [this.ethicalSourcingDetail.distribution, [Validators.required]],
        postDate: [postDate, [Validators.required]],
        endDate: [endDate, [Validators.required]],
        notificationDate: [notificationDate, [Validators.required]],
        link: [this.ethicalSourcingDetail.link, [Validators.required]],
        pushNotification: [this.ethicalSourcingDetail.pushNotification, [Validators.required]],

        notificationSubTitle: [this.ethicalSourcingDetail.notificationSubTitle, [Validators.required]],
        notificationTitle: [this.ethicalSourcingDetail.notificationTitle, [Validators.required]],

        notificationStatus: [this.ethicalSourcingDetail.notificationStatus, [Validators.required]],
        
        
        color: [this.ethicalSourcingDetail.color, [Validators.required]],
        location: [this.ethicalSourcingDetail.location],
        action: [this.ethicalSourcingDetail.action, [Validators.required]],
        test: [this.ethicalSourcingDetail.test, [Validators.required]],
        status: [this.ethicalSourcingDetail.status, [Validators.required]],
        crystal: [this.crystal, [Validators.required]],
        store: [this.store, [Validators.required]],
        createdAt: [this.ethicalSourcingDetail.createdAt],
        updatedAt: [this.ethicalSourcingDetail.updatedAt],
    })
  }

  get f() {
    return this.form.controls;
  }

  viewStore = (data: any) => {
    this.submitted = false;
    this.ethicalSourcingDetail = data;
    this.idToShow = data._id;
    this.getEthicalSourcingDetail();
    
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getTutorialList(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTutorialList(1);
  }

  changeOrder(evt:any, id:any) {  
    
    if(evt.target.value == '') {
      return;
    }
    var obj = {
      contentOrder:evt.target.value
    }
    this.userService
      .updateHomeContent(obj, id)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          this.getTutorialList(this.page);

        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });


  }

  submitForm = () => {
    this.submitted = true;
    const formData = new FormData();
    
    if (this.imageToUpload && this.imageToUpload[0]) {
        formData.append('image', this.imageToUpload[0]);
    }

    if(this.logoImageToUpload && this.logoImageToUpload[0]){
      formData.append("logo",this.logoImageToUpload[0])
  }
    
    
    if(this.selGroup==3) {

      if(this.form.value['notificationDate'] == "NaN-NaN-NaN"){
        this.form.value['notificationDate'] = "";
      }

      if(!this.form.value['pushNotification']){
      
        this.form.value['pushNotification'] = false;
      }

        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            crystal:this.crystal,
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            notificationDate: this.form.value['notificationDate'],
            link: this.form.value['link'],
            pushNotification: this.form.value['pushNotification'],

            notificationSubTitle: this.form.value['notificationSubTitle'],
            notificationTitle: this.form.value['notificationTitle'],
            notificationStatus: this.form.value['notificationStatus'],
            
            
            
            color: this.form.value['color'],
            location: this.form.value['location'] ? this.form.value['location'] : 0, 
            action: this.form.value['action'],            
            test: this.form.value['test'],            
            status: this.form.value['status'],
            createdAt: this.form.value['createdAt']             
        }
    } else if(this.selGroup==7){

      if(this.form.value['notificationDate'] == "NaN-NaN-NaN"){

        this.form.value['notificationDate'] = "";

      }

      if(!this.form.value['pushNotification']){
        this.form.value['pushNotification'] = false;
      }
      
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            store: this.store,
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            notificationDate: this.form.value['notificationDate'],
            pushNotification: this.form.value['pushNotification'],

            link: this.form.value['link'],
            notificationSubTitle: this.form.value['notificationSubTitle'],
            notificationTitle: this.form.value['notificationTitle'],
            notificationStatus: this.form.value['notificationStatus'],
            
            color: this.form.value['color'],
            location: this.form.value['location'] ? this.form.value['location'] : 0,  
            action: this.form.value['action'],            
            test: this.form.value['test'],            
            status: this.form.value['status'],      
        }
    }else{

      if(this.form.value['notificationDate'] == "NaN-NaN-NaN"){
        this.form.value['notificationDate'] = "";
      }
      if(!this.form.value['pushNotification']){
        this.form.value['pushNotification'] = false;
      }
      
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            description: this.form.value['description'],
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            notificationDate: this.form.value['notificationDate'],
            link: this.form.value['link'],
            pushNotification: this.form.value['pushNotification'],

            notificationSubTitle: this.form.value['notificationSubTitle'],
            notificationTitle: this.form.value['notificationTitle'],
            notificationStatus: this.form.value['notificationStatus'],
            
            color: this.form.value['color'],
            location: this.form.value['location'] ? this.form.value['location'] : 0,  
            action: this.form.value['action'],            
            test: this.form.value['test'],            
            status: this.form.value['status'],           
        }
    }
    for (let key in this.selectedForm) {
        
        formData.append(key, this.selectedForm[key]);
    }
    if (!this.validate) {
        return;
    }


    this.loading = true;
    this.userService
      .updateHomeContent(formData, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: ' Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
          this.getTutorialList(this.page)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

  deleteStore = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteHomeContent(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getTutorialList(this.page)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }
  get validate(){

    var errors=0;
    
    if(this.selGroup == '' || this.selGroup == undefined){
        errors += 1
    }
    if(
        this.selGroup == 1 ||
        this.selGroup == 2 ||
        this.selGroup == 6 ||
        this.selGroup == 4 ||
        this.selGroup == 5 
    ){
        if(
            this.form.value['title']==''  ||
            this.form.value['contentOrder']=='' || 
            this.form.value['distribution']=='' || 
            this.form.value['postDate']=='' || 
            this.form.value['endDate']==''
        
        ){
            errors += 1
        }
    }
    if(
        this.selGroup == 3 
    ){
        if(
            this.form.value['title']=='' ||
            this.form.value['contentOrder']=='' || 
            this.form.value['distribution']=='' || 
            this.form.value['postDate']=='' || 
            this.form.value['endDate']=='' 
        
        ){
            errors += 1
        }
    }

    if(
        this.selGroup == 7 
    ){
        if(
            this.form.value['title'] == '' ||
            this.form.value['contentOrder'] == '' || 
            this.form.value['distribution'] == '' || 
            this.form.value['postDate'] == '' || 
            this.form.value['endDate'] == ''  
        
        ){
            errors += 1
        }
    }
   
    if( this.form.value['action'] == null ){
      errors += 1
    }
    if( this.form.value['test'] == null){
      errors += 1
    }
    if( this.form.value['status'] == null  ){
      errors += 1
    }
    
    
    if(errors>0){
        return false;
    }else{
        return true;
    }

    
  }
}
