<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Comments
                    </h3>
                    <br>
                    
                     <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            
                            <span>
                                Showing 
                                <b>
                                    <ng-container *ngIf="reportedPostList">
                                        {{ reportedPostList.length>0?(currentPages - 1) * tableSize+1:0}}

                                    </ng-container>
                                </b>
                                -
                                <b>{{ ((currentPages - 1) * tableSize+1) + (reportedPostList?reportedPostList.length-1:0)}}</b>
                                of 
                                <b>{{count}}</b>
                                 items
                            </span>
                          </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">Post Id</th>
                                    <th class="text-center whitespace-no-wrap">Posted By</th>
                                    <th class="text-center whitespace-no-wrap">Posted Text</th>
                                    <th class="text-center whitespace-no-wrap">Status</th>
                                    <th class="text-center whitespace-no-wrap">Created At</th>
                                    <th class="text-center whitespace-no-wrap">Updated At</th>

                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let post of comments | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">

                                  

                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">  <a> {{post?.postId._id}} </a>  </td>
                                    <td class="text-center">{{post?.userId?.firstName}}
                                        {{post?.userId?.lastName}}
                                    </td>
                                    
                                    <td class="text-center">{{post?.comment}}</td>
                                    
                                   
                                    <td class="text-center ">
                                       
                                        <!-- change button class as per response from server -->
                                        <ng-container *ngIf="post?.status=='rejected'">
                                            <button class="btn-danger px-4">                                         {{post?.status}}     
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="post?.status=='unapproved'">
                                            <button class="btn-primary px-3">                                         {{post?.status}}     
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="post?.status=='approved'">
                                            <button class="btn-success px-4">                                         {{post?.status}}     
                                            </button>
                                                
                                        </ng-container>
                                        
                                        
                                        
                                    <form >
                                        <div class="text-left ml-5 pl-5 mt-2 ">
                                        <button type="radio " class="mr-2"  >  Approve  </button>
                                        <button type="radio " class="mr-2"  >  Unapprove  </button>
                                        <button type="radio" >   Reject  </button>

                                        </div>
                                    

                                    </form>
                                    </td>

                                    <td class="text-center">{{post.createdAt | date:'short'}}</td>
                                    <td class="text-center">{{post.updatedAt | date:'short'}}</td>

                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewUser" (click)="viewPost(post)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                            <a data-target="#deleteSubscription"
											(click)="delComments(post._id)" class="text-danger"><i
												class="fa fa-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="intro-x" *ngIf="comments!=undefined && comments.length==0">
                                    <td class="text-center" colspan="6">
                                        No record found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewUser">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <h4 class="mb-4">Post Info</h4>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group mb-">
                            <div class="uploadimageprofile ">
                                <ng-container *ngIf="reportedPostDetails!=undefined">

                                
                                <img src="{{
									reportedPostDetails.postId 
									  ? base + '/'+ reportedPostDetails.postId.postImg
									  : 'assets/img/user.png'
								  }}" class="img-rounded w-100 h-100">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><label>Reported By</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined && reportedPostDetails.reported_by.firstName!=undefined">
                                {{reportedPostDetails && reportedPostDetails.reported_by ?
                                reportedPostDetails.reported_by.firstName + " " + reportedPostDetails.reported_by.lastName:""}}
                                </ng-container>
                                
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"><label>Posted By</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined && reportedPostDetails.postId!=undefined">
                                {{reportedPostDetails && reportedPostDetails.postId.created_by ?
                                reportedPostDetails.postId.created_by.firstName + " " + reportedPostDetails.postId.created_by.lastName:""}}</ng-container></p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><label>Posted Text</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined && reportedPostDetails.postId!=undefined">
                                {{reportedPostDetails.postId ? reportedPostDetails.postId.text:""}}
                                </ng-container>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"><label>Reason</label>
                            <p>{{reportedPostDetails ? reportedPostDetails.reportReason:""}}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><label>Created At</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails.createdAt | date:'short' }} 
                                </ng-container>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"><label>Updated At</label>
                            <p>
                                <ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails.updatedAt | date:'short' }} 
                                </ng-container>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>