<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Home Screen
                        <span class="float-right">
                            <a class="btn btn-primary" [routerLink]="['/admin/addHomeCreation']">Add Content</a>
                        </span>
                    </h3>
                    <br>
                    <br>
                    <div class="row">

                        <div class="col-md-2 ">
                            
                            <div class="form-group ">
                               
                                <label>Group Name</label>
                                <select class="form-control" [(ngModel)]="groupType" [ngModelOptions]="{standalone: true}" (change)="changeFilter($event)" placeholder="Select Group">
                                    <option value="">Select Group Name </option>
                                    <option *ngFor="let item of groupList" [value]="item.groupName">{{item.groupName}}</option>
                                </select>
                                
                            </div>
                        </div>

                        <div class="col-md-2 ">
                            
                            <div class="form-group ">
                                <label>Interest Flag</label>
                                
                                <select class="form-control" [(ngModel)]="interestFlag" [ngModelOptions]="{standalone: true}" (change)="changeInterestFilter($event)" placeholder="Select Interest Flag">
                                    <option value="">Select Interest Flag</option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                                
                            </div>
                        </div>

                        <div class="col-md-2 ">
                            <div class="form-group ">
                                <label>Post Date: </label>
                              <input  type="date" class="form-control" [(ngModel)]="postDate" (change)="postDateChange($event)"
                                placeholder="Post Date">
                                
                            </div>
                        </div>
                        <div class="col-md-2">
                            
                            <div class="form-group ">
                               
                                <label>End Date: </label>
                                <input  type="date" class="form-control" [(ngModel)]="endDate" (change)="endDateChange($event)"
                                placeholder="End Date">
                                
                            </div>
                        </div>
                        
                        <div class="col-md-4 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                                Showing 
                                <b>
                                    <ng-container *ngIf="tutorialList">
                                        {{ tutorialList.length>0?(currentPages - 1) * postsPerPage+1:0}}

                                    </ng-container>
                                </b>
                                -
                                <b>{{ ((currentPages - 1) * postsPerPage+1) + (tutorialList?tutorialList.length-1:0)}}</b>
                                of 
                                <b>{{count}}</b>
                                 items
                            </span>
                          </div>
                          <div class="form-group text-right showing_line" >
                            <span>
                               <i (click)="sortingData('ASC')" *ngIf="sortType == 'DSC'" class="fa fa-sort-amount-asc" aria-hidden="true"></i>
                               <i (click)="sortingData('DSC')" *ngIf="sortType == 'ASC'" class="fa fa-sort-amount-desc" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table width="100%" class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">Title</th>
                                    <th class="text-center whitespace-no-wrap">Group Description</th>
                                    
                                    <th class="text-center whitespace-no-wrap">Group Name</th>
                                    <th class="text-center whitespace-no-wrap">Notification Title</th>
                                    <th class="text-center whitespace-no-wrap">Notification Sub Title</th>
                                    <th class="text-center whitespace-no-wrap">Link</th>
                                    <th class="text-center whitespace-no-wrap">Interest Flag</th>
                                    <th class="text-center whitespace-no-wrap">End Date</th>
                                    <th class="text-center whitespace-no-wrap">Distribution</th>
                                    <th class="text-center whitespace-no-wrap">Content Order</th>
                                    <th class="text-center whitespace-no-wrap">Post Date</th>
                                    

                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let tutorial of tutorialList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }} </h4>
                                    </td>
                                    <td class="text-center">{{tutorial.title}}</td>
                                    <td class="text-center">{{tutorial.groupType.description}}</td>
                                    
                                    <td class="text-center">{{tutorial.groupType.groupName}}</td>
                                    <td class="text-center">{{tutorial.notificationTitle}}</td>
                                    <td class="text-center">{{tutorial.notificationSubTitle}}</td>
                                    <td class="text-center">{{tutorial.link}}</td>
                                    <td class="text-center">
                                        <ui-switch color="green" (change)="onChange($event,tutorial._id)" [checked]="tutorial.interestFlag==true"></ui-switch>
                                    </td>
                                    <td class="text-center">{{tutorial.endDate | date: "short"}}</td>
                                    <td class="text-center">{{tutorial.distribution}}</td>
                                    <td class="text-center">
                                        <div class="col-md-8" >
                                            <div class="form-group" style="margin-left: 25px;">
                                                <input type="text" class="form-control" value="{{tutorial.contentOrder}}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" (keyup)="changeOrder($event,tutorial._id)"/>

                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">{{tutorial.postDate | date:'short'}}</td>
                                    


                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewStore" (click)="viewStore(tutorial)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                            <a (click)="deleteStore(tutorial._id)" href="javascript:void(0);" class="text-danger"><i
												class="fa fa-trash"></i></a>
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr class="intro-x" *ngIf="tutorialList!=undefined && tutorialList.length==0">
                                    <td class="text-center" colspan="13">
                                        No record found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
    
    
</div>

<div class="modal" id="viewStore" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Home Creation Info
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form  class="custom-mt-form" [formGroup]="form" (ngSubmit)="submitForm()">
                                        <div class="row m-0">
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Group Type</label>
                                                    
                                                    <select class="form-control" formControlName="groupType" (change)="changeGroup($event)">
                                                        
                                                        <option value="">Select</option>
                                                        <option *ngFor="let item of groupList" [value]="item._id">{{item.groupName}}</option>
                                                        
                                                    </select>
                                                    
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.groupType.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.groupType.errors.required">
                                                        Please select group type
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="selGroup===1 || selGroup===2 || selGroup===6|| selGroup===4 || selGroup===5" class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Image</label>
                                                        
                                                </div>
                                                <div class="col-lg-2">
         
                                                    
                                                        <div class="columns">
        
                                                        <div class="circle">
                                                            <div class="pic-upload">
                                                                <img [src]="imgUrl" class="w-100 h-100" />
                                                            </div>
                                                         
                                                            <img class="profile-pic" src="{{
                                                                selected_image
                                                                    ? base + '/'+ selected_image
                                                                    : 'assets/img/user.png'
                                                                }}">
                                                        </div>
                                                        <div class="p-image">
                                                            <label for="fileupload">
                                                                <i class="fa fa-camera upload-button"></i>
                                                            </label>
                                                            <input id="fileupload" class="file-upload" type="file"
                                                                (change)="onFileChange($event)" accept="image/*">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="selGroup===1 || selGroup===2 || selGroup===6|| selGroup===4 || selGroup===5 || selGroup===7 || selGroup===3" class="col-lg-12 col-md-12 col-12">
                                       
                                                <div class="col-lg-6 col-md-12 col-12">
                                                    <div class="form-group custom-mt-form-group">
                                                        <label class="control-label">Logo Image</label>
                                                            
                                                    </div>
                                                    <div class="col-lg-2">
            
                                                        
                                                            <div class="columns">
            
                                                            <div class="circle">
                                                                <div class="pic-upload">
                                                                    <img [src]="logoImgUrl" class="w-100 h-100" />
                                                                </div>
                                                           
                                                                <img class="profile-pic" src="{{
                                                                    selected_image
                                                                        ? base + '/'+ selected_image
                                                                        : 'assets/img/user.png'
                                                                    }}">
                                                            </div>
                                                            <div class="p-image">
                                                                <label for="fileuploadd">
                                                                    <i class="fa fa-camera upload-button"></i>
                                                                </label>
                                                                <input id="fileuploadd" class="file-upload" type="file"
                                                                    (change)="onFileChangeLogoImage($event)" accept="image/*">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        *ngIf="submitted && logoImageToUpload==null"
                                                        class="invalid-feedback"
                                                    >
                                                        <!-- <div *ngIf="logoImageToUpload==null">
                                                            Please select a logo image
                                                        </div> -->
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Can Close</label>
                                                    
                                                    <select class="form-control" formControlName="canClose">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div> 
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Title</label>
                                                    <input type="text" class="form-control" formControlName="title"
                                                        placeholder="Enter Title">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.title.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.title.errors.required">
                                                        Please enter title
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="selGroup===1 || selGroup===2 || selGroup===6|| selGroup===4 || selGroup===5" class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label>
                                                    <input  type="text" class="form-control" formControlName="description"
                                                        placeholder="Enter Description">
                                                </div>
                                             
                                            </div>
                                           
                                            <div *ngIf="selGroup===3" class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Crystal</label>
                                                    
                                                  
                                                    <ng-select2 
                                                        [(ngModel)]="crystal"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [data]="selectToCrystal"
                                                        [placeholder]="'Please select a crystal...'"
                                                        [dropdownParent]="'viewStore'"
                                                        >		
                                                    </ng-select2>
                                                </div>
                                                <div
                                                    *ngIf="submitted && crystal==''"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf=" crystal==''">
                                                        Please select crystal
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div *ngIf="selGroup===7" class="col-lg-6 col-md-6 col-6">
                                                <div class="">
                                                    <label class="control-label">Store</label>                                                    
                                                  
                                                    <ng-select2 
                                                        [(ngModel)]="store"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [data]="selectToStore"
                                                        [placeholder]="'Please select a store...'"
                                                        [dropdownParent]="'viewStore'"
                                                        >		
                                                    </ng-select2>
                                                </div>
                                                <div
                                                    *ngIf="submitted && store==''"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="store==''">
                                                        Please select store
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Interest Flag</label>
                                                    
                                                    <select class="form-control" formControlName="interestFlag">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                    
                                                    
                                                </div>
                                            </div> 
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Content Order</label>
                                                    <input  type="text" class="form-control" formControlName="contentOrder"
                                                        placeholder="Enter Content Order" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                                                </div>

                                                <div
                                                *ngIf="submitted && f.contentOrder.errors"
                                                class="invalid-feedback"
                                            >
                                                <div *ngIf="f.contentOrder.errors.required">
                                                    Please enter content
                                                </div>
                                            </div>
                                            
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Distribution</label>
                                                    
                                                        <select class="form-control" formControlName="distribution">
                                                            <option value="">Select</option>
                                                          
                                                            <option value="All">All</option>
                                                        </select>
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.distribution.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.distribution.errors.required">
                                                        Please enter distribution
                                                    </div>
                                                </div>
                                            </div>
        
                                            
                                            <div  class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Post Date</label>
                                                    <input  type="date" class="form-control" formControlName="postDate"
                                                        placeholder="Post Date">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.postDate.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.postDate.errors.required">
                                                        Please enter post date
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">End Date</label>
                                                    <input  type="date" class="form-control" formControlName="endDate"
                                                        placeholder="End Date">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.endDate.errors; else elseBlock"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.endDate.errors.required">
                                                        Please enter end date
                                                    </div>
                                                </div>
                                                <ng-template #elseBlock>
                                                <div
                                                    class="invalid-feedback"
                                                    #elseBlock
                                                >
                                                    <div *ngIf="submitted && f.endDate.value<f.postDate.value">
                                                        End date should be greater than post date
                                                    </div>
                                                </div>
                                                </ng-template>
                                            </div>

                                            <div  class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Date</label>
                                                    <input  type="date" class="form-control" formControlName="notificationDate"
                                                        placeholder="Notification Date">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.notificationDate.errors; else elseBlock"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.notificationDate.errors.required">
                                                        Please enter Notification Date
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            
                                            
                                            
                                            <div  class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Link</label>
                                                    <input  type="text" class="form-control" formControlName="link"
                                                    placeholder="Link">
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Push Notification </label>
                                                    
                                                    <select class="form-control" formControlName="pushNotification">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Title</label>
                                                    <input  type="text" class="form-control" formControlName="notificationTitle"
                                                    placeholder="Notification Sub Title">
                                                </div>
                                                
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Sub Title</label>
                                                    <input  type="text" class="form-control" formControlName="notificationSubTitle"
                                                    placeholder="Notification Sub Title">
                                                </div>
                                                
                                            </div>




                                            <div class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Color</label>
                                                    <input  type="text" class="form-control" formControlName="color"
                                                    placeholder="Notification Color">
                                                </div>
                                                
                                            </div>

                                            
                                            <div class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Status</label>
                                                    
                                                    <select class="form-control" formControlName="notificationStatus">
                                                        <option value="None">None</option>
                                                        <option value="Send">Send</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Sent">Sent</option>
                                                    </select>
                                                </div>
                                            </div>
        

                                            <div class="col-lg-6 col-md-6 col-6">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Location</label>
                                                    
                                                    <select class="form-control" formControlName="location">
                                                        
                                                        <option value="">Select</option>
                                                        <option *ngFor="let item of locationList" value="{{item.page}}">{{item.page}}</option>
                                                    </select>
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.location.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.location.errors.required">
                                                        Please select location
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Action</label>
                                                    
                                                    <select class="form-control" formControlName="action">
                                                        <option value="New">New</option>
                                                        <option value="Edit">Edit</option>
                                                        <option value="Delete">Delete</option>
                                                    </select>
                                                    
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.action.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.action.errors.required">
                                                        Please select action
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Test</label>
                                                    
                                                    <select class="form-control" formControlName="test">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                    
                                                    
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.test.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.test.errors.required">
                                                        Please select test
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Status</label>
                                                    
                                                    <select class="form-control" formControlName="status">
                                                        <option value="Draft">Draft</option>
                                                        <option value="Sent">Sent</option>
                                                    </select>
                                                    
                                                    
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.status.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.status.errors.required">
                                                        Please select status
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Created At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="createdAt"
                                                    placeholder="Created At">
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Updated At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="updatedAt"
                                                    placeholder="Created At">
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
