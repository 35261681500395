import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "./../../../../../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import {NotificationsService} from "./../../../../../services/notifications.service";
import { SessionService } from "./../../../../../services/session.service";
import { ApiUrl } from "../../../../../utils/config";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.css']
})
export class AddNotificationComponent implements OnInit {

  form!: FormGroup;
  submitted = false;
  loading = false;
  base: any = ApiUrl;
  criteriaList :any
  count :any
  page = 1;
  tableSize = 10;
  selectedGroup:any
  selected_image:any
  imgUrl = "/assets/img/user.png";
  logoImgUrl = "/assets/img/user.png";
  imageToUpload: any;
  logoImageToUpload: any;
  locationList:any

  // grouped

  constructor(private userService: UserService, private formBuilder:FormBuilder, private router:Router,private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.formControls()
    this.getAllCriteria(this.page)
    this.getLanguageList()
  }

  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }

  formControls = () => {
    this.form = this.formBuilder.group({
      grouped: [false, [Validators.required]],
      canClose: [true, [Validators.required]],
      notificationTemplate: [true, [Validators.required]],
      description: [''],
      interestFlag: [true, [Validators.required]],
      distribution: ['', [Validators.required]],
      postDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      link: [''],
      notificationSubTitle: [''],
      location: [''],
      pushNotification: [false, [Validators.required]],
      notificationListFlag: [false, [Validators.required]],
      notificationTitle: ['', [Validators.required]],
      notificationCriteria: ['', [Validators.required]],
      action: ['New', [Validators.required]],
      test: [false, [Validators.required]],
      status: ['Draft', [Validators.required]],
    });    
  };


  
  getAllCriteria = (page: Number) => {
    page = page ? page : 1
    this.userService.getAllCriteria(page).toPromise().then(response => {
      
      if (response.success) {
        this.criteriaList = response.data;
        this.count = response.data.length;
      }
    });
  }

  get f() {
    return this.form.controls;
  }
  get grouped() { return this.f.grouped.value; }


  onFileChange(evt:any){

    this.imageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
        this.imgUrl = e.target.result;
    };
  }

  onFileChangeLogoImage(evt:any){

    this.logoImageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.logoImageToUpload[0]);
    reader.onload = (e: any) => {
        this.logoImgUrl = e.target.result;
    };
  }
  submitForm = () => {
    this.submitted = true;
    

    const formData = new FormData();
    
    if (this.imageToUpload && this.imageToUpload[0]) {
        formData.append('image', this.imageToUpload[0]);
    }
    if (this.logoImageToUpload && this.logoImageToUpload[0]) {
      formData.append('logo', this.logoImageToUpload[0]);
    }
    for (let key in this.form.value) {
        
        formData.append(key, this.form.value[key]);
    }
    if (this.form.invalid) {
        return;
    }
    
    this.form.value;
    this.loading = true;
    this.userService.addHomeContent(formData).toPromise()
    .then((resp) => {
        if (resp.success) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Notification Added Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            this.router.navigate(["/admin/notification"]);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
        })
    });
  }

}
