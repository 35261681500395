<div class="wrapper wrapper-full-page">
    <div class="full-page login-page" data-color="blue">
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12 offset-md-3">
              <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
                <div class="card card-login">
                  <a class="navbar-brand text-center mx-auto w-100" href="#">
                    <img src="assets/img/logo.png??thumbnail==227" class="img-fluid mx-auto" />
                  </a>
                  <div class="card-header text-center borderbottom0 pt-2 pb-2">
                    <h3 class="card-title mt-2">Change Password</h3>
                  </div>
                  <div class="card-content">
                    

                    <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons mt-3">lock_outline</i>
                        </span>
                        <div class="form-group label-floating">
                          <label class="control-label">New Password</label>
                          <input type="password" class="form-control" formControlName="newPassword" required="required"/>
                          <div
                              *ngIf="submitted && f.newPassword.errors"
                              class="invalid-feedback"
                          >
                              <div *ngIf="f.newPassword.errors.required">
                              Please enter password
                              </div>
                              <div *ngIf="f.newPassword.errors.minlength">
                              Password should be minimum 6 digit
                              </div>
                          </div>
                        </div>
                      </div>

                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons mt-3">lock_outline</i>
                        </span>
                        <div class="form-group label-floating">
                          <label class="control-label">Confirm Password</label>
                          <input type="password" class="form-control" formControlName="confirmNewPassword" required="required"/>
                          <div
                              *ngIf="submitted && f.confirmNewPassword.errors"
                              class="invalid-feedback"
                          >
                              <div *ngIf="f.confirmNewPassword.errors.required">
                              Please enter confirm password
                              </div>
                              <div *ngIf="f.confirmNewPassword.errors.minlength">
                              Password should be minimum 6 digit
                              </div>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>