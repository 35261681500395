import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiUrl } from "../../../../utils/config";
import { NotificationsService } from "../../../../services/notifications.service";

@Component({
  selector: 'app-content-creation',
  templateUrl: './content-creation.component.html',
  styleUrls: ['./content-creation.component.css']
})
export class ContentCreationComponent implements OnInit {

  ethicalSourcingDetail: any;
  currentPages = 1;
  postsPerPage = 10;
  form !: FormGroup;
  tableSize = 10;
  tutorialList: any;
  submitted = false;
  loading = false;
  count = 0;
  page = 1;
  idToShow: any
  base: any = ApiUrl;
  selectedGroup:any
  selected_image:any
  imgUrl = "/assets/img/user.png";
  groupList :any
  imageToUpload: any;
  locationList:any
  crystalList:any
  storeList:any
  selectedForm:any
  crystal:any
  store:any

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getTutorialList(1);
    this.updatefromControl()
    this.getGroups()
    this.getLanguageList()
    this.getAllCrystal()
    this.getAllStore()
  }

  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }

  getGroups = () => {
        this.userService.getAllGroup().toPromise().then(response => {
            if (response.success) {
                this.groupList = response.data;
                this.selectedGroup = this.groupList[0].groupName
            }
        });
    }
  getAllCrystal = () => {
      this.userService.getAllCrystal().toPromise().then(response => {
          if (response.success) {
              this.crystalList = response.data;
          }
      });
  }
  getAllStore = () => {
      this.userService.getAllStore().toPromise().then(response => {
          if (response.success) {
              this.storeList = response.data;
          }
      });
  }

  onFileChange(evt:any){

    this.imageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
        this.imgUrl = e.target.result;
    };
  }

  changeGroup(evt:any){
    let name  = evt.target.value
    
      this.selectedGroup=name
      
  }
  get grouped() { return this.f.grouped.value; }

  get selGroup() { return this.selectedGroup; }
  updatefromControl = () => {
    this.form = this.formbuilder.group({
        grouped: [true, [Validators.required]],
        groupType: ['', [Validators.required]],
        canClose: [true, [Validators.required]],
        title: ['', [Validators.required]],
        description: ['', [Validators.required]],
        interestFlag: [true, [Validators.required]],
        contentOrder: ['', [Validators.required,Validators.pattern(/^[0-9]+$/)]],
        distribution: ['', [Validators.required]],
        postDate: ['', [Validators.required]],
        endDate: ['', [Validators.required]],
        link: ['', [Validators.required]],
        notificationSubTitle: ['', [Validators.required]],
        color: ['', [Validators.required]],
        location: ['', [Validators.required]],
        crystal: ['', [Validators.required]],
        store: ['', [Validators.required]],
      
    })
  }

  
  onChange = (e: any, id: any) => {
    let message = !e ? false : true
    let data = {
      id: id,
      interestFlag: !e ? false : true
    }
    this.userService.interestFlag(data).toPromise().then(response => {
      if (response.success) {
        // this.notify.showSuccess(message)
      }
    })
  }

  getTutorialList = (page: Number) => {
    this.userService.getContents(page).toPromise().then(response => {
      if (response.success) {
        this.tutorialList = response.data.result;
        this.count = response.data.count;
      }
    });
  }
    formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
  getEthicalSourcingDetail = () => {

    if(this.ethicalSourcingDetail.crystal!==undefined){
        this.crystal = this.ethicalSourcingDetail.crystal._id
    }
    if(this.store = this.ethicalSourcingDetail.store!==undefined){
        this.store = this.ethicalSourcingDetail.store._id
    }

    if(this.store = this.ethicalSourcingDetail.image!==undefined && this.ethicalSourcingDetail.image!==''){
        this.imgUrl = this.base + '/'+ this.ethicalSourcingDetail.image
    }

    let postDate = this.formatDate(this.ethicalSourcingDetail.postDate)
    let endDate = this.formatDate(this.ethicalSourcingDetail.endDate)

    let group_type={}
    if(this.ethicalSourcingDetail.groupType!==undefined ){
      group_type = this.ethicalSourcingDetail.groupType
      
  }
    // this.ethicalSourcingDetail.groupType._id
    
    this.form = this.formbuilder.group({

        grouped: [this.ethicalSourcingDetail.grouped, [Validators.required]],
        groupType: [group_type, [Validators.required]],
        canClose: [this.ethicalSourcingDetail.canClose, [Validators.required]],
        title: [this.ethicalSourcingDetail.title, [Validators.required]],
        description: [this.ethicalSourcingDetail.description, [Validators.required]],
        interestFlag: [this.ethicalSourcingDetail.interestFlag, [Validators.required]],
        contentOrder: [this.ethicalSourcingDetail.contentOrder, [Validators.required,Validators.pattern(/^[0-9]+$/)]],
        distribution: [this.ethicalSourcingDetail.distribution, [Validators.required]],
        postDate: [postDate, [Validators.required]],
        endDate: [endDate, [Validators.required]],
        link: [this.ethicalSourcingDetail.link, [Validators.required]],
        notificationSubTitle: [this.ethicalSourcingDetail.notificationSubTitle, [Validators.required]],
        color: [this.ethicalSourcingDetail.color, [Validators.required]],
        location: [this.ethicalSourcingDetail.location, [Validators.required]],
        crystal: [this.crystal, [Validators.required]],
        store: [this.store, [Validators.required]],
    })
  }

  get f() {
    return this.form.controls;
  }

  viewStore = (data: any) => {
    this.ethicalSourcingDetail = data;
    this.idToShow = data._id;
    this.getEthicalSourcingDetail();
    
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getTutorialList(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTutorialList(1);
  }

  changeOrder(evt:any, id:any) {  
    
    if(evt.target.value == '') {
      return;
    }
    var obj = {
      contentOrder:evt.target.value
    }
    this.userService
      .updateHomeContent(obj, id)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          this.getTutorialList(this.page);

        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });


  }

  submitForm = () => {
    this.submitted = true;
    const formData = new FormData();
    
    if (this.imageToUpload && this.imageToUpload[0]) {
        formData.append('image', this.imageToUpload[0]);
    }
    
    
    if(this.selGroup=='CRYSTAL OF THE MONTH') {
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            crystal:this.form.value['crystal'],
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            link: this.form.value['link'],
            notificationSubTitle: this.form.value['notificationSubTitle'],
            color: this.form.value['color'],
            location: this.form.value['location'],            
        }
    } else if(this.selGroup=='Featured Store'){
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            store: this.form.value['store'],
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            link: this.form.value['link'],
            notificationSubTitle: this.form.value['notificationSubTitle'],
            color: this.form.value['color'],
            location: this.form.value['location'],            
        }
    }else{
        this.selectedForm = {
            grouped: true,
            groupType: this.form.value['groupType'],
            canClose: this.form.value['canClose'],
            title: this.form.value['title'],
            description: this.form.value['description'],
            interestFlag: this.form.value['interestFlag'],
            contentOrder: this.form.value['contentOrder'],
            distribution: this.form.value['distribution'],
            postDate: this.form.value['postDate'],
            endDate: this.form.value['endDate'],
            link: this.form.value['link'],
            notificationSubTitle: this.form.value['notificationSubTitle'],
            color: this.form.value['color'],
            location: this.form.value['location'],            
        }
    }
    for (let key in this.selectedForm) {
        
        formData.append(key, this.form.value[key]);
    }
    if (!this.validate) {
        
        return;
    }
    this.loading = true;
    this.userService
      .updateHomeContent(formData, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: ' Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
          this.getTutorialList(this.page)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

  deleteStore = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteHomeContent(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getTutorialList(this.page)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }
  get validate(){

    var errors=0;
    
    if(this.selGroup == '' || this.selGroup == undefined){
        errors += 1
    }
    if(
        this.selGroup == 'CRYSTAL TIPS' ||
        this.selGroup == 'ASTROLOGICAL' ||
        this.selGroup == 'Chakra info' ||
        this.selGroup == 'SHOWCASE' ||
        this.selGroup == 'NEWS' 
    ){
        if(
            this.form.value['title']=='' || 
            this.form.value['description']=='' || 
            this.form.value['contentOrder']=='' || 
            this.form.value['distribution']=='' || 
            this.form.value['postDate']=='' || 
            this.form.value['endDate']=='' || 
            this.form.value['link']=='' || 
            this.form.value['notificationSubTitle']=='' || 
            this.form.value['color']=='' || 
            this.form.value['location']=='' 
        
        ){
            errors += 1
        }
    }
    if(
        this.selGroup == 'CRYSTAL OF THE MONTH' 
    ){
        if(
            this.form.value['title']=='' || 
            this.form.value['crystal']=='' || 
            this.form.value['contentOrder']=='' || 
            this.form.value['distribution']=='' || 
            this.form.value['postDate']=='' || 
            this.form.value['endDate']=='' || 
            this.form.value['link']=='' || 
            this.form.value['notificationSubTitle']=='' || 
            this.form.value['color']=='' || 
            this.form.value['location']=='' 
        
        ){
            errors += 1
        }
    }

    if(
        this.selGroup == 'Featured Store' 
    ){
        if(
            this.form.value['title'] == '' || 
            this.form.value['store'] == '' || 
            this.form.value['contentOrder'] == '' || 
            this.form.value['distribution'] == '' || 
            this.form.value['postDate'] == '' || 
            this.form.value['endDate'] == '' || 
            this.form.value['link'] == '' || 
            this.form.value['notificationSubTitle'] == '' || 
            this.form.value['color'] == '' || 
            this.form.value['location'] == '' 
        
        ){
            errors += 1
        }
    }
    if(
        this.form.value['endDate'] < this.form.value['postDate'] 
    ){
        errors += 1
    }
    if(
        !this.f.contentOrder.valid
    ){
        errors += 1
    }
    
    if(errors>0){
        return false;
    }else{
        return true;
    }

    
  }

}
