<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <div class="row">
                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0 mt-0">
                                Content Criteria Listing
                                <span class="float-right">
                                    <a class="btn btn-primary" [routerLink]="['/admin/addContentCriteria']">Add
                                        Content Criteria
                                    </a>
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 ">
                          
                        </div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right" style="margin-left: 10px;">
                            <span>
                              Showing 
                              <b>
                                <ng-container *ngIf="subscriptionList">
                                  {{ subscriptionList.length>0?(currentPages - 1) * postsPerPage+1:0}}
            
                                </ng-container>
                              </b>
                              -
                              <b>{{ ((currentPages - 1) * postsPerPage+1) + (subscriptionList?subscriptionList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table width="100%" class="table table-bordered table-striped table-crystal">
                            <thead>
                                <th>Sr No.</th>
                                <th>Name</th>
                                <th>Query</th>
                             
                                <th>Actions</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let subscription of subscriptionList | paginate : {
								itemsPerPage: tableSize,
								currentPage: page,
								totalItems: count
							  }; index as i">

                                    <td>{{ (currentPages - 1) * postsPerPage + i + 1 }}</td>
                                    <td>{{subscription.name}}</td>
                                    <td>{{subscription.query}}</td>
                                  
                                    <td>
                                        <a data-toggle="modal" data-target="#viewSubscription"
                                            (click)="viewSubscription(subscription)" class="text-succes mr-1">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                        <a data-target="#deleteSubscription"
											(click)="deleteSubscription(subscription._id)" class="text-danger">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <pagination-controls previousLabel="Prev" nextLabel="Next"
                     (pageChange)="onTableDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteSubscription" role="dialog">
        <div class="modal-dialog">
          
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 class="modal-title">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this data?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="viewSubscription">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header p-0">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="card">
                    <div class="card-content">
                        <div class="row">

                            <div class="col-lg-12 ">
                                <h3 class="text-lg mb-0">
                                    Content Criteria Info
                                </h3>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form [formGroup]="subscriptionForm" (ngSubmit)="updateGroupInfo()"
                                            class="custom-mt-form">

                                            <div class="row m-0">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <div class="form-group custom-mt-form-group">
                                                        <label class="control-label">Name</label>
                                                        <input formControlName="name" type="text" class="form-control"
                                                            placeholder="Enter Name">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <div class="form-group custom-mt-form-group">
                                                        <label class="control-label">Query</label>
                                                        <input formControlName="query" type="text" class="form-control"
                                                            placeholder="Enter Query">
                                                    </div>
                                                </div>




                                                <div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Created At</label>
														
														<input  type="text" class="form-control" readonly formControlName="createdAt"
														placeholder="Created At">
													</div>
													
												</div>
												<div class="col-lg-6 col-md-6 col-12">
													<div class="form-group custom-mt-form-group">
														<label class="control-label">Updated At</label>
														
														<input  type="text" class="form-control" readonly formControlName="updatedAt"
														placeholder="Updated At">
													</div>
													
												</div>





                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <button class="btn btn-success mr-2" type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>