<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                   

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="addSubscriptionForm" (ngSubmit)="addSubscription()" class="custom-mt-form">
                                      
                                        <div class="row m-0">
                                        	<div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
													<label class="control-label">Credit Id</label> 
                                                    <input 
                                                        formControlName="creditId"
														readonly
														type="text"
														class="form-control"
														placeholder="Credit Id">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.creditId.errors"
                                                    class="invalid-feedback"
                                                >
                                                    
                                                    <div *ngIf="f.creditId.errors.required">
                                                        Please generate credit Id
                                                    </div>
                                                        
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
												
                                                    
                                                    <button (click)="getCreditCode($event)" class="btn btn-primary">Generate Credit Id</button>
                                                </div>
                                                
                                            </div>
                                        
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Duration Type</label> 
                                                    <select
                                                        class="form-control" >
                                                        <option value="weekly" >Weekly</option>
                                                        
                                                    </select>
                                                </div>
                                               
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
													<label class="control-label">Subscription</label> 
                                                    <select 
                                                        formControlName="subscriptionId"
                                                        class="form-control"
                                                    >
                                                        <option>Select subscription</option>
                                                        <option *ngFor="let item of subscriptionList" value="{{item._id}}">{{item.planName}}</option>
                                                    </select>
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.subscriptionId.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.subscriptionId.errors.required">
                                                        Please select subscription
                                                    </div>
                                                    <div *ngIf="f.subscriptionId.errors.pattern">
                                                        Please enter valid subscriptionId
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Credit To</label> 
                                                    
                                                    <ng-select2 
                                                        [(ngModel)]="creditTo"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [data]="selectToData"
                                                        [placeholder]="'Please select a user...'">		
                                                    </ng-select2>
                                                </div>
                                                <div
                                                    *ngIf="submitted && creditTo==''"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="creditTo==''">
                                                        Please select credit to
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
													<label class="control-label">Number of Weeks</label> 
                                                    <input 
														formControlName="quantity"
														type="text"
														class="form-control"
														placeholder="Number of Weeks">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.quantity.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.quantity.errors.required">
                                                        Please enter number of weeks
                                                    </div>
                                                    <div *ngIf="f.quantity.errors.pattern">
                                                        Please enter valid number
                                                    </div>
                                                        
                                                </div>
                                            </div>
                                           
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
