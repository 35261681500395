<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Stores & More
                        <span class="float-right">
                            <a class="btn btn-primary" [routerLink]="['/admin/addStore']">Add New Store</a>
                        </span>
                    </h3>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-md-3 ">
                            <div class="search-box" style="">
								<form class="search-form">
									<input class="form-control" placeholder="Search shop name here..." type="text" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup)="onSearch($event)">
									<span class="search-btn">
										
									</span>
								</form>
							</div>
                        </div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            
                            <span>
                                Showing 
                                <b>
                                    <ng-container *ngIf="storeList">
                                        {{ storeList.length>0?(currentPages - 1) * postsPerPage+1:0}}

                                    </ng-container>
                                </b>
                                -
                                <b>{{ ((currentPages - 1) * postsPerPage+1) + (storeList?storeList.length-1:0)}}</b>
                                of 
                                <b>{{count}}</b>
                                 items
                            </span>
                          </div>
                        </div>
                    </div>
                    
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="whitespace-no-wrap">Sr No.</th>
                                    <th class="whitespace-no-wrap">Shop Name</th>
                                    <th class="text-center whitespace-no-wrap">Description</th>
                                    <th class="text-center whitespace-no-wrap">Address</th>
                                    <th class="text-center whitespace-no-wrap">Website</th>
                                    <th class="text-center whitespace-no-wrap">First Name</th>
                                    <th class="text-center whitespace-no-wrap">Last Name</th>
                                    <th class="text-center whitespace-no-wrap">Title</th>
                                    <th class="text-center whitespace-no-wrap">Mobile Number</th>
                                    <th class="text-center whitespace-no-wrap">Email</th>
                                    <th class="text-center whitespace-no-wrap">Ethical Ranking</th>
                                    <th class="text-center whitespace-no-wrap">Quality Ranking</th>
                                    <th class="text-center whitespace-no-wrap">Experience Ranking</th>
                                    <th class="text-center whitespace-no-wrap">Speciality</th>
                                    <th class="text-center whitespace-no-wrap">Brief Description</th>
                                    <th class="text-center whitespace-no-wrap">Shop</th>
                                    <th class="text-center whitespace-no-wrap">Partner</th>
                                    <th class="text-center whitespace-no-wrap">Service</th>
                             

                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let store of storeList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{store.shopName}}</td>
                                    <td class="text-center">{{store.description}}</td>
                                    <td class="text-center">{{store.address}}</td>
                                    <td class="text-center">{{store.website}}</td>
                                    <td class="text-center">{{store.firstName}}</td>
                                    <td class="text-center">{{store.lastName}}</td>
                                    <td class="text-center">{{store.title}}</td>
                                    <td class="text-center">{{store.mobileNumber}}</td>
                                    <td class="text-center">{{store.email}}</td>
                                    <td class="text-center">{{store.ethicalRanking}}</td>
                                    <td class="text-center">{{store.qualityRanking}}</td>
                                    <td class="text-center">{{store.experienceRanking}}</td>
                                    <td class="text-center">{{store.speciality}}</td>
                                    <td class="text-center">
                                        {{store.briefDescription}}
                                    </td>
                                    <td class="text-center">
                                        {{store.shop}}
                                    </td>
                                    <td class="text-center">
                                        {{store.partner}}
                                    </td>
                                    <td class="text-center">
                                        {{store.service}}
                                    </td>
                                

                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewStore" (click)="viewStore(store)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                            <a 
											(click)="deleteStore(store._id)" class="text-danger"><i
												class="fa fa-trash"></i>
                                            </a>
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr class="intro-x" *ngIf="storeList!=undefined && storeList.length==0">
                                    <td class="text-center" colspan="18">
                                        No record found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteStore" role="dialog">
        <div class="modal-dialog">
            
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 class="modal-title">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this data?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewStore" >        
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>   
          <div class="card">
                  <div class="card-content">
                      <div class="row">
                         
                          <div class="col-lg-12 ">
                              <h3 class="text-lg mb-0">
                                Store Info 
                              </h3>
                          </div>
                      </div>
                      <div class="row mt-3">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div class="card">
                                  <div class="card-body">
                                      <form [formGroup]="updateUser" (ngSubmit)="updateStore()" class="custom-mt-form">
                                          
                                          <div class="d-flex flex-wrap">
                                            <div class="">
												<h4 class="text-center"> Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="imageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploadss">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploadss" class="file-upload"
															(change)="onImageChangeRaw($event,'image')" type="file"
															accept="image/*">
                                                        </div>
                                                        <h4 class="text-center"> 
                                                            
                                                        <a *ngIf="checkImg" class="delete_img text-danger" (click)="removeImg($event,'image')"><i  class="fa fa-trash"></i></a>
                                                    </h4>
												</div>
												
											</div>
											<div class="ml-4">
												<h4 class="text-center"> Thumbnail Image</h4>

												<div class="columns">
													<div class="circle">
														<img class="w-100 h-100" [src]="thumbnail_imageUrl">
													</div>
													<div class="p-image">
														<label for="fileuploads2">
															<i class="fa fa-camera upload-button"></i>
														</label>
														<input id="fileuploads2" class="file-upload"
															(change)="onImageChangeRaw($event,'thumbnail_image')" type="file"
															accept="image/*">
                                                        </div>
                                                        <h4 class="text-center"> 
                                                    
                                                    <a *ngIf="checkThumbImg" class="delete_img text-danger" (click)="removeImg($event,'thumbnail_image')"><i  class="fa fa-trash"></i></a>
                                                    </h4>
												</div>
												
											</div>
                                        </div>
                                            
                                          <div class="row m-0">
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Shop Name</label> 
                                                      <input 
                                                      formControlName="shopName"
                                                      type="text"
                                                      class="form-control" 
                                                      placeholder="Enter Shop Name"
                                                      >
                                                  </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Website</label>
                                                      <input 
                                                      formControlName="website"
                                                      type="text" 
                                                      class="form-control" 
                                                      placeholder="Enter Website"
                                                      >
                                                  </div>
                                              </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="form-group custom-mt-form-group">
                                                        <label class="control-label">First Name</label>
                                                        <input formControlName="firstName" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="form-group custom-mt-form-group">
                                                        <label class="control-label">Last Name</label>
                                                        <input formControlName="lastName" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="form-group custom-mt-form-group">
                                                        <label class="control-label">Title</label>
                                                        <input formControlName="title" type="text" class="form-control">
                                                    </div>
                                                </div>
    
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Address</label> 
                                                      <input 
                                                      formControlName="address"
                                                      type="text"
                                                      class="form-control address" 
                                                      
                                                      
                                                       id="address">
                                                  </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">State</label>
                                                      <input 
                                                      formControlName="state"
                                                      type="text" 
                                                      class="form-control" 
                                                      placeholder="Enter State"
                                                      >
                                                  </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">City</label>
                                                      <input 
                                                      formControlName="city"
                                                      type="text" 
                                                      class="form-control" 
                                                      placeholder="Enter City"
                                                      >
                                                  </div>
                                              </div>
    
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Country</label>
                                                      <input 
                                                      formControlName="country"
                                                      type="text" 
                                                      class="form-control" 
                                                      placeholder="Enter country"
                                                      >
                                                  </div>
                                              </div>
    
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Zip</label>
                                                      <input 
                                                      formControlName="zip"
                                                      type="text" 
                                                      class="form-control"
                                                      placeholder="Enter Zip">
                                                  </div>
                                              </div>
                                              
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Mobile Number</label>
                                                      <input 
                                                      formControlName="mobileNumber"
                                                      type="text" 
                                                      class="form-control"
                                                      placeholder="Enter Mobile Number">
                                                  </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Email</label>
                                                      <input 
                                                      formControlName="email"
                                                      type="text" 
                                                      class="form-control" 
                                                      placeholder="Enter Email">
                                                  </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-12">
                                                  <div class="form-group custom-mt-form-group">
                                                      <label class="control-label">Speciality</label> 
                                                      <input 
                                                      formControlName="speciality"
                                                      type="text"
                                                      class="form-control" 
                                                      placeholder="Enter Speciality">
                                                  </div>
                                              </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Ethical Ranking</label> 
                                                    <input 
                                                    formControlName="ethicalRanking"
                                                    type="text"
                                                    class="form-control" 
                                                    placeholder="Ethical Ranking">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Quality Ranking</label> 
                                                    <input 
                                                    formControlName="qualityRanking"
                                                    type="text"
                                                    class="form-control" 
                                                    placeholder="Quality Ranking">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Experience Ranking</label> 
                                                    <input 
                                                    formControlName="experienceRanking"
                                                    type="text"
                                                    class="form-control" 
                                                    placeholder="Experience Ranking">
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label> 
                                                    <input 
                                                    formControlName="description"
                                                    type="text"
                                                    class="form-control" 
                                                    placeholder="Description">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Brief Description</label> 
                                                    <input 
                                                    formControlName="briefDescription"
                                                    type="text"
                                                    class="form-control" 
                                                    placeholder="Brief Description">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Ecommerce Link</label> <input
                                                        formControlName="ecommerceLink" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Facebook</label> <input
                                                        formControlName="facebook" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Instagram</label> <input
                                                        formControlName="instagram" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Twitter</label> <input
                                                        formControlName="twitter" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">EW Partner</label> 
                                                    
                                                    <select formControlName="EW_Partner" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Investigated</label> 
                                                    
                                                    <select formControlName="investigated" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Active</label> 
                                                    
                                                    <select formControlName="active" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Online Store</label> 
                                                    
                                                    <select formControlName="onlineStore" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Shop</label> 
                                                    
                                                    <select formControlName="shop" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Partner</label> 
                                                    
                                                    <select formControlName="partner" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Service</label> 
                                                    
                                                    <select formControlName="service" class="form-control">
                                                        <option [value]="true"> True </option>
                                                        <option [value]="false"> False </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Created At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="createdAt"
                                                    placeholder="Created At">
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Updated At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="updatedAt"
                                                    placeholder="Updated At">
                                                </div>
                                                
                                            </div>


                                          </div>
                                          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                              <div class="form-group custom-mt-form-group">
                                                  <button class="btn btn-success mr-2" type="submit">Submit</button>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</div>