/*@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUrl } from "../../../../utils/config";
import { NotificationsService } from "../../../../services/notifications.service";
const EMAIL_REGEX = /^(([^()\[\]\\.,;:\s@"]+(\.[^()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const pattern = new RegExp(
  // '^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

// declare let google: any;

@Component({
  selector: 'app-store-add',
  templateUrl: './store-add.component.html',
  styleUrls: ['./store-add.component.css']
})
export class StoreAddComponent implements OnInit {
  addStoreForm !: FormGroup;
  submitted = false;
  loading = false;
  base: any = ApiUrl;
  imgUrl = "/assets/img/user.png";
  imageToUpload: any;
  latitude:any
  longitude:any
  address:any
  image:any
    thumbnail_image:any
    imageUrl= "/assets/img/user.png";
    thumbnail_imageUrl= "/assets/img/user.png";


  

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.addStoreControler()
    // this.autocompleteSearch()
  }

  autocompleteSearch() {
           
        // var input = document.getElementById('address');
        // var autocomplete = new google.maps.places.Autocomplete(input);

        // autocomplete.addListener("place_changed", ()=>{
          
        //     let place = autocomplete.getPlace();
        //     if(place.geometry === undefined || place.geometry === null) {
        //        return;
        //     }
        //     this.latitude = place.geometry.location.lat();
        //     this.longitude = place.geometry.location.lng();
        //     this.address = place.formatted_address;
            
        //     this.addStoreForm.controls['address'].setValue(this.address);
        //     this.addStoreForm.controls['latitude'].setValue(this.latitude);
        //     this.addStoreForm.controls['longitude'].setValue(this.longitude);
            
        // })
  }

  addStoreControler = () => {
    this.addStoreForm = this.formbuilder.group({
      // category: ['', Validators.required],
      shopName: ['', Validators.required],
      description: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      country: ['', Validators.required],
      website: ['',[ Validators.required, Validators.pattern(pattern)]],
      mobileNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      ethicalRanking: ['', Validators.required],
      qualityRanking: ['', Validators.required],
      experienceRanking: ['', Validators.required],
    //   longitude: ['', Validators.required],
    //   latitude: ['', Validators.required],
      speciality: ['', Validators.required],
      briefDescription: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      title: ['', Validators.required],
      EW_Partner: [false],
      investigated: [false],
      active: [false],
      onlineStore: [false],
      ecommerceLink: ['',Validators.pattern(pattern)],
      facebook: ['',Validators.pattern(pattern)],
      instagram: ['',Validators.pattern(pattern)],
      twitter: ['',Validators.pattern(pattern)],
      shop: [false],
      partner: [false],
      service: [false],

    })
  }
  get f() {
    return this.addStoreForm.controls;
  }
  onFileChange = (fileInput: any) => {
    this.imageToUpload = <Array<File>>fileInput.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
      this.imgUrl = e.target.result;
    };
  };

  validURL(str:any) {
    return !pattern.test(str);
  }
    onImageChangeRaw= (fileInput: any,type:any) => {
        if(type=='image'){
            this.image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.image[0]);
            reader.onload = (e: any) => {
                this.imageUrl = e.target.result;
            };
        }
        if(type=='thumbnail_image'){
            this.thumbnail_image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.thumbnail_image[0]);
            reader.onload = (e: any) => {
                this.thumbnail_imageUrl = e.target.result;
            };
        }
        
    }

    changeAddress(evt:any){

    }

  placeChangedCallback(evt:any){
    
    var lat = evt.geometry.location.lat;
    var long = evt.geometry.location.lng;
    
  }

  addStore = () => {
    this.submitted = true;
    if (this.addStoreForm.invalid) {

    //   var error=[];
    //   for (let key in this.addStoreForm.value) {
    //     if(this.addStoreForm.value[key]==''){
    //       error.push(key);
    //     }
    //   }
    //   if(error[0] == undefined){
    //     this.notify.showError('Please enter proper email')
    //     return;
    //   }
    // //   if(error[0] == 'longitude' || error[0] == 'latitude'){
    // //     this.notify.showError('Please select address from suggestions')
    // //     return;
    // //   }
    //   this.notify.showError('Please fill the '+error[0])
      // return;
    }
    const formData = new FormData();
    // if (this.imageToUpload && this.imageToUpload[0]) {
    //   formData.append('storeImage', this.imageToUpload[0]);
    // }
    if (this.image && this.image[0]) {
        formData.append('storeImage', this.image[0]);
    }
    if (this.thumbnail_image && this.thumbnail_image[0]) {
        formData.append('thumbnail_image', this.thumbnail_image[0]);
    }
    
    for (let key in this.addStoreForm.value) {
        formData.append(key, this.addStoreForm.value[key]);
    }
    if (!formData.get("storeImage")) {
        this.notify.showError('Please select store image')
        return;
    }
    if (!formData.get("thumbnail_image")) {
        this.notify.showError('Please select thumbnail image')
        return;
    }
    // if (this.addStoreForm.value['category']=='') {
    //   this.notify.showError('Please select category')
    //   return;
    // }
    if (this.addStoreForm.value['shopName']=='') {
      this.notify.showError('Please enter shop name')
      return;
    }
    
    
    
    this.loading = true;
    this.userService
      .addStore(formData)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Store Added Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.router.navigate(["/admin/store"]);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }
}
