<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <!-- <div class="row">
                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0 mt-0">
                               Add Crystal <span class="float-right">
                               	<a class="btn btn-primary" href="#0">Add New Crystal</a></span>
                            </h3>
                        </div>
                    </div> -->

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="addSubscriptionForm" (ngSubmit)="addSubscription()" class="custom-mt-form">
                                      
                                        <div class="row m-0">
                                        	<div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
													<label class="control-label">Page</label> 
                                                    <input 
														formControlName="page"
														type="text"
														class="form-control"
														placeholder="Page">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.page.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.page.errors.required">
                                                        Please enter page
                                                    </div>
                                                    <div *ngIf="f.page.errors.pattern">
                                                        Please enter valid page
                                                    </div>
                                                        
                                                </div>
                                            </div>
                                            
                                           
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
