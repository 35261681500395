<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    
                    <h3 class="text-lg mb-0">
                        All Constant Config
                        <span class="float-right">
                            <a class="btn btn-primary" [routerLink]="['/admin/addConstantConfig']">Add Constant Config </a>
                        </span>
                    </h3>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-md-3 ">
                            <div class="search-box" style="">
								<form class="search-form">
									<input class="form-control" placeholder="Search label here..." type="text" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup)="onSearch($event)">
									<span class="search-btn">
										
									</span>
								</form>
							</div>
                        </div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            
                            <span>
                                Showing 
                                <b>
                                    <ng-container *ngIf="tutorialList">
                                        {{ tutorialList.length>0?(currentPages - 1) * postsPerPage+1:0}}

                                    </ng-container>
                                </b>
                                -
                                <b>{{ ((currentPages - 1) * postsPerPage+1) + (tutorialList?tutorialList.length-1:0)}}</b>
                                of 
                                <b>{{count}}</b>
                                 items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">Title</th>
                                    <th class="text-center whitespace-no-wrap">Label</th>
                                    <th class="text-center whitespace-no-wrap">English Value</th>
                                    <th class="text-center whitespace-no-wrap">Description</th>
                                  
                                    <th class="text-center whitespace-no-wrap">Web Admin</th>
                                    <th class="text-center whitespace-no-wrap">View Order</th>
                                    <th class="text-center whitespace-no-wrap">System</th>
                                    <th class="text-center whitespace-no-wrap">Default</th>
                                    <th class="text-center whitespace-no-wrap">UX</th>
                                    <th class="text-center whitespace-no-wrap">Local</th>
                                  

                                    <th width="10%" class="text-center whitespace-no-wrap">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let tutorial of tutorialList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{tutorial.title}}</td>
                                    <td class="text-center">{{tutorial.label}}</td>
                                    <td class="text-center">{{tutorial.englishValue}}</td>
                                    <td class="text-center" [innerHTML]="tutorial.description"></td>
                                  
                                    <td class="text-center">{{tutorial.webAdmin}}</td>
                                    <td class="text-center">{{tutorial.viewOrder}}</td>
                                    <td class="text-center">{{tutorial.system}}</td>
                                    <td class="text-center">{{tutorial.default}}</td>
                                    <td class="text-center">{{tutorial.UX}}</td>
                                    <td class="text-center">{{tutorial.local}}</td>
                                

                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewStore" (click)="viewStore(tutorial)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                          
                                                <a href="javascript:void(0);"
											(click)="deleteStore(tutorial._id,tutorial.label)" class="text-danger"><i
												class="fa fa-trash"></i></a>
                                          
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr class="intro-x" *ngIf="tutorialList!=undefined && tutorialList.length==0">
                                    <td class="text-center" colspan="14">
                                      No record found
                                    </td>
                                  </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteStore" role="dialog">
        <div class="modal-dialog">
       
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 class="modal-title">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this data?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewStore">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Constant Config Info
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="updateUser" (ngSubmit)="updateProfile()" class="custom-mt-form">
                                        <div class="row m-0"  *ngIf="titleShow">
                                          

                                            <div class="columns">
                                                <div class="circle">
                                                    <div class="pic-upload">
                                                        <img [src]="imgUrl ?imgUrl :'assets/img/user.png'" class="w-100 h-100" />
                                                    </div>
                                                  
                                                </div>
                                                <div class="p-image">
                                                    <label for="fileuploadss">
                                                        <i class="fa fa-camera upload-button"></i>
                                                    </label>
                                                    <input id="fileuploadss" class="file-upload"
                                                        (change)="onFileChange($event)" type="file"
                                                        accept="image/*">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row m-0">
                                           
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Type</label>
                                                  
                                                    <select class="form-control" formControlName="type">
                                                        <option value="Text">
                                                            Text
                                                        </option>
                                                        <option value="Image">
                                                            Image
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div *ngIf="titleShow" class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Title</label>
                                                    <input formControlName="title" type="text" class="form-control"
                                                        placeholder="Enter Title">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Label</label>
                                                    <input formControlName="label" type="text" class="form-control"
                                                        placeholder="Enter Label">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">English Value</label>
                                                    <input formControlName="englishValue" type="text" class="form-control"
                                                        placeholder="Enter English Value">
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Location</label>
                                                    <select formControlName="location" class="form-control">
                                                        <option value="">Select</option>
                                                        <option *ngFor="let pages of locationList" value="{{pages.page}}">{{pages.page}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div *ngIf="descriptionShow" class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label>
                                                    <editor formControlName="description"
                                                    ></editor>
                                                </div>
                                                <div *ngIf="!descriptionShow" class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label>
                                                    <input type="text" class="form-control" formControlName="description" placeholder="Enter Description"/>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Web Admin</label>
                                                    <select class="form-control" formControlName="webAdmin">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">View Order</label>
                                                    <input type="text" formControlName="viewOrder"
                                                        class="form-control" placeholder="View Order">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.viewOrder.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.viewOrder.errors.required">
                                                    Please enter view order
                                                    </div>
                                                    <div *ngIf="f.viewOrder.errors.pattern">
                                                    Please enter valid number
                                                    </div>
                                                        
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">System	</label>
                                                    <select class="form-control" formControlName="system">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Default</label>
                                                    <select class="form-control" formControlName="default">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">UX</label>
                                                    <select class="form-control" formControlName="UX">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Local</label>
                                                    <select class="form-control" formControlName="local">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>
                                            </div>


                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Created At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="createdAt"
                                                    placeholder="Created At">
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Updated At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="updatedAt"
                                                    placeholder="Updated At">
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>