<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Rating
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * tableSize+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * tableSize+1) + (list?list.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">rating</th>
                                    <th class="text-center whitespace-no-wrap">rate_by</th>
                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let item of list | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * tableSize + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">
                                        {{item.rating}}
                                    </td>
                                   
                                    <td class="text-center">{{ item.rate_by?item.rate_by.guId:'' }}</td>
                                    <td class="text-center">{{item.createdAt}}</td>
                                    <td class="text-center">{{item.updatedAt}}</td>

                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>
<div class="modal" id="viewSubscription">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
              <div class="modal-body">
                  <h4 class="mb-4">rating info</h4>
                  <div class="row">
                    
                  </div>
                  <div class="detailed-view table-responsive">
                      <table width="100%" class="crystal-detail table table-bordered table-striped">
                            <tr>
                                <th>rating</th>
                                <td>{{ reportedPostDetails?reportedPostDetails.rating:'' }}
                                </td>
                                <th>rate_by</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.rate_by:""}}
                                </td>
                            </tr>
                            <tr>
                                <th>createdAt</th>
                                <td>{{reportedPostDetails ? reportedPostDetails.createdAt:""}}</td>
                                <th>updatedAt</th>
                                <td><ng-container *ngIf="reportedPostDetails!=undefined">
                                    {{ reportedPostDetails?reportedPostDetails.updatedAt:'' }} 
                                    </ng-container>
                                </td>
                            </tr>
                            
                      </table>
                  </div>
              </div>
          </div>
      </div>
    
</div>