/*@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUrl } from "../../../../utils/config";

@Component({
    selector: 'app-add-crystal',
    templateUrl: './add-crystal.component.html',
    styleUrls: ['./add-crystal.component.css']
})
export class AddCrystalComponent implements OnInit {
    addCrystalForm !: FormGroup;
    items!: FormArray;
    submitted = false;
    loading = false;
    secondaryChakraArray: any = [];
    primaryChakraArray: any = [];
    physicalArray: any = [];
    spiritualArray: any = [];
    colorArray: any = [];
    mineralClassArray: any = [];
    astrologicalSignArray: any = [];
    locationArray: any = [];
    numericalVibrationArray: any = [];
    alternateStoneNameArray: any = [];
    emotionalArray: any = [];
    base: any = ApiUrl;
    imgUrl = "/assets/img/user.png";
    thumbnailUrl = "/assets/img/user.png";

    rawImageUrl = "/assets/img/user.png";
    raw_thumbnail_imageUrl = "/assets/img/user.png";
    polishedImageUrl = "/assets/img/user.png";
    polished_thumbnail_imageUrl = "/assets/img/user.png";

    imageToUpload: any;
    thumbnail: any;

    rawImage: any;
    raw_thumbnail_image: any;
    polishedImage: any;
    polished_thumbnail_image: any;

    constructor(private userService: UserService, private formbuilder: FormBuilder, private router: Router, private activateRoute: ActivatedRoute) { }


    ngOnInit(): void {
        this.addCrystalControler()
    }

    removeButton(type:any,i:any){

        if(type=='physicalArray'){
            this.physicalArray.splice(i,1)
        }
        if(type=='primaryChakraArray'){
            this.primaryChakraArray.splice(i,1)
        }
        if(type=='alternateStoneNameArray'){
            this.alternateStoneNameArray.splice(i,1)
        }
        if(type=='emotionalArray'){
            this.emotionalArray.splice(i,1)
        }
        if(type=='numericalVibrationArray'){
            this.numericalVibrationArray.splice(i,1)
        }
        if(type=='locationArray'){
            this.locationArray.splice(i,1)
        }
        if(type=='astrologicalSignArray'){
            this.astrologicalSignArray.splice(i,1)
        }
        if(type=='mineralClassArray'){
            this.mineralClassArray.splice(i,1)
        }
        if(type=='colorArray'){
            this.colorArray.splice(i,1)
        }
        if(type=='spiritualArray'){
            this.spiritualArray.splice(i,1)
        }
        if(type=='secondaryChakraArray'){
            this.secondaryChakraArray.splice(i,1)
        } 
    }

    onFileChange = (fileInput: any) => {
        this.imageToUpload = <Array<File>>fileInput.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(this.imageToUpload[0]);
        reader.onload = (e: any) => {
            this.imgUrl = e.target.result;
        };
    };

    onImageChange = (fileInput: any) => {
        this.thumbnail = <Array<File>>fileInput.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(this.thumbnail[0]);
        reader.onload = (e: any) => {
            this.thumbnailUrl = e.target.result;
        };
    };

    onImageChangeRaw = (fileInput: any) => {

        this.rawImage = <Array<File>>fileInput.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(this.rawImage[0]);
        reader.onload = (e: any) => {
            this.rawImageUrl = e.target.result;
        };
    };
    onImageChangeraw_thumbnail = (fileInput: any) => {
        
        this.raw_thumbnail_image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.raw_thumbnail_image[0]);
            reader.onload = (e: any) => {
                this.raw_thumbnail_imageUrl = e.target.result;
        };
    };
    onImageChangepolishedImage = (fileInput: any) => {

        this.polishedImage = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.polishedImage[0]);
            reader.onload = (e: any) => {
                this.polishedImageUrl = e.target.result;
        };
    };
    onImageChangepolished_thumbnail_image = (fileInput: any) => {

        this.polished_thumbnail_image = <Array<File>>fileInput.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(this.polished_thumbnail_image[0]);
            reader.onload = (e: any) => {
                this.polished_thumbnail_imageUrl = e.target.result;
            };
    };


    addSecondaryChakra(newSecondaryChakra: string) {
        if (newSecondaryChakra) {
            this.secondaryChakraArray.push(newSecondaryChakra);
        }
    }

    addPrimaryChakra(dataToSet: string) {
        if (dataToSet) {
            this.primaryChakraArray.push(dataToSet);
        }
    }

    addPhysical(dataToSet: string) {
        if (dataToSet) {
            this.physicalArray.push(dataToSet);
        }
    }

    addColor(dataToSet: string) {
        if (dataToSet) {
            this.colorArray.push(dataToSet);
        }
    }

    addMineralClass(dataToSet: string) {
        if (dataToSet) {
            this.mineralClassArray.push(dataToSet);
        }
    }

    addAstrologicalSign(dataToSet: string) {
        if (dataToSet) {
            this.astrologicalSignArray.push(dataToSet);
        }
    }

    addSpiritual(dataToSet: string) {
        if (dataToSet) {
            this.spiritualArray.push(dataToSet);
        }
    }

    addLocation(dataToSet: string) {
        if (dataToSet) {
            this.locationArray.push(dataToSet);
        }
    }

    addNumericalVibration(dataToSet: string) {
        if (dataToSet) {
            this.numericalVibrationArray.push(dataToSet);
        }
    }

    addAlternateStoneName(dataToSet: string) {
        if (dataToSet) {
            this.alternateStoneNameArray.push(dataToSet);
        }
    }

    addEmotional(dataToSet: string) {
        if (dataToSet) {
            this.emotionalArray.push(dataToSet);
        }
    }

    addCrystalControler = () => {
        this.addCrystalForm = this.formbuilder.group({
            crystalName: ['', Validators.required],
            thumbnail_image: [''],
            image: [''],
            description: [''],
            knownAs: [''],
            knownFor: [''],
            rarity: [''],
            chemicalComposition: [''],
            crystalSystem: [''],
            ethicalSourced: [''],
            hardness: [''],
            pronunciation: [''],
            affirmation: [''],
            brain:['0']
        })
    }

    get f() {
        return this.addCrystalForm.controls;
    }

    addCrystal = () => {
        this.submitted = true;
        const formData = new FormData();

        if (this.rawImage && this.rawImage[0]) {
            formData.append('rawImage', this.rawImage[0]);
        }
        if (this.raw_thumbnail_image && this.raw_thumbnail_image[0]) {
            formData.append('raw_thumbnail_image', this.raw_thumbnail_image[0]);
        }
        if (this.polishedImage && this.polishedImage[0]) {
            formData.append('polishedImage', this.polishedImage[0]);
        }
        if (this.polished_thumbnail_image && this.polished_thumbnail_image[0]) {
            formData.append('polished_thumbnail_image', this.polished_thumbnail_image[0]);
        }

       
        for (let key in this.addCrystalForm.value) {
            formData.append(key, this.addCrystalForm.value[key]);
        }
        if (this.addCrystalForm.invalid) {
            return;
        }
        if (!formData.get("rawImage") && !formData.get("raw_thumbnail_image") && !formData.get("polishedImage") && !formData.get("polished_thumbnail_image")) {
            return;
        }
        
        formData.append('secondaryChakra', this.secondaryChakraArray);
        formData.append('primaryChakra', this.primaryChakraArray);
        formData.append('physical', this.physicalArray);
        formData.append('spiritual', this.spiritualArray);
        formData.append('color', this.colorArray);
        formData.append('mineralClass', this.mineralClassArray);
        formData.append('astrologicalSign', this.astrologicalSignArray);
        formData.append('location', this.locationArray);
        formData.append('numericalVibration', this.numericalVibrationArray);
        formData.append('alternateStoneName', this.alternateStoneNameArray);
        formData.append('emotional', this.emotionalArray);

        this.loading = true;
        this.userService
            .addCrystal(formData)
            .toPromise()
            .then((resp) => {
                if (resp.success) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Crystal Added Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.router.navigate(["/admin/crystal"]);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            });
    }
}
