<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    
                    <div class="row">
						<div class="col-lg-12 ">
							<h3 class="text-lg mb-0 mt-0">
								Notification Management<span class="float-right col-lg-2">
                                    <div class="row">
                                        <div class="col-lg-2 ">
                                            <label class="text-lg">Filter</label>
                                        </div>
                                        <div class="col-lg-10">
                                            <select class="form-control" (change)="changeFilter($event)">
                                                <option value="">All</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Sent">Sent</option>
                                            </select>
                                        </div>
                                    </div>
                                    
									<!-- <a class="btn btn-primary" [routerLink]="['/admin/add-notification-management']">Add Notification</a> -->
                                </span>
                               
							</h3>
						</div>
					</div>
                    
                    <div class="row">
                        <div class="col-md-3 ">
                          
                        </div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right" style="margin-left: 10px;">
                            <span>
                              Showing 
                              <b>
                                <ng-container *ngIf="reportedPostList">
                                  {{ reportedPostList.length>0?(currentPages - 1) * postsPerPage+1:0}}
            
                                </ng-container>
                              </b>
                              -
                              <b>{{ ((currentPages - 1) * postsPerPage+1) + (reportedPostList?reportedPostList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">Logo</th>
                                    <th class="text-center whitespace-no-wrap">Notification Title</th>
                                    <th class="text-center whitespace-no-wrap">Notification Sub Title</th>
                                    
                                    <th class="text-center whitespace-no-wrap">Link </th>
                                    <th class="text-center whitespace-no-wrap">Push Notification  </th>
                                    <th class="text-center whitespace-no-wrap">Status</th>
                                    <!-- <th class="text-center whitespace-no-wrap">Notification Date  </th> -->
                                    <th class="text-center whitespace-no-wrap">Notification Status  </th>
                                    <!-- <th class="text-center whitespace-no-wrap">Created At</th>
                                    <th class="text-center whitespace-no-wrap">Updated At</th> -->

                                    <th class="text-center whitespace-no-wrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let post of reportedPostList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">
                                        <!-- {{post.logo}} -->
                                        <div class="columns">
                                            <div class="circle">
                                                <div class="pic-upload">
                                                    <img 
                                                    
                                                    src="{{
                                                        post 
                                                          ? base + '/'+ post.logo
                                                          : 'assets/img/user.png'
                                                      }}" class="w-100 h-100" />
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                    
                                    </td>
                                    <td class="text-center">{{post.notificationTitle}}
                                        
                                    </td>
                                    <td class="text-center">{{post.notificationSubTitle}}</td>
                                    
                                    <td class="text-center">{{post.notificationLink}}</td>
                                    <td class="text-center">{{post.pushNotification}}</td>
                                    <!-- <td class="text-center">{{post.status}}</td> -->
                                    <td>
										<ui-switch  (change)="onChange($event,post._id)" [checked]="post.status"></ui-switch>
									</td>
                                  
                                    <!-- <td class="text-center">{{post.notificationDate }}</td> -->
                                    <td class="text-center">{{post.notificationStatus }}</td>

                                    <td class="text-center">
                                        <div class="flex justify-center items-center">
                                            <a class="flex items-center text-success mr-3" data-toggle="modal"
                                                data-target="#viewSubscription" (click)="view(post)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-eye"></i>

                                            </a>
                                            <a class="flex items-center text-danger mr-3" data-toggle="modal"
                                                data-target="#viewUser" (click)="deleteSubscription(post._id)"
                                                href="javascript:void(0);">
                                                <i class="fa fa-trash"></i>

                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewSubscription">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Notification Info
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="subscriptionForm" (ngSubmit)="updateSubscription()"
                                        class="custom-mt-form">

                                        <div class="row m-0">
                                            <div class="col-lg-12 col-md-12 col-12">
                                    
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Logo Image</label>
                                                        
                                                </div>
                                                <div class="col-lg-2">
        
                                                    
                                                        <div class="columns">
        
                                                        <div class="circle">
                                                            <div class="pic-upload">
                                                                <img [src]="logoImgUrl" class="w-100 h-100" />
                                                            </div>
                                                         
                                                            <img class="profile-pic" src="{{
                                                                selected_image
                                                                    ? base + '/'+ selected_image
                                                                    : 'assets/img/user.png'
                                                                }}">
                                                        </div>
                                                        <div class="p-image">
                                                            <label for="fileuploadd">
                                                                <i class="fa fa-camera upload-button"></i>
                                                            </label>
                                                            <input id="fileuploadd" class="file-upload" type="file"
                                                                (change)="onFileChangeLogoImage($event)" accept="image/*">
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Title </label>
                                                    <input  type="text" class="form-control" formControlName="notificationTitle"
                                                    placeholder="Notification Title">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.notificationTitle.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.notificationTitle.errors.required">
                                                        Please enter title
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification sub title</label>
                                                    <input  type="text" class="form-control" formControlName="notificationSubTitle"
                                                    placeholder="Notification sub title">
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Link</label>
                                                    <input  type="text" class="form-control" formControlName="notificationLink"
                                                    placeholder="Link">
                                                </div>
                                            </div>

                                        
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Date</label>
                                                    <input  type="date" class="form-control" formControlName="notificationDate"
                                                        placeholder="Post Date">
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.notificationDate.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.notificationDate.errors.required">
                                                        Please enter notification date
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Push Notification </label>
                                                    
                                                    <select class="form-control" formControlName="pushNotification">
                                                        <option [value]="true">True</option>
                                                        <option [value]="false">False</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Notification Status</label>
                                                    
                                                    <select class="form-control" formControlName="notificationStatus">
                                                        <option value="None">None</option>
                                                        <option value="Send">Send</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Sent">Sent</option>
                                                    </select>
                                                    
                                                    
                                                </div>

                                            </div>



                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Created At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="createdAt"
                                                    placeholder="Created At">
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Updated At</label>
                                                    
                                                    <input  type="text" class="form-control" readonly formControlName="updatedAt"
                                                    placeholder="Updated At">
                                                </div>
                                                
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

