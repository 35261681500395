/* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.*/

import { Component, OnInit, Pipe } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiUrl } from "../../../../utils/config";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'



@Component({
  selector: 'app-ethical-sourcing',
  templateUrl: './ethical-sourcing.component.html',
  styleUrls: ['./ethical-sourcing.component.css']
})

export class EthicalSourcingComponent implements OnInit {
  ethicalSourcingDetail: any;
  updateUser !: FormGroup;
  imageSrc !: string;
  file !: File;
  submitted = false;
  loading = false;
  filesToUpload: any
  imgUrl = "/assets/img/user.png";
  imageToUpload: any;
  base: any = ApiUrl;
  page: any;

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getEthicalSourcingDetail();
    this.updatefromControl()
    this.getLanguageList()
  }

  updatefromControl = () => {
    this.updateUser = this.formbuilder.group({
      description: ['', Validators.required],
      heading: ['', Validators.required],
      image: ['', Validators.required],
      title: ['', Validators.required],
      location:[0,Validators.required]
    })
  }

  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.page = response.data;
      }
    });
  }

  getEthicalSourcingDetail = () => {
    this.userService.getStaticPage("Ethical Sourcing").toPromise().then((resp) => {
      if (resp.success == true) {
        this.ethicalSourcingDetail = resp.data;
        this.updateUser = this.formbuilder.group({
          description: [this.ethicalSourcingDetail.description ? this.ethicalSourcingDetail.description : '',Validators.required],
          heading: [this.ethicalSourcingDetail.heading, Validators.required],
          image: [this.ethicalSourcingDetail.image, Validators.required],
          title: [this.ethicalSourcingDetail.title, Validators.required],
          location:[this.ethicalSourcingDetail?.location,Validators.required]
        })
      }
    }).catch(err => {
      Swal.fire(
        (err.message),
      )
    })
  }

  get f() {
    return this.updateUser.controls;
  }

  onFileChange = (fileInput: any) => {
    this.imageToUpload = <Array<File>>fileInput.target.files;
    var reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
      this.imgUrl = e.target.result;
    };
  };

  updateProfile = () => {
    this.submitted = true;

    const formData = new FormData();
    if (this.imageToUpload && this.imageToUpload[0]) {
      formData.append('image', this.imageToUpload[0]);
    }
    for (let key in this.updateUser.value) {
      formData.append(key, this.updateUser.value[key]);
    }
    
    this.loading = true;
    this.userService
      .updateContent(formData)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Ethical Sourcing Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
          this.getEthicalSourcingDetail()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }
}
