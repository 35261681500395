<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->
<div class="mt-8 row">
    <div class="intro-y h-10 col-md-12 mb-4">
        <div class="card">
            <div class="card-content">
                <h3 class="text-lg mb-0">
                   Add Notification
                   
                </h3>
                <br>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="card-body">
                            <form  class="custom-mt-form" [formGroup]="form" (ngSubmit)="submitForm()">
                                <div class="row m-0">
                                    
                                    <div class="col-lg-12 col-md-12 col-12">
                                    
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Logo Image</label>
                                                
                                        </div>
                                        <div class="col-lg-2">

                                            
                                                <div class="columns">

                                                <div class="circle">
                                                    <div class="pic-upload">
                                                        <img [src]="logoImgUrl" class="w-100 h-100" />
                                                    </div>
                                                 
                                                    <img class="profile-pic" src="{{
                                                        selected_image
                                                            ? base + '/'+ selected_image
                                                            : 'assets/img/user.png'
                                                        }}">
                                                </div>
                                                <div class="p-image">
                                                    <label for="fileuploadd">
                                                        <i class="fa fa-camera upload-button"></i>
                                                    </label>
                                                    <input id="fileuploadd" class="file-upload" type="file"
                                                        (change)="onFileChangeLogoImage($event)" accept="image/*">
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Title </label>
                                            <input  type="text" class="form-control" formControlName="notificationTitle"
                                            placeholder="Notification Title">
                                        </div>
                                        <div
                                            *ngIf="submitted && f.notificationTitle.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.notificationTitle.errors.required">
                                                Please enter title
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification sub title</label>
                                            <input  type="text" class="form-control" formControlName="notificationSubTitle"
                                            placeholder="Notification sub title">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Link</label>
                                            <input  type="text" class="form-control" formControlName="notificationLink"
                                            placeholder="Link">
                                        </div>
                                    </div>

                                    <div  class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Notification Date</label>
                                            <input  type="date" class="form-control" formControlName="notificationDate"
                                                placeholder="Post Date">
                                        </div>
                                        <div
                                            *ngIf="submitted && f.postDate.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.postDate.errors.required">
                                                Please enter post date
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Push Notification </label>
                                            
                                            <select class="form-control" formControlName="pushNotification">
                                                <option [value]="true">True</option>
                                                <option [value]="false">False</option>
                                            </select>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group custom-mt-form-group">
                                            <label class="control-label">Status</label>
                                            
                                            <select class="form-control" formControlName="status">
                                                <option value="Draft">Draft</option>
                                                <option value="Sent">Sent</option>
                                            </select>
                                            
                                            
                                        </div>
                                    </div>

                                    
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group custom-mt-form-group">
                                        <button class="btn btn-success mr-2" type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
