import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/services/notifications.service';
import { UserService } from 'src/services/user.service';
import { ApiUrl } from 'src/utils/config';
import Swal from 'sweetalert2';

import { UserManagementComponent } from 'src/app/user-management/user-management.component';

@Component({
  selector: 'app-user-subscription',
  templateUrl: './user-subscription.component.html',
  styleUrls: ['./user-subscription.component.css']
})
export class UserSubscriptionComponent implements OnInit {
  list: any;
  UserManagementObject:any;
  currentPages = 1;
  count = 0;
  page = 1;
  tableSize = 10;
  reportedPostDetails: any;
  base: any = ApiUrl;
  rawImageUrl = "/assets/img/user.png";
  one = 1
  zero = 0
  constructor(
    private userService: UserService,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
	this.UserManagementObject = new UserManagementComponent(this.userService,this.notify);
    this.getAllPayment(this.one);
  }

  getAllPayment = (page: Number) => {
    page = page ? page : this.one
    this.userService.userManagement(this.one).subscribe(data => {
      console.log("object",data.data)
      this.count = data.data.count
      this.list = data.data.getSubscription;
    })
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getAllPayment(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = this.one;
    this.getAllPayment(this.page);
  }

  deleteSubscription = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deletePaymentManagement(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getAllPayment(this.one);
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }
}
