<!-- @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited. -->

<div class="container-fluid">
    <div class="mt-8 row">
        <div class="intro-y h-10 col-md-12 mb-4">
            <div class="card">
                <div class="card-content">
                    <h3 class="text-lg mb-0">
                        Update tables
                    </h3>
                    <br>
                    <div class="row">
                        <div class="col-md-3 "></div>
                        <div class="col-md-9 ">
                          <div class="form-group text-right showing_line" >
                            <span>
                              Showing 
                              <b>{{ (currentPages - 1) * postsPerPage+1}}</b>
                              -
                              <b>{{ ((currentPages - 1) * postsPerPage+1) + (reportedPostList?reportedPostList.length-1:0)}}</b>
                              of 
                              <b>{{count}}</b>
                               items
                            </span>
                          </div>
                        </div>
                      </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center whitespace-no-wrap">Sr No.</th>
                                    <th class="text-center whitespace-no-wrap">tableName</th>
                                    <th class="text-center whitespace-no-wrap">descripton</th>
                                    <th class="text-center whitespace-no-wrap">createdAt</th>
                                    <th class="text-center whitespace-no-wrap">updatedAt</th>
                                    

                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="intro-x" *ngFor="let item of reportedPostList | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  }; index as i">
                                    <td class="text-center">
                                        <h4>{{ (currentPages - 1) * postsPerPage + i + 1 }}</h4>
                                    </td>
                                    <td class="text-center">{{item.tableName}}
                                        
                                    </td>
                                
                                <td class="text-center">{{item.descripton}}</td>
                                <td class="text-center">{{item.createdAt}}</td>
                                <td class="text-center">{{item.updatedAt }} </td>
                                    

                                   
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>

<div class="modal" id="viewUser">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-12 ">
                            <h3 class="text-lg mb-0">
                                Update Table Info
                            </h3>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form [formGroup]="subscriptionForm" (ngSubmit)="update()"
                                     class="custom-mt-form">
                                        <div class="row m-0">
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Table</label>
                                                    
                                                    <select class="form-control" formControlName="tableName">
                                                        <ng-container *ngFor="let item of reportedPostList">
                                                            <option [value]="item.tableName">{{item.tableName}}</option>
                                                        </ng-container>
                                                        
                                                    </select>
                                                    
                                                    
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.tableName.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.tableName.errors.required">
                                                        Please select a table
                                                    </div>
                                                   
                                                </div>
                                            </div> 
                                            
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="form-group custom-mt-form-group">
                                                    <label class="control-label">Description</label>
                                                    
                                                    <input class="form-control" type="text" formControlName="descripton" />
                                                    
                                                    
                                                </div>
                                                <div
                                                    *ngIf="submitted && f.descripton.errors"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f.descripton.errors.required">
                                                        Please enter description
                                                    </div>
                                                   
                                                </div>
                                            </div> 
                                            
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group custom-mt-form-group">
                                                <button class="btn btn-success mr-2" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>