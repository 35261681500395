import { Component, OnInit } from '@angular/core';
import { Tables } from "../../../utils/tables";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute,Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-tables',
  templateUrl: './all-tables.component.html',
  styleUrls: ['./all-tables.component.css']
})
export class AllTablesComponent implements OnInit {

  handle:any;
  currentTable :any;
  base: any = Tables;

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { 

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
       
        
      }

      if (event instanceof NavigationEnd) {
        
          
          this.getSubscriptionList();
      }

      if (event instanceof NavigationError) {
          
      }
    });
  }

  ngOnInit(): void {
    
    this.getSubscriptionList();
    
  }

  handleChange=()=>{
    
  }
  getSubscriptionList = () => {
    let handle = this.route.snapshot.paramMap.get("handle")
    let filtered = this.base.filter((q:any)=>
        q.handle===handle
      )
    this.currentTable = filtered[0]
  }
}
