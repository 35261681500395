import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUrl } from '../../../../utils/config';


@Component({
  selector: 'app-add-constant-config',
  templateUrl: './add-constant-config.component.html',
  styleUrls: ['./add-constant-config.component.css']
})
export class AddConstantConfigComponent implements OnInit {

  addSubscriptionForm !: FormGroup;
  submitted = false;
  loading = false;
  base: any = ApiUrl;
  locationList:any

  constructor(private userService: UserService, private formbuilder: FormBuilder, private router: Router, private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.addSubscriptionControler()
    this.getLanguageList()
  }
  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }
  addSubscriptionControler = () => {
    this.addSubscriptionForm = this.formbuilder.group({
      description: [''],
      label: [''],
      englishValue: [''],
      title: [''],
      location: [''],
      webAdmin: [false, Validators.required],
      viewOrder: ['', [Validators.pattern(/^[0-9]+$/)]],
      system: [false, Validators.required],
      default: [false, Validators.required],
      UX: [false, Validators.required],
      local: [false, Validators.required],
      type:['Text']
    })
  }

  get f() {
    return this.addSubscriptionForm.controls;
  }

  addSubscription = () => {
    this.submitted = true;
    
    if (this.addSubscriptionForm.invalid) {
      return;
    }
    this.addSubscriptionForm.value;
    this.loading = true;
    this.userService.addContent(this.addSubscriptionForm.value).toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'App Tutorial Added Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.router.navigate(["/admin/constantConfig"]);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

}
