/* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.*/

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service'
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiUrl } from "../../../../../utils/config";
import { NotificationsService } from "../../../../../services/notifications.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  ethicalSourcingDetail: any;
  currentPages = 1;
  postsPerPage = 10;
  form!: FormGroup;
  tableSize = 10;
  tutorialList: any;
  submitted = false;
  loading = false;
  count = 0;
  page = 1;
  idToShow: any
  base: any = ApiUrl;
  selectedGroup:any
  selected_image:any
  imgUrl = "/assets/img/user.png";
  logoImgUrl = "/assets/img/user.png";
  imageToUpload: any;
  logoImageToUpload: any;
  locationList:any
  groupList :any
  criteriaList :any
  search: any = '';

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private notify: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getTutorialList(1);
    this.updatefromControl()
    this.getAllCriteria(this.page)
    this.getLanguageList()
  }

  getLanguageList = ()=>{
    this.userService.languageList().toPromise()
    .then(response => {
      if (response.success) {
        this.locationList = response.data;
      }
    });
  }

  getAllCriteria = (page: Number) => {
    page = page ? page : 1
    this.userService.getAllCriteria(page).toPromise().then(response => {
      
      if (response.success) {
        this.criteriaList = response.data;
      }
    });
  }
    formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
  

  onFileChange(evt:any){

    this.imageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageToUpload[0]);
    reader.onload = (e: any) => {
        this.imgUrl = e.target.result;
    };
  }

  onFileChangeLogoImage(evt:any){

    this.logoImageToUpload = <Array<File>>evt.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(this.logoImageToUpload[0]);
    reader.onload = (e: any) => {
        this.logoImgUrl = e.target.result;
    };
  }
  
  updatefromControl = () => {
    this.form = this.formbuilder.group({
        grouped: [false, [Validators.required]],
        canClose: [true, [Validators.required]],
        notificationTemplate: [true, [Validators.required]],
        description: [''],
        interestFlag: [true, [Validators.required]],
        distribution: ['', [Validators.required]],
        postDate: ['', [Validators.required]],
        endDate: ['', [Validators.required]],
        link: [''],
        notificationSubTitle: [''],
        location: [''],
        pushNotification: [false, [Validators.required]],
        notificationListFlag: [false, [Validators.required]],
        notificationTitle: ['', [Validators.required]],
        notificationCriteria: ['', [Validators.required]],
        action: ['', [Validators.required]],
        test: [false, [Validators.required]],
        status: ['', [Validators.required]],
        createdAt: [''],
        updatedAt:['']
    })
  }

  
  onChange = (e: any, id: any) => {
    let message = !e ? false : true
    let data = {
      id: id,
      interestFlag: !e ? false : true
    }
    this.userService.interestFlag(data).toPromise().then(response => {
      if (response.success) {
        
      }
    })
  }
  onSearch(e:any){
    this.getTutorialList(1);
  }

  getTutorialList = (page: any) => {
    this.currentPages = page;
    this.page=page;
    this.userService.getNotificationSearch(page, this.search).toPromise().then(response => {
      if (response.success) {
        this.tutorialList = response.data.result;
        this.count = response.data.count;
      }
    });
  }

  getEthicalSourcingDetail = () => {

    if(this.ethicalSourcingDetail.image!==undefined && this.ethicalSourcingDetail.image!==''){
        this.imgUrl = this.base + '/'+ this.ethicalSourcingDetail.image
    }
    if(this.ethicalSourcingDetail.logo!==undefined && this.ethicalSourcingDetail.logo!==''){
      this.logoImgUrl = this.base + '/'+ this.ethicalSourcingDetail.logo
   }
    let postDate = this.formatDate(this.ethicalSourcingDetail.postDate)
    let endDate = this.formatDate(this.ethicalSourcingDetail.endDate)

    this.form = this.formbuilder.group({
      grouped: [this.ethicalSourcingDetail.grouped, [Validators.required]],
      canClose: [this.ethicalSourcingDetail.canClose, [Validators.required]],
      notificationTemplate: [this.ethicalSourcingDetail.notificationTemplate, [Validators.required]],
      description: [this.ethicalSourcingDetail.description],
      interestFlag: [this.ethicalSourcingDetail.interestFlag, [Validators.required]],
      distribution: [this.ethicalSourcingDetail.distribution, [Validators.required]],
      postDate: [postDate, [Validators.required]],
        endDate: [endDate, [Validators.required]],
      link: [this.ethicalSourcingDetail.link],
      notificationSubTitle: [this.ethicalSourcingDetail.notificationSubTitle],
      location: [this.ethicalSourcingDetail?.location],
      pushNotification: [this.ethicalSourcingDetail.pushNotification, [Validators.required]],
      notificationListFlag: [this.ethicalSourcingDetail.notificationListFlag, [Validators.required]],
      notificationTitle: [this.ethicalSourcingDetail.notificationTitle, [Validators.required]],
      notificationCriteria: [this.ethicalSourcingDetail.notificationCriteria, [Validators.required]],
      action: [this.ethicalSourcingDetail.action, [Validators.required]],
      test: [this.ethicalSourcingDetail.test, [Validators.required]],
      status: [this.ethicalSourcingDetail.status, [Validators.required]],
      createdAt: [this.ethicalSourcingDetail.createdAt],
      updatedAt: [this.ethicalSourcingDetail.updatedAt],
    })
  }

  get f() {
    return this.form.controls;
  }

  viewStore = (data: any) => {
    this.ethicalSourcingDetail = data;
    this.idToShow = data._id;
    this.getEthicalSourcingDetail();
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.currentPages = this.page;
    this.getTutorialList(this.page);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTutorialList(1);
  }

  submitForm = () => {
    this.submitted = true;
    const formData = new FormData();

    if (this.imageToUpload && this.imageToUpload[0]) {
        formData.append('image', this.imageToUpload[0]);
    }

    if (this.logoImageToUpload && this.logoImageToUpload[0]) {
      formData.append('logo', this.logoImageToUpload[0]);
    }

    for (let key in this.form.value) {
      if(this.form.value[key]!==null  ){
        formData.append(key, this.form.value[key]);

      }
    }
    if (this.form.invalid) {
        return;
    }
    this.userService
      .updateHomeContent(formData, this.idToShow)
      .toPromise()
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Notification Updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          let modalToClose = <HTMLElement>document.querySelector('.modal-header.p-0 .close');
          modalToClose.click();
          this.getTutorialList(this.page)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      });
  }

  deleteStore = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteHomeContent(id).toPromise().then(response => {
          if (response.success) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.getTutorialList(this.page)
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

}
